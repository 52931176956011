import React,  { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { useNavigate } from "react-router-dom";
import _  from 'lodash';

import MetaData from "../../layout/MetaData";
import AdminLayout from "../../layout/AdminLayout";
import HeaderAdmin from "../../layout/HeaderAdmin";
import Loader from "../../layout/Loader";
import { EditableNumberCell, ButtonCell, RightAlignedCell, CenterAlignedCell, LeftAlignedCell } from "../../layout/CustomMDBDataTableCell";
import { PURCHASE_STATUS, ADMIN_MENU_ITEMS, PURCHASE_USER_RIGHTS, PURCHASE_STATUS_ICON, QUICKBOOKS_CATEGORY, USER_ROLES } from "../../../constants/constants.js";
import { 
  useAdminPurchaseDetailsQuery, 
  useAdminUpdatePurchaseMutation, 
  useAdminDeletePurchaseMutation, 
  useAdminReceivePurchaseMutation, 
  useAdminMarkPurchaseAsViewedMutation,
  useAdminForceResyncOnePurchaseQuickbooksMutation
 } from "../../../redux/api/purchaseAdminApi";
import { useAdminGetInventoryQuery } from "../../../redux/api/inventoriesAdminApi";
import { useLazyAdminGetPriceListQuery } from "../../../redux/api/priceListAdminApi";
import { valueIsANonNegativeNumber, valueIsANumber } from "../../../utils/validators";
import { appendAppVersionToUrl, CalculatePurchaseSummary, getPSTStartOfDay } from "../../../utils/utilities";
import { ConfirmDialog, ConfirmDialogReceivePurchase, ConfirmDialogUpdateDeliveredOrder } from "../ConfirmDialog";
import { ProductSelection } from "../../product/ProductSelection";
import { DetermineUserOldPurchaseRights, DetermineUserPurchaseRights } from "../AdminActionEligibilities";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import QuickbooksIcon from "../../layout/QuickbooksIcon.jsx";
import { generatePurchaseInvoicePDF } from "../../printings/PurchaseInvoice.jsx";

import { setAdminOptions } from "../../../redux/features/adminOptionsSlice.js";

const ProcessPurchase = () => {
  const isOldPurchase = window.location.href.includes('/admin/oldpurchases/');

  const params = useParams();
  const dispatch = useDispatch();
  const purchaseId = params?.id;
  const navigate = useNavigate();

  // Redux
  const { isLoading: loadingPurchaseDetails, data: purchaseDetail, error: loadingPurchaseDetailError } = useAdminPurchaseDetailsQuery({id: purchaseId, isOldPurchase}, { refetchOnMountOrArgChange: true });
  const [adminUpdatePurchase, { data: updatedPurchaseData, isLoading: isUpdatingPurchase, error: purchaseUpdateError, isSuccess: purchaseUpdateSuccess }] = useAdminUpdatePurchaseMutation();
  const [adminDeletePurchase, { isLoading: isDeletingPurchase, error: purchaseDeleteError, isSuccess: purchaseDeleteSuccess }] = useAdminDeletePurchaseMutation();
  const [adminReceivePurchase, { isLoading: isReceivingPurchase, error: purchaseReceiveError, isSuccess: purchaseReceiveSuccess }] = useAdminReceivePurchaseMutation();
  const [adminGetPriceListQuery, { data: vendorPriceListData, isLoading: isLoadingVendorPriceListData } ] = useLazyAdminGetPriceListQuery();
  const [adminMarkPurchaseAsViewed] = useAdminMarkPurchaseAsViewedMutation();
  const [adminForceResyncOnePurchaseQuickbooks, { data: quickbooksResyncData, isLoading: isForcingQuickbooksResync }] = useAdminForceResyncOnePurchaseQuickbooksMutation();

  // state
  const [itemIndexToRemove, showRemoveItemConfirmDialog] = useState(-1);
  const [showingDeletePurchaseConfirm, showDeletePurchaseConfirmDialog] = useState(false);
  const [showingUpdateCompletedPurchaseConfirm, showUpdateCompletedPurchaseConfirmDialog] = useState(false);
  const [status, setStatus] = useState("");
  const [isCR, setIsCR] = useState(false);
  const [purchaseItems, setPurchaseItems] = useState([{}]);
  const [thereAreValueChanges, setThereAreValueChanges] = useState(false);
  const [formUpdated, setFormUpdated] = useState(false);
  const [purchaseDate, setPurchaseDate] = useState();

  const { adminOptions } = useSelector((state) => state.adminOptions);
  //const [purchaseUpdatedTime, setPurchaseUpdatedTime] = useState();

  let [clonedPurchaseItems, setclonedPurchaseItems] = useState([]);
  let [clonedInvoice, setClonedInvoice] = useState("");
  let [clonedNotes, setClonedNotes] = useState("");
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [notes, setNotes] = useState("");
  const [history, setHistory] = useState("");
  const [tempUpdatedInventory, setTempUpdatedInventory] = useState({});
  const [showingReceivePurchaseConfirm, showReceivePurchaseConfirm] = useState(false);
  const [invoice, setInvoice] = useState("");
  const [QBStatus, setQBStatus] = useState();
  const [purchaseNoQB, setPurchaseNoQB] = useState();
  const [vendorPriceHash, setVendorPriceHash] = useState({});

  // Slice data
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { inventory } = useSelector((state) => state.inventory);
  const { inventoryDataOrdersByProduct } = useSelector((state) => state.inProgressOrders);
  const { inventoryDataPurchasesByProduct } = useSelector((state) => state.inProgressPurchases);

     // Query for data
  const {isLoading: isLoadingInventory } = useAdminGetInventoryQuery();

  const filterValidRows = (items) => {
    const validRows = [];
    for (const item of items) {
      if (item.product) {
        validRows.push(item);
      }
    }
    return validRows;
  };
 
  // Clean up the temp inventory
  useEffect(() => {
    setTempUpdatedInventory({});
  }, [inventory]);

  useEffect(() => {
    if (loadingPurchaseDetailError) {
      
      if (loadingPurchaseDetailError.status === 401) {
        toast.error(loadingPurchaseDetailError?.data?.message);
        adminLogout();
      } else if (loadingPurchaseDetailError.status === 404) {
        if (isOldPurchase) {
          toast.error(loadingPurchaseDetailError?.data?.message);
          navigate(appendAppVersionToUrl("/admin/history"));
        } else {
          navigate(appendAppVersionToUrl(`/admin/purchases`));
        }
      }
    } 
  }, [loadingPurchaseDetailError]);

  useEffect(() => {
    considerEnableUpdateButton();
  }, [notes, invoice, purchaseNoQB]);

  useEffect(() => {
    if (user && purchaseDetail ) {
      if (purchaseDetail.status) {
        setStatus(purchaseDetail.status);
      }
      setIsCR(purchaseDetail.isCR);
      setNotes(purchaseDetail.notes ? purchaseDetail.notes : "");
      setClonedNotes(purchaseDetail.notes ? purchaseDetail.notes : "");
      const paymentInfo = purchaseDetail.paymentInfo;
      setInvoice(paymentInfo?.invoice ? paymentInfo.invoice : "");
      setQBStatus(purchaseDetail.QBStatus);
      setClonedInvoice(paymentInfo?.invoice ? paymentInfo.invoice : "");
      if (purchaseDetail.history) {
        let historyString = "";
        purchaseDetail.history.forEach((item, index) => {
          historyString += `${index + 1}. ${item} \n`;
        });
        setHistory(historyString);
      }

      if (purchaseDetail.purchaseItems) { 
        const clonedItems = JSON.parse(JSON.stringify(purchaseDetail.purchaseItems));
        setPurchaseItems(JSON.parse(JSON.stringify(clonedItems)));   
        clonedItems.push({});
        setclonedPurchaseItems(JSON.parse(JSON.stringify(clonedItems)));
      }

      if (purchaseDetail.vendor) {
        adminGetPriceListQuery(purchaseDetail.vendor._id);
      }

      if (!isOldPurchase) {
        // Update the purchase as viewed
        const timestamp = new Date(purchaseDetail.updatedAt).getTime();
        if (!user.viewPurchaseHistory || !user.viewPurchaseHistory[purchaseDetail._id] || user.viewPurchaseHistory[purchaseDetail._id].timestamp < timestamp) {
          adminMarkPurchaseAsViewed({purchaseId: purchaseDetail._id, body: {timestamp, status: purchaseDetail.status }});
        }
      }

      setPurchaseDate(purchaseDetail.purchaseDate)
      setPurchaseNoQB(purchaseDetail.noQB);
    }
  }, [purchaseDetail, user]);

  useEffect(() => {
    if (quickbooksResyncData) {
      setQBStatus(quickbooksResyncData.purchase?.QBStatus);
    }
  }, [quickbooksResyncData]);

  useEffect(() => {
    if (vendorPriceListData) {
      const priceHash = {};
      vendorPriceListData.forEach((item) => {
        priceHash[item.product] = item.price;
      });

      // Update the prices of the items in the purchase
      purchaseDetail.itemList?.forEach((item) => {
        const productId = item.product;
        const price  = priceHash ? priceHash[productId] : undefined;
        item.finalPrice = item.initPrice = price;
      });

      setVendorPriceHash(priceHash);
    }
  }, [vendorPriceListData]);    

  useEffect(() => {
    if (purchaseUpdateError) {
      toast.error(purchaseUpdateError?.data?.message);
    }
    if (purchaseUpdateSuccess) {
      //toast.success(updatedPurchaseData?.message);
      const updatedPurchase = updatedPurchaseData?.purchase;
      if (updatedPurchase) {
        setStatus(updatedPurchase.status);
        setPurchaseItems(updatedPurchase.purchaseItems);
        setclonedPurchaseItems(JSON.parse(JSON.stringify(updatedPurchase.purchaseItems)));
        considerEnableUpdateButton();
      }

      // Update inventory for inbound/outbound
      if (updatedPurchaseData?.updatedInventory) {
        setTempUpdatedInventory(updatedPurchaseData.updatedInventory);
      }
    }
  }, [purchaseUpdateError, purchaseUpdateSuccess]);

  useEffect(() => {
    considerEnableUpdateButton();
    if (clonedPurchaseItems && clonedPurchaseItems.length > 0 && clonedPurchaseItems[clonedPurchaseItems.length - 1].name) {
      addItemRowHandler();
    }
  }, [clonedPurchaseItems, formUpdated]);

  useEffect(() => {
    if (purchaseDeleteError) {
      toast.error(purchaseDeleteError?.data?.message);
    } else if (purchaseDeleteSuccess) {
      //toast.success("Purchase Removed");
      if (!isLoadingInventory) {
        navigate(appendAppVersionToUrl(isOldPurchase ? "admin/history" : "/admin/purchases"));
      }
    }
  }, [purchaseDeleteError, purchaseDeleteSuccess, isLoadingInventory]);

  useEffect(() => {
    if (purchaseReceiveError) {
      toast.error(purchaseReceiveError?.data?.message);
      if (purchaseReceiveError.status === 401) {
        adminLogout();
      }
    } 

    if (purchaseReceiveSuccess) {
      setStatus(PURCHASE_STATUS.DELIVERED);
      //toast.success("Purchase Received");
    }
  }, [purchaseReceiveError, purchaseReceiveSuccess]);

  // --------------------------------- Render ----------------------------------
  // If the Purchase, inventory, user are not loaded yet, show the loader
  if (loadingPurchaseDetails || loadingAdmin || user === null || purchaseDetail === null || inventory === null) {
    return <Loader />;
  }
  //--------------------------------- Functions ---------------------------------

  function thereAreChanges() {
    const validRows = filterValidRows(clonedPurchaseItems);
    //console.log("thereAreChanges: ", validRows, purchaseItems);
    return !_.isEqual(validRows, purchaseItems) || (notes ?? "") !== (clonedNotes ?? "") || invoice !== clonedInvoice || (purchaseNoQB ? true : false) !== (purchaseDetail.noQB ? true : false) || purchaseDate !== purchaseDetail.purchaseDate;
  }

  function considerEnableUpdateButton() 
  {
    if (purchaseDetail) {
      const thereArePendingChanges = thereAreChanges();
      setThereAreValueChanges(thereArePendingChanges);

      if (thereArePendingChanges !== adminOptions.thereArePendingChanges) {
        const newAdminOptions = { ...adminOptions, thereArePendingChanges: thereArePendingChanges };
        dispatch(setAdminOptions(newAdminOptions));
      }
    }
  }

  const handleRemoveItem = (index) => {
    confirmRemovingItem(index);
  };

  // Confirm removing item
  const confirmRemovingItem = (index) => {
    if (index >= 0) {
      clonedPurchaseItems.splice(index, 1);
      setFormUpdated(!formUpdated);
    }
    showRemoveItemConfirmDialog(-1);
  }

  // Delete Purchase
  const handleDeletePurchase = () => {
    if (!isDeletingPurchase) showDeletePurchaseConfirmDialog(true);
  }

  // Confirm deleting Purchase
  const confirmDeletingPurchase = () => {
    showDeletePurchaseConfirmDialog(false);
    if (!isDeletingPurchase) adminDeletePurchase(purchaseId);
  }

  // Confirm Receive Purchase
  const confirmReceivePurchase = (invoice) => {
    showReceivePurchaseConfirm(false);
    adminReceivePurchase({ id: purchaseId });
  }

  const confirmUpdatingCompletedPurchase = (updateStock) => {
    showUpdateCompletedPurchaseConfirmDialog(false);
    doUpdatePurchase(updateStock);
  }

  // Process Purchase
  const processPurchaseHandler = (nextStatus) => {
    // There there are chages, update first
    if (thereAreValueChanges) {
      toast.error("There are changes in this Purchase. Please update first");
    } else {
      if (nextStatus === PURCHASE_STATUS.RECEIVED) {
        if (!invoice) {
          toast.error("Please enter the invoice number first");
        } else {
          showReceivePurchaseConfirm(true);
        }
      } else {
        let purchaseData = { status: nextStatus };
        purchaseData.updateStock = true;
        adminUpdatePurchase({ id: purchaseId, isOldPurchase, body: purchaseData });
      }
    }
  };

  // Update
  const updatePurchaseHandler = () => {
    const validRows = filterValidRows(clonedPurchaseItems);
    const therIsItemChange = !_.isEqual(validRows, purchaseItems);
    if (therIsItemChange && purchaseDetail.status === PURCHASE_STATUS.DELIVERED) {
      showUpdateCompletedPurchaseConfirmDialog(true);
    } else {
      doUpdatePurchase(true);
    }
  }

  const doUpdatePurchase = (updateStock) => {
    // Check if there is item with no price
    const incompleteItem = clonedPurchaseItems.find(item => item.product && (item.finalPrice === undefined || item.finalPrice === "" || item.finalQuantity === undefined || item.finalQuantity === ''));
    if (incompleteItem) {
      toast.error(`Please enter price/quantity for ${incompleteItem.name}!`);
      return;
    }

    // Clean up the cloned Purchase items
    if (thereAreChanges()) {
      const paymentInfo = { invoice };
      const validRows = filterValidRows(clonedPurchaseItems);
      const purchaseData = { itemList: validRows, notes, paymentInfo };
      if (purchaseDate && purchaseDate !== purchaseDetail.purchaseDate) {
        purchaseData.purchaseDate = purchaseDate;
      }
      if (purchaseNoQB !== purchaseData.noQB) {
        purchaseData.noQB = purchaseNoQB;
      }
      purchaseData.updateStock = updateStock;
      adminUpdatePurchase({ id: purchaseId, isOldPurchase, body: purchaseData });
    } 
  };

  const forceResynceOnePurchase = () => {
    adminForceResyncOnePurchaseQuickbooks({ isHistory: isOldPurchase , id: purchaseId });
  };

  // Handle price
  const handlePriceChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && clonedPurchaseItems[index].finalPrice !== value) {
      clonedPurchaseItems[index].finalPrice = Number(value);
      setFormUpdated(!formUpdated);
    }
  };

  // Handle note
  const handleNoteChange = (index, value) => {
    clonedPurchaseItems[index].note = value;
    setFormUpdated(!formUpdated);
  }
    
  // Handle quantity
  const handleQuantityChange = (index, value) => {
    if (valueIsANumber(value) && clonedPurchaseItems[index].finalQuantity !== value) {
      clonedPurchaseItems[index].finalQuantity = Number(value);
      setFormUpdated(!formUpdated);
    }
  };

  function calculateCurrentBalancePerProduct(productInventoryObj) {  
    if (!productInventoryObj) {
      return 0;
    }
    const productId = productInventoryObj.product._id;

    // Total inbound on this purchase latest
    const currentPurchaseItems = clonedPurchaseItems.filter(item => item.product === productId);
    const totalCurrentInboundThisPurchase = currentPurchaseItems.reduce((total, item) => total + (isCR ? -item.finalQuantity : item.finalQuantity), 0);

    // Calulate inbound on this purchase original
    const originaPurchaseItems = purchaseItems.filter(item => item.product === productId);
    const totalOriginalInboundThisPurchase = originaPurchaseItems.reduce((total, item) => total + (isCR ? -item.finalQuantity : item.finalQuantity), 0);

    // Total original outbound on all orders
    const totalOriginalOutboundAllOrder = (inventoryDataOrdersByProduct && inventoryDataOrdersByProduct[productId]?.inProgressTotal? inventoryDataOrdersByProduct[productId]?.inProgressTotal : 0);

    // Total original inbound on all purchases
    const totalOriginalInboundAllPurchase = (inventoryDataPurchasesByProduct && inventoryDataPurchasesByProduct[productId]?.inProgressTotal? inventoryDataPurchasesByProduct[productId]?.inProgressTotal : 0);

    // Total current outbound on all purchases
    const totalCurrentInboundAllPurchase = totalOriginalInboundAllPurchase + totalCurrentInboundThisPurchase - totalOriginalInboundThisPurchase;

    let stock = productInventoryObj.quantity ? productInventoryObj.quantity : 0;
    if (tempUpdatedInventory && tempUpdatedInventory[productId] && tempUpdatedInventory[productId].stockInc) {
      stock += tempUpdatedInventory[productId].stockInc;
    }

    // Calculate the balance
    const balance = stock - totalOriginalOutboundAllOrder + totalCurrentInboundAllPurchase;
    return balance;
  }

  // Add item row
  const addItemRowHandler = () => {
    clonedPurchaseItems.push({});
    setFormUpdated(!formUpdated);
  };

  const assignProductData = (index, product) => {
    const price = (vendorPriceHash && vendorPriceHash[product._id]) ? parseFloat(vendorPriceHash[product._id].toFixed(2)) : "";
    Object.assign(clonedPurchaseItems[index], {
      product: product._id,
      name: product.name,
      initQuantity: 1,
      finalQuantity: 1,
      finalPrice: price, 
    });
  }
  const handleDateChange = (date) => {
    const selectedDate = getPSTStartOfDay(date);
    const isoDate = selectedDate.toISOString();
    setPurchaseDate(isoDate);
    setFormUpdated(!formUpdated);
  };

  const handleCheckBoxChange = (e) => {
    setPurchaseNoQB(!e.target.checked);
    setFormUpdated(!formUpdated);
  }

  const copyPurchaseTextToClipBoard = () => {
    // iterate purchaseItems and create row of text with name and value separated by tab space
    let purchaseText = "";
    clonedPurchaseItems.forEach((item) => {
      if (item.product) {
        purchaseText += `${item.name}\t${item.finalQuantity}\n`;
      }
    });
    navigator.clipboard.writeText(purchaseText).then(() => {
      toast.success('Purchase data copied to clipboard!');
    }).catch(err => {
      toast.error('Failed to copy purchase data to clipboard.');
    });
  };

  const print = () => {
    //window.open(appendAppVersionToUrl('/admin/purchases/' + purchaseId + '/invoice'), '_blank');
    const pdf = generatePurchaseInvoicePDF(purchaseDetail);
    if (pdf) {
      pdf.open();
    }
  };

  const {units, estPallet, itemTotal, tax, total} = CalculatePurchaseSummary(clonedPurchaseItems, inventory);

  // Determine user rights
  const {rights} = isOldPurchase ? DetermineUserOldPurchaseRights(user, status, isCR) : DetermineUserPurchaseRights(user, status, isCR);
  const editable = rights?.some(right => right === PURCHASE_USER_RIGHTS.UPDATE);
  const addable = rights?.some(right => right === PURCHASE_USER_RIGHTS.ADD);
  const deleteable = rights?.some(right => right === PURCHASE_USER_RIGHTS.DELETE);

  const completedPurchase = status === PURCHASE_STATUS.DELIVERED || status === PURCHASE_STATUS.CANCELLED;
  const MDBDTableClassname =  "myMDBDataProcessOrderTableRow";
  
  const setPurchases = () => {
    const items = {
      columns: [
        {
          label: <CenterAlignedCell value={"SKU"}> </CenterAlignedCell>,
          field: "sku",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Product"}> </CenterAlignedCell>,
          field: "name",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Note"}> </CenterAlignedCell>,
          field: "note",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Quantity"}> </CenterAlignedCell>,
          field: "quantity",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Price"}> </CenterAlignedCell>,
          field: "price",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Item Total"}> </CenterAlignedCell>,
          field: "itemTotal",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Balance"}> </CenterAlignedCell>,
          field: "balance",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };  

    if (!editable) {
      clonedPurchaseItems?.forEach((item, index) => {
        const productId = item.product;
        const product = inventory[productId];
        const balanceVal = calculateCurrentBalancePerProduct(product);
        const balance = Number.isInteger(parseFloat(balanceVal)) ? balanceVal : balanceVal.toFixed(2);

        const quantity = item.finalQuantity;
        const price = item.finalPrice ? item.finalPrice : '';
        const itemTotal = Number(price) * Number(quantity);
        const itemTotalString = isNaN(itemTotal) ? "" : "$" + itemTotal.toFixed(2);      
        items.rows.push({
          className: MDBDTableClassname,
          name: item.name,
          price: <RightAlignedCell value={price} > </RightAlignedCell>,
          quantity: <RightAlignedCell value={quantity} > </RightAlignedCell>,
          itemTotal: <RightAlignedCell value={itemTotalString} > </RightAlignedCell>,
          balance:  product ? <span style={{ color: balance < 0 ? "red" : "black"}}><RightAlignedCell value={balance !== undefined ? balance : '_'} ></RightAlignedCell> </span> : "",
          actions: (<center>
                      {index < clonedPurchaseItems.length - 1 && (<ButtonCell buttonType={"btn btn-outline-danger ms-2"} labelType={"fas fa-trash fa-sm"}  style={{ fontSize: "0.8em" }} action={(e) => {handleRemoveItem(index);}} > </ButtonCell>)}                 
                    </center>),
        });
      });
    } else {
      clonedPurchaseItems?.forEach((item, index) => {
        const productId = item.product;
        const product = inventory[productId];
        const quantity = item.finalQuantity;

        const balanceVal = calculateCurrentBalancePerProduct(product);
        const balance = Number.isInteger(parseFloat(balanceVal)) ? balanceVal : balanceVal.toFixed(2);

        const price = item.finalPrice ? item.finalPrice.toFixed(2) : '';
        const itemTotal = Number(price) * Number(quantity);
        const itemTotalString = isNaN(itemTotal) ? "" : "$" + itemTotal.toFixed(2);
        let priceColor, quantityColor, noteColor;

        const orginalItem = purchaseItems.find(_item => _item._id === item._id);
        if (orginalItem) {
          if (item?.note !== orginalItem.note) {
            noteColor = "red";
          }
          if (item?.finalPrice !== orginalItem.finalPrice) {
            priceColor = "red";
          }
          if (item?.finalQuantity !== orginalItem.finalQuantity) {
            quantityColor = "red";
          }
        } else {
          quantityColor = priceColor = "red";
          priceColor = "red";
          noteColor = "red";
        }

        items.rows.push({
          className: MDBDTableClassname,
          sku: <CenterAlignedCell value={product ? product.product.sku : ""}> </CenterAlignedCell>,
          name: ProductSelection({ productList: inventory, 
                                    defaultItem: { ...item, sku: (product ? product.product.sku : "") }, 
                                    vendorPriceHash,
                                    onChange: (product) => {
                                      assignProductData(index, product);
                                      setFormUpdated(!formUpdated);
                                    }
                                  }),
          note: product ? <EditableNumberCell type={'text'} readOnly={!editable} value={item.note} onChange={(val) => {handleNoteChange(index, val); }} color={noteColor}> </EditableNumberCell> : "",
          price: product ? <EditableNumberCell readOnly={!editable} value={price} onBlur={(val) => {handlePriceChange(index, val)}} color={priceColor}> </EditableNumberCell> : "",
          quantity: product ? <EditableNumberCell readOnly={!editable} value={quantity} onBlur={(val) => {handleQuantityChange(index, val)}} color={quantityColor}> </EditableNumberCell> : "",
          itemTotal: product ? <RightAlignedCell value={itemTotalString} > </RightAlignedCell> : "",
          //stock: product ? <RightAlignedCell value={stock !== undefined ? stock : '-'} > </RightAlignedCell> : "",
          //incommingQuantity: product ? <RightAlignedCell value={totalInQty} > </RightAlignedCell> : "",
          //outcommingQuantity: product ? <RightAlignedCell value={outcommingQuantity} > </RightAlignedCell> : "",
          balance:  product ? <span style={{ color: balance < 0 ? "red" : "black"}}><RightAlignedCell value={balance !== undefined ? balance : '_'} ></RightAlignedCell> </span> : "",
          actions: (<center>
                      {index < clonedPurchaseItems.length - 1 && (<ButtonCell buttonType={"btn btn-outline-danger ms-2"} labelType={"fas fa-trash fa-sm"}  style={{ fontSize: "0.8em" }} action={(e) => {handleRemoveItem(index);}} > </ButtonCell>)}                 
                    </center>),
        });
      });
    }
    return items;
  };

  return (
    <>
      <MetaData title={"Purchase Details"} />
      <HeaderAdmin title={"Purchase Detail"} bg_color={"lightblue"}/>
      <AdminLayout  menuItem={ADMIN_MENU_ITEMS.PURCHASES.name}>
      <div id = "order_table" className="col-11 col-lg-11 my-0 offset-lg-0">
          {/* Purchase # & Delete Purchase button */}
          <div className="row">
            <div className="col-2 width-100">
              <DatePicker
                selected={purchaseDate}
                onChange={(date) => { handleDateChange(date); } }
                dateFormat="MM/dd/yyyy"
                className="form-control"
              />
            </div>
            {/* Purchase # */}
            <div className="col-6 row width-100">
              <div className="col-6">
                { isCR ? (
                  <h4 className="my-1 text-center" style={{color: 'orange'}}> 
                    Vendor Credit #: <strong>{`CR-${purchaseDetail.purchaseNumber}`} </strong>
                  </h4>
                ) : (
                  <h4 className="my-1 offset-1 text-center"> 
                    Purchase#: <strong>{purchaseDetail.purchaseNumber} </strong>
                  </h4>
                )}
              </div>
              <div className="col-3">
                <button
                  type="button"
                  id="printButton"
                  onClick={() => print()}
                  style={{ height: '2.5em', verticalAlign: 'middle', width: '2.5em', padding: '0.5em' }}
                >
                  <i className={"fas fa-print"}/>
                </button>
              </div>
              <div className="col-3">
                {purchaseDetail && !purchaseDetail.noQB && (
                  <div className="text-left">
                    <QuickbooksIcon  qbCategory={QUICKBOOKS_CATEGORY.PURCHASE} qbRef={purchaseDetail.purchaseNumer}  QBStatus={QBStatus}  function1={() => forceResynceOnePurchase()}/>
                  </div>
                )}
              </div>
            </div>
            <div className="col-2">
              <button 
                  onClick={() => {copyPurchaseTextToClipBoard();}} 
                  className="btn btn-success"
                  style={{width: '100%'}}>
                  Copy
              </button>
            </div>

            {/* Remove Purchase */}
            <div className="col-2 text-end">
            {deleteable && (<ButtonCell buttonType={"btn btn-outline-danger ms-2"} disabled={isDeletingPurchase || showingDeletePurchaseConfirm} label={'Delete Purchase'} action={(e) => {handleDeletePurchase();}}  width={'100%'}> </ButtonCell>)}  
            </div>
          </div>
          {/* QB id for testing -visible to me only */}
          { user.role === USER_ROLES.SUPER_ADMIN && user.name === "KingGeorge" && (
            <div>
              <h4>{ purchaseDetail.QBId }</h4>
            </div>
          )}
          {/* Ref/Invoice # */}
          <div className="row my-1">
            <div className="col-2 text-end">
              <h5>{ isCR ? 'Ref #' : 'Invoice #'}</h5>
            </div>
            <div className="col-2">
              {!editable && 
              (
                <h5 className="my-2"><strong>{purchaseDetail?.paymentInfo?.invoice}</strong></h5>
              )}
              {editable && (
                <input 
                  style={{color: invoice !== clonedInvoice ? "red" : "black"}}
                  type="text"
                  className="form-control"
                  value={invoice}
                  onChange={(e) => {setInvoice(e.target.value)}}
                />
              )}
            </div>
          </div>
          {/* Vendor */}
          <div className="row my-1">
            <div className="col-2 text-end">
              <h5>{`Vendor: `}</h5>
            </div>
            <div className="col-7">
              <h5><strong>{purchaseDetail.vendor ? purchaseDetail.vendor.name  : purchaseDetail.vendorName}</strong> </h5>      
            </div>  
            {(user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ACCOUNTANT) && (
            <div className="col-3">
              <h3 className="mb-4">{`QB `} 
                <input 
                  type="checkbox" 
                  name="isActive" 
                  checked={!purchaseNoQB} 
                  onChange={handleCheckBoxChange}
                  style={{ 
                    transform: 'scale(2.0)', 
                    marginRight: '20px',
                    marginLeft: '20px',
                  }} 
                /> 
              </h3>
            </div>
            )}
          </div>
          {/* Status */}
          {!isCR && (
          <div className="row my-1">
            <div className="col-2 text-end">
              <h5><span>{`Status: `}  </span> </h5>
            </div>
            <div className="col-8">
              <h5>
                <strong>{`${String(status).toUpperCase()} `}</strong>
                { PURCHASE_STATUS_ICON[status] && (<i className={PURCHASE_STATUS_ICON[status].icon}  style={{ color: PURCHASE_STATUS_ICON[status].color }} /> )}
              </h5>      
            </div>
            <div className="col-2 text-end">
              {/* Process Purchase Button */}
              {rights?.some(right => right === PURCHASE_USER_RIGHTS.ORDER) && <ButtonCell buttonType={"btn btn-primary ms-2"} label={'Order'} action={()=>{processPurchaseHandler(PURCHASE_STATUS.ORDERED)}} disabled={isUpdatingPurchase || thereAreValueChanges } width={'100%'} >  </ButtonCell>}
              {rights?.some(right => right === PURCHASE_USER_RIGHTS.CONFIRM) && <ButtonCell buttonType={"btn btn-primary ms-2"} label={'Confirm'} action={()=>{processPurchaseHandler(PURCHASE_STATUS.CONFIRMED)}} disabled={isUpdatingPurchase || thereAreValueChanges } width={'100%'} >  </ButtonCell>}
              {rights?.some(right => right === PURCHASE_USER_RIGHTS.RECEIVE) && <ButtonCell buttonType={"btn btn-primary ms-2"} label={'Receive'} action={()=>{processPurchaseHandler(PURCHASE_STATUS.RECEIVE)}} disabled={isUpdatingPurchase || thereAreValueChanges } width={'100%'} >  </ButtonCell>}
            </div>
          </div>
          )}

          {/* Add a thin horizontal line */}
          <hr style={{ borderTop: '1px solid #ccc' }} />

          {/* Handler */}
          {!isCR && (
            <div className="row my-1">
              {/* Created by */}
              {purchaseDetail.createdBy && (
                <div className="col-2 text-end">
                  <h5><span>{'Created by: '}</span> </h5>
                </div>
              )}
              {purchaseDetail.createdBy && (
                <div className="col-2">
                  <h5><span><strong>{purchaseDetail.createdBy}</strong></span> </h5>
                </div>
              )}

              {/* Ordered by */}
              { purchaseDetail.orderedBy && (
                <div className="col-2 text-end">
                  <h5><span>{'Ordered by: '}</span> </h5>
                </div>
              )}
              { purchaseDetail.orderedBy && (
                <div className="col-2">
                  <h5><span><strong>{purchaseDetail.orderedBy}</strong></span> </h5>
                </div>
              )}

              {/* Confirmed by */}
              { purchaseDetail.confirmedBy && (
                <div className="col-2 text-end">
                  <h5><span>{'Confirmed by: '}</span> </h5>
                </div>
              )}
              { purchaseDetail.confirmedBy && (
                <div className="col-2">
                  <h5><span><strong>{purchaseDetail.confirmedBy}</strong></span> </h5>
                </div>
              )}

              {/*
              {purchaseDetail.receivedBy && (
                <div className="col-2">
                  <h5><span>{'Received by: '}<strong>{purchaseDetail.receivedBy}</strong></span> </h5>
                </div>            
              )}         
                */}
            </div>
          )}

          {/* Loader */}
          {(isUpdatingPurchase || isReceivingPurchase || isDeletingPurchase) && (
            <div style={{position: 'relative', height: '100%', width: '100%'}}>
              <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                <Loader />
              </div>
            </div>
          )}

          {/* Purchase Items */}
          <MDBDataTable
            data={setPurchases()}
            className={`px-10 ${MDBDTableClassname}`}
            small
            bordered
            hover
            noBottomColumns
            noRecordsFoundLabel={"No items found"}
            displayEntries={false}
            searchLabel={"Filter"}
            paging={false}
            striped
          />
          <div className="row my-0">
            <div className="col-7 col-lg-7 my-4 offset-0">
              <label htmlFor="notes_field">Notes</label>
              <textarea
                className="form-control"
                value={notes}
                style={{ height: '200px' }}
                onChange={(e) => { setNotes(e.target.value); }}
              />
              <label htmlFor="history_field">History</label>
              <textarea
                className="form-control"
                value={history}
                wrap="soft"
                style={{ 
                  height: '200px', 
                  overflow: 'auto',
                  whiteSpace: 'pre'
                }}
                readOnly
              />
            </div>
            <div className="col-4 col-lg-4 my-0 offset-1">
              {/*
              {addable && (
              <div className="my-4">
                <button 
                    disabled={isUpdatingPurchase || !editable || !addable }
                    onClick={() => {addItemRowHandler();}} 
                    className="btn btn-primary"
                    style={{width: '100%'}}>
                    Add More Items
                </button>
              </div>
              )}
              */}
              {/* Update Button */}
              {editable && (
              <div className="my-4">
                <button 
                    disabled={!thereAreValueChanges || isUpdatingPurchase || !editable}
                    onClick={() => {updatePurchaseHandler();}} 
                    className="btn btn-success"
                    style={{width: '100%'}}>
                    Update
                </button>
              </div>)}

              {/* Purchase Summary */} 
              <div>
                <div id="purchase_summary">
                  <h4>Purchase Summary</h4>
                  <hr />
                  <p>
                    Units:{" "}
                    <span className="purchase-summary-values">
                      {units} (Units)
                    </span>
                  </p>
                  <p>
                    Est. size:{" "}
                    <span className="purchase-summary-values">
                      {estPallet?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (Pallet)
                    </span>
                  </p>
                  <hr />
                  <p>
                    Item Total:{" "}
                    <span className="purchase-summary-values">
                      $
                      { itemTotal !== undefined ? itemTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0 }
                    </span>
                  </p>      
                  <p>
                    Tax:{" "}
                    <span className="purchase-summary-values">
                      $
                      { tax !== undefined ? tax.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0 }
                    </span>
                  </p>      
                  <hr />
                  <p>
                    <strong>Total:{" "}</strong>
                    <span className="purchase-summary-values">
                      $
                      { total !== undefined ? total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0 }
                    </span>
                  </p>            
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog message={`Are you sure to remove ${clonedPurchaseItems[itemIndexToRemove] ? clonedPurchaseItems[itemIndexToRemove].name :"row"}?`} show={itemIndexToRemove !== -1} confirm={()=>{confirmRemovingItem(itemIndexToRemove)}} cancel={()=> {showRemoveItemConfirmDialog(-1)}}> </ConfirmDialog>
        <ConfirmDialog message={`Are you sure to delete this purchase?`} show={showingDeletePurchaseConfirm} confirm={()=>{confirmDeletingPurchase()}} cancel={()=> {showDeletePurchaseConfirmDialog(false)}}> </ConfirmDialog>
        <ConfirmDialogUpdateDeliveredOrder 
          message={`Are you sure to update a purchase that has been completed?`} 
          show={showingUpdateCompletedPurchaseConfirm} 
          updateStock={()=>{confirmUpdatingCompletedPurchase(true)}} 
          dontUpdateStock={()=>{confirmUpdatingCompletedPurchase(false)}} 
          cancel={()=> {showUpdateCompletedPurchaseConfirmDialog(false)}}> 
        </ConfirmDialogUpdateDeliveredOrder>
        <ConfirmDialogReceivePurchase 
            title={`Receive Purchase # ${purchaseDetail.purchaseNumber}`}
            invoice={purchaseDetail.paymentInfo.invoice}
            show={showingReceivePurchaseConfirm} 
            itemCount={units}
            confirm={()=>{confirmReceivePurchase()}} 
            cancel={()=> {showReceivePurchaseConfirm(false)}}> 
        </ConfirmDialogReceivePurchase>
      </AdminLayout>
    </>
  );
};

export default ProcessPurchase;
