import pdfMake from 'pdfmake/build/pdfmake';
import vfs_fonts from 'pdfmake/build/vfs_fonts'; 
import { COMPANY_ADDRESS_LINE1, COMPANY_ADDRESS_LINE2, COMPANY_ADDRESS_LINE3, COMPANY_ADDRESS_PHONE, COMPANY_EMAIL, COMPANY_NAME} from "../../constants/constants.js";
import { removeReduntantBusinessNameFromAddress } from "../../utils/utilities.js";
import { logoBase64 } from './logoBase64.js'; // Import the base64 encoded logo image

export const generatePackingSlipPDF = async (order) => {
  const MIN_ROWS = 10; // Set your desired minimum number of rows

  const { orderItems, orderNumber, addressInfo } = order;
  const customerName = order.customer ? order.customer.name : order.customerName;

  const loadFonts = async () => {
    try {
      // Assign the fonts to pdfMake
      pdfMake.vfs = vfs_fonts.vfs;
      // Configure pdfMake to use the fonts
      pdfMake.fonts = {
        NotoSansSC: {
          normal: 'NotoSansSC-Regular.ttf',
          bold: 'NotoSansSC-Bold.ttf',
          medium: 'NotoSansSC-Medium.ttf',
        }
      };
      
      // Log the font configuration to verify
      console.log('Font configuration:', pdfMake.fonts);
  
      console.log('Fonts loaded successfully');
    } catch (error) {
      console.error('Error loading fonts:', error);
    }
  };
  
  // Function to split the address into parts
  const splitAddress = (address) => {
    const parts = address.split(',').map(part => part.trim());
    const length = parts.length;
  
    if (length === 1) {
      return [address, ''];
    } else if (length === 2) {
      return [parts[0], parts[1]];
    } else {
      const billLine1 = parts.slice(0, length - 2).join(', ');
      const billLine2 = `${parts[length - 2]}, ${parts[length - 1]}`;
      return [billLine1, billLine2];
    }
  };

  const generatePDF = async () => {
    const formattedOrderCreateTime = new Date(order.orderDate).toLocaleDateString();
    const billAddress = removeReduntantBusinessNameFromAddress(customerName, addressInfo?.billingAddress);
    const shipAddress = removeReduntantBusinessNameFromAddress(customerName, addressInfo?.address);

    const [billLine1, billLine2] = splitAddress(billAddress);
    const [addressLine1, addressLine2] = splitAddress(shipAddress);

    await loadFonts();
  
    // Define the table structure
    const tableBody = [
      [
        { text: 'Quantity', style: 'tableHeader', alignment: 'center' },
        { text: 'Description', style: 'tableHeader', alignment: 'center' },
      ],
      ...filledOrderItems.map(item => [
        { text: item.finalQuantity !== undefined ? item.finalQuantity : ' ', alignment: 'right' },
        { text: item.finalQuantity !== undefined ? `${item.desc ? item.desc : ''} ${item.note ? `(${item.note})` : ''}` : ' ', alignment: 'left' },
      ]),
    ];
  
    // Define the document
    const headerImageHeight = 250; // Set the height of the header image
    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [20, headerImageHeight, 20, 90],
      defaultStyle: {
        font: 'NotoSansSC',
        fontSize: 13 // Adjust the font size
      },
      header: function(currentPage, pageCount) {
        return {
          stack: [
            {
              // Top container
              columns: [
                {
                  stack: [
                    { text: COMPANY_NAME, style: 'companyName' },
                    { text: COMPANY_ADDRESS_LINE1, style: 'addressLabel' },
                    { text: COMPANY_ADDRESS_LINE2, style: 'addressLabel' },
                    { text: COMPANY_ADDRESS_LINE3, style: 'addressLabel' },
                    { text: COMPANY_ADDRESS_PHONE, style: 'addressLabel' },
                    { text: COMPANY_EMAIL, style: 'addressLabel' }
                  ],
                  width: '35%'
                },
                {
                  stack: [
                    {
                      image: logoBase64,
                      width: 80, // Adjust the width of the image as needed
                      alignment: 'center'
                    }
                  ],
                  width: '30%'
                },
                {
                  stack: [
                    { text: `PACKING SLIP`, style: 'invoiceLabel' },
                    {
                      table: {
                        widths: ['*', '*'],
                        body: [
                          [
                            { text: 'Date', style: 'labelLarge', alignment: 'center' },
                            { text: 'Invoice #', style: 'labelLarge', alignment: 'center' }
                          ],
                          [
                            { text: formattedOrderCreateTime, style: 'header', alignment: 'center' },
                            { text: orderNumber, style: 'header', alignment: 'center' }
                          ]
                        ]
                      }
                    }
                  ],
                  width: '35%',
                  alignment: 'right'
                }
              ]
            },
            {
              // empty space
              text: '',
              margin: [0, 10] 
            },
            {
              // billing and shipping info
              columns: [
                {
                  stack: [
                    { 
                      table: {
                        headerRows: 1,
                        widths: ['*'],
                        body: [
                          [
                            { text: 'Bill To', style: 'labelLarge', alignment: 'center'},
                          ],
                          [
                            {
                              stack: [
                                { text: customerName, style: 'header', alignment: 'left' },
                                { text: billLine1, style: 'header', alignment: 'left' },
                                { text: billLine2, style: 'header', alignment: 'left' },
                              ]
                            }
                          ],
                        ],
                      } 
                    }
                  ],
                  width: '48%'
                },
                {
                  stack: [],
                  width: '4%'
                },
                {
                  stack: [
                    { 
                      table: {
                        headerRows: 1,
                        widths: ['*'],
                        body: [
                          [
                            { text: 'Ship To', style: 'labelLarge', alignment: 'center'},
                          ],
                          [
                            {
                              stack: [
                                { text: customerName, style: 'header', alignment: 'left' },
                                { text: addressLine1, style: 'header', alignment: 'left' },
                                { text: addressLine2, style: 'header', alignment: 'left' },
                              ]
                            }
                          ],
                        ],
                      } 
                    }
                  ],
                  width: '48%'
                },
              ]
            },
          ],
          margin: [20, 10, 20, 10] // Margin around the header container
        };
      },
      content: [
        {
          table: {
            headerRows: 1,
            widths: ['30%', '70%'],
            body: tableBody
          },
          layout: {
            hLineWidth: function(i, node) {
              return (i === 0 || i === 1 || i === node.table.body.length ) ? 1 : 0; // Only draw horizontal lines for the header and footer
            },
            vLineWidth: function(i, node) {
              return 1; // Draw vertical lines for all cells
            },
            hLineColor: function(i, node) {
              return '#000000'; // Black color for horizontal lines
            },
            vLineColor: function(i, node) {
              return '#000000'; // Black color for vertical lines
            }
          }
        }
      ],
      styles: {
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: 'black',
          fillColor: '#f2f2f2',
          alignment: 'center',
        },
        labelLarge: {
          fontSize: 12, // Larger font size
          bold: true // Bold text
        },
        labelSmall: {
          fontSize: 10, // Larger font size
          bold: true // Bold text
        },
        companyName: {
          fontSize: 15, // Larger font size
          bold: true // Bold text
        },
        invoiceLabel: {
          fontSize: 15, // Larger font size
          bold: true // Bold text
        },
        addressLabel: {
          fontSize: 10, // Larger font size
        },
        termSmall: {
          fontSize: 8, // Larger font size
          bold: true // Bold text
        },
      },
      footer: function(currentPage, pageCount) {
        return {
          
            text: `Page ${currentPage} of ${pageCount}`,
            alignment: 'center',
            margin: [0, 10, 0, 10]
        };
      }
    };
  
    // Generate the PDF and open it in a new tab
    return pdfMake.createPdf(docDefinition);
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const filledOrderItems = [...orderItems];
  while (filledOrderItems.length < MIN_ROWS) {
    filledOrderItems.push({}); // Add empty objects to fill the rows
  }
  const formattedOrderCreateTime = new Date(order.orderDate).toLocaleDateString();
  const formattedBillPhoneNumber = formatPhoneNumber(addressInfo?.phone);
  const formattedShipPhoneNumber = formatPhoneNumber(addressInfo?.phone);

  const pdf = await generatePDF();
  return pdf;
}
