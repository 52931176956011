import React, { useEffect, useState,  } from "react";
import Loader from "../../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import MetaData from "../../layout/MetaData";
import DatePicker from "react-datepicker";
import { toZonedTime, format, addDays } from 'date-fns-tz';
import { getHours } from 'date-fns';

import AdminLayout from "../../layout/AdminLayout";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { ConfirmDialog } from "../ConfirmDialog";
import {
  useAdminDeletePurchaseMutation,
  useLazyAdminGetPurchasesByDateQuery,
  useAdminForceResyncOnePurchaseQuickbooksMutation,
  useAdminForceResyncMultiplePurchaseQuickbooksMutation,
} from "../../../redux/api/purchaseAdminApi";
import { setAdminOptions } from "../../../redux/features/adminOptionsSlice";
import { ADMIN_MENU_ITEMS, PURCHASE_STATUS, PURCHASE_STATUS_ICON, PURCHASE_USER_RIGHTS, QUICKBOOKS_CATEGORY, USER_ROLES } from "../../../constants/constants.js";
import { CanUserCreatePurchase, CanUserCreditPurchase, DetermineUserPurchaseRights } from "../AdminActionEligibilities";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { CenterAlignedCell } from "../../layout/CustomMDBDataTableCell";
import { appendAppVersionToUrl, getDateEndTimeInPST, getDateStartTimeInPST } from "../../../utils/utilities.js";
import QuickbooksIcon from "../../layout/QuickbooksIcon.jsx";

const ListPurchases = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [purchaseToBeDeleted, showDeletePurchaseConfirmDialog] = useState(null);

  // Redux
  const { loadingAdmin, user, viewPurchaseHistory, unviewedNewPurchase, unviewedUpdatedPurchase } = useSelector((state) => state.adminAuth);
  //const { inProgressPurchaseData } = useSelector((state) => state.inProgressPurchases);
  const [AdminGetPurchasesByDate, {data: inProgressPurchaseData} ] = useLazyAdminGetPurchasesByDateQuery(undefined, { refetchOnMountOrArgChange: true });
  const { adminOptions } = useSelector((state) => state.adminOptions);

  const [adminDeletePurchase, { isLoading: isDeletingPurchase, error: purchaseDeleteError, isSuccess: purchaseDeleteSuccess }] = useAdminDeletePurchaseMutation();
  const [AdminForceResyncOnePurchaseQuickbooks, { data: quickbooksResyncData, isLoading: isForcingQuickbooksResync }] = useAdminForceResyncOnePurchaseQuickbooksMutation();
  const [AdminForceResyncMultiplePurchaseQuickbooks, { data: quickbooksResyncMultipleData, isLoading: isForcingQuickbooksResyncMultiple }] = useAdminForceResyncMultiplePurchaseQuickbooksMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [purchaseData, setPurchaseData] = useState(null);
  const [todayDate, setTodayDate] = useState(new Date());

  const [sortField, setSortField] = useState();
  const [sortingPurchase, setSortingPurchase] = useState();
  const [sortedPurchases, setSortedPurchases] = useState([]);

  const [totalPurchaseCount, setTotalPurchaseCount] = useState({});

  // Default view to pending purchase if there is no previous selection
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultView = searchParams.get('defaultView');

  const [filterDate, setFilterDate] = useState(new Date());
  const [purchaseFilter, setPurchaseFilter] = useState("ALL");
  
  const [errorLoadingPurchase, setErrorLoadingPurchase] = useState(null);
  const [isLoadingPurchase, setIsLoadingPurchase] = useState(false); 

  const [selectedQBResyncOption, setSelectedQBResyncOption] = useState("All");

  const assignPurchaseData = () => {
    const pendingCount = inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.PENDING).length || 0;
    const orderedCount = inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.ORDERED).length || 0;
    const confirmedCount = inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.CONFIRMED).length || 0;
    const deliveredCount = inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.DELIVERED).length || 0;
    const allCount = inProgressPurchaseData?.purchases?.length || 0;

    setTotalPurchaseCount({
      [PURCHASE_STATUS.PENDING]: pendingCount,
      [PURCHASE_STATUS.ORDERED]: orderedCount,
      [PURCHASE_STATUS.CONFIRMED]: confirmedCount,
      [PURCHASE_STATUS.DELIVERED]: deliveredCount,
      ALL: allCount,
    });

    switch (purchaseFilter) {
      case PURCHASE_STATUS.PENDING:
        const pendingPurchaseData = {purchases : inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.PENDING)};
        setPurchaseData(pendingPurchaseData);
        break;
      case PURCHASE_STATUS.ORDERED:
        const orderedPurchaseData = {purchases : inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.ORDERED)};
        setPurchaseData(orderedPurchaseData);
        break;
      case PURCHASE_STATUS.CONFIRMED:
        const confirmedPurchaseData = {purchases : inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.CONFIRMED)};
        setPurchaseData(confirmedPurchaseData);
        break;
      case PURCHASE_STATUS.DELIVERED:
        const deliveredPurchaseData = {purchases : inProgressPurchaseData?.purchases?.filter(purchase => purchase.status === PURCHASE_STATUS.DELIVERED)};
        setPurchaseData(deliveredPurchaseData);
        break;
      case "ALL":
        setPurchaseData(inProgressPurchaseData);
        break
      default:
        break;
    }
  }

// ---------------------------- Sorting Functions ----------------------------
const handleSortPurchaseClick = (field) => {
  if (sortField === field) {
    setSortingPurchase(sortingPurchase === 'asc' ? 'desc' : 'asc');
  } else {
    setSortField(field);
    setSortingPurchase('asc');
  }
};

const handleSort = () => {
  const newSortedPurchases = purchaseData?.purchases ? [...purchaseData?.purchases] : [];
  if (sortingPurchase && sortField ) {  
    newSortedPurchases.sort((a, b) => {
      if (sortingPurchase === 'asc') {
        return customSortFunctions[sortField](a, b);
      } else {
        return customSortFunctions[sortField](b, a);
      }
    });
  }
  setSortedPurchases(newSortedPurchases);
};

const customSortFunctions = {
  purchaseStatus: (a, b) => {
    const purchaseStatusList = [PURCHASE_STATUS.PENDING, PURCHASE_STATUS.ORDERED, PURCHASE_STATUS.CONFIRMED, PURCHASE_STATUS.DELIVERED];
    return purchaseStatusList.indexOf(a.status) - purchaseStatusList.indexOf(b.status);
  },
  vendor: (a, b) => a.vendorName.localeCompare(b.vendorName),
};

useEffect(() => {
  const _todayDate =  toZonedTime(new Date(), 'America/Los_Angeles');
  const hours = getHours(_todayDate);
  let adjustedDate = _todayDate;
  if (hours >= 18) { // 6 PM is 18:00 in 24-hour format
    adjustedDate = new Date(_todayDate.getTime() + 86400 * 1000); // 86400 seconds * 1000 milliseconds
  }
  setFilterDate(adjustedDate);
  setTodayDate(adjustedDate);
}, []);

useEffect(() => {
  handleSort();
}, [sortingPurchase, sortField, purchaseData]);
//--------------------------------------------------------------------------
  
  useEffect(() => {
    assignPurchaseData();
  }, [inProgressPurchaseData]);

  useEffect(() => {
    // Save selection
    assignPurchaseData();
  }, [purchaseFilter]);


  useEffect(() => {
    if (errorLoadingPurchase) {
      toast.error(errorLoadingPurchase?.data?.message);
      if (errorLoadingPurchase.status === 401) {
        adminLogout();
      }
    }
  }, [errorLoadingPurchase]);

  useEffect(() => {
    if (purchaseDeleteError) {
      toast.error(purchaseDeleteError?.data?.message);
    }
    /*
    if (purchaseDeleteSuccess) {
      toast.success("Purchase Removed");
    }*/
  }, [purchaseDeleteError, purchaseDeleteSuccess]);

  useEffect(() => {
    AdminGetPurchasesByDate({startDate: getDateStartTimeInPST(filterDate), endDate: getDateEndTimeInPST(filterDate)});
  }, [filterDate]);

  useEffect(() => {
    if (adminOptions) {
      if (adminOptions.allPurchaseStatusFilter) {
        setPurchaseFilter(adminOptions.allPurchaseStatusFilter);
      }
      if (adminOptions.allPurchasesDateFilter) {
        setFilterDate(adminOptions.allPurchasesDateFilter);
      }
    }
  }, [adminOptions]);

  // --------------------------------- Render ----------------------------------
  // If the purchase, user are not loaded yet, show the loader
  if (user === null || loadingAdmin || purchaseData === null) {
    return <Loader />;
  }

  //--------------------------------- Functions ---------------------------------
  const handlePurchaseStatusFilterChange = (status) => {
    setPurchaseFilter(status);
    
    const newAdminOptions = {...adminOptions, allPurchaseStatusFilter: status};
    dispatch(setAdminOptions(newAdminOptions));
  };
  const handleFilterDateChange = (date) => {
    setFilterDate(date);

    const newAdminOptions = {...adminOptions, allPurchasesDateFilter: date};
    dispatch(setAdminOptions(newAdminOptions));
  };
  

  // Delete purchase
  const handleDeletePurchase = (purchase) => {
    if (!isDeletingPurchase && purchase !== null) showDeletePurchaseConfirmDialog(purchase);
  }

  // Confirm deleting purchase
  const confirmDeletingPurchase = () => {
    if (!isDeletingPurchase && purchaseToBeDeleted !== null) {
      adminDeletePurchase(purchaseToBeDeleted._id);
      showDeletePurchaseConfirmDialog(null);
    }
  }

  const createNewPurchaseVendorCreditHandler = () => {
    // Redirect to create new order page
    navigate(appendAppVersionToUrl('/admin/vendorcredits/new'));
  }

  // Create new purchase
  const createNewPurchaseHandler = () => {
    // Redirect to create new purchase page
    navigate(appendAppVersionToUrl("/admin/purchases/new"));
  }


  const handlePurchaseRowClick = (id) => {
    const isHistory = inProgressPurchaseData?.isHistory;
    navigate(appendAppVersionToUrl(isHistory ? `/admin/oldpurchases/${id}` : `/admin/purchases/${id}`));
  }

  const isBeforeDate = (date1, date2) => {
    const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    return d1 < d2;
  };

  const checkPurchaseStatus = (purchase) => {
    const purchaseDateObj = new Date(purchase.purchaseDate);
    const todayDateObj = new Date(todayDate);
    if (isBeforeDate(purchaseDateObj, todayDateObj)) {
      return {isNew: false, updated: false};
    }

    const isNew = purchase.needAttn &&  viewPurchaseHistory && viewPurchaseHistory[purchase._id] === undefined;
    const updated = purchase.needAttn && viewPurchaseHistory && viewPurchaseHistory[purchase._id]?.timestamp < new Date(purchase.updatedAt).getTime();

    return {isNew, updated};
  }

  // Quickbooks Resync
  const forceResynceOnePurchase = (id) => {
    AdminForceResyncOnePurchaseQuickbooks({ id, isHistory: inProgressPurchaseData?.isHistory} );
  };

  const QBResyncOptionChange = (e) => {
    setSelectedQBResyncOption(e.target.value);
  };

  const executeResyncMultiple = () => {
    AdminForceResyncMultiplePurchaseQuickbooks({ body: { startDate: getDateStartTimeInPST(filterDate), endDate: getDateEndTimeInPST(filterDate), option: selectedQBResyncOption } });
  }

  const canCreateNewPurchase = CanUserCreatePurchase(user);
  const canCreditPurchase = CanUserCreditPurchase(user);

  const setPurchases = (isCR) => {
    const purchases = {
      columns: [
        {
          label: <CenterAlignedCell value={""}> </CenterAlignedCell>,
          field: "id",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={isCR ? "Credit #" : "Purchase #"}> </CenterAlignedCell>,
          field: "purchaseNumber",
          sort: "asc",
        },
        {
          label: (
            <div onClick={() => handleSortPurchaseClick('vendor')}>
              <CenterAlignedCell value={"Vendor"} />
            </div>
          ),
          field: "vendor",
          sort: "disabled",
        },
        {
          label: (
            <div onClick={() => handleSortPurchaseClick('purchaseStatus')}>
              <CenterAlignedCell value={"Status"} />
            </div>
          ),
          field: "purchaseStatus",
          sort: 'disabled', 
          onClick: () => handleSortPurchaseClick('purchaseStatus'),
        },
        {
          label: <CenterAlignedCell value={"Invoice #"}> </CenterAlignedCell>,
          field: "invoiceNumber",
          sort: "asc",
        },        
        {
          label: <CenterAlignedCell value={"Date"}> </CenterAlignedCell>,
          field: "purchaseDate",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Note"}> </CenterAlignedCell>,
          field: "note",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };
    let index = 1;
    sortedPurchases?.forEach((purchase) => {
      if ((isCR && !purchase.isCR) || (!isCR && purchase.isCR)) {
        return;
      }

      // Check if user has rights to delete purchase
      const {rights} = DetermineUserPurchaseRights(user, purchase.status);
      const deleteable = rights?.some(right => right === PURCHASE_USER_RIGHTS.DELETE);
      const { isNew, updated } = checkPurchaseStatus(purchase);
      const purchaseNumberString = purchase.isCR ? `CR-${purchase.purchaseNumber}`: purchase?.purchaseNumber;

      purchases.rows.push({
        clickEvent: () => handlePurchaseRowClick(purchase._id),
        id: <CenterAlignedCell value={index ++}> </CenterAlignedCell>,
        purchaseNumber: <span style={{ color: purchase.isCR ? 'orange' : '' }}>{purchaseNumberString}</span>,
        vendor: purchase.vendor ? purchase.vendor.name : purchase.vendorName,
        purchaseStatus: purchase.isCR 
                          ? '' 
                          : <span>{ PURCHASE_STATUS_ICON[purchase.status] && (<i className={PURCHASE_STATUS_ICON[purchase.status].icon}  style={{ color: PURCHASE_STATUS_ICON[purchase.status].color }} /> )} {String(purchase?.status).toUpperCase()}</span>,
        purchaseDate: <CenterAlignedCell value = {purchase?.purchaseDate}> </CenterAlignedCell>,
        invoiceNumber: <CenterAlignedCell value={purchase.paymentInfo?.invoice}> </CenterAlignedCell>,
        actions: (
          <center>
            {(user?.role === USER_ROLES.SUPER_ADMIN || user?.role === USER_ROLES.ADMIN || user?.role === USER_ROLES.ACCOUNTANT) && !purchase.noQB &&  (
              <QuickbooksIcon  qbCategory={QUICKBOOKS_CATEGORY.PURCHASE} qbRef={purchase.purchaseNumber}  QBStatus={purchase.QBStatus} function1={() => forceResynceOnePurchase(purchase._id)}/>
            )}
            { deleteable && (
            <button
              className="btn btn-outline-danger ms-2"
              onClick={(event) => { event.stopPropagation(); handleDeletePurchase(purchase);} }
              disabled={isDeletingPurchase || purchaseToBeDeleted !== null }
            >
              <i className="fa fa-trash"></i>
            </button>)}
          </center>
        ),
        note: 
        <div className="d-flex align-items-center" style={{ width: '100%' }}>
          {isNew && (
            <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red', width: '100%' }}>New</div>
          )}

          {!isNew && updated && (
            <div className="text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'orange', width: '100%' }}>Updated</div>
          )}
        </div>,
      });
    });

    return purchases;
  };

  const appendPurchaseOption = (status) => {
    let totalUnviewedNewPurchase = 0;
    let totalUnviewedUpdatedPurchase = 0;
    let totalCount = totalPurchaseCount[status] ? totalPurchaseCount[status] : 0;
    /*
    if (status === "ALL") {
      Object.keys(unviewedNewPurchase).forEach((key) => {
        totalUnviewedNewPurchase += unviewedNewPurchase[key];
      });
      Object.keys(unviewedUpdatedPurchase).forEach((key) => {
        totalUnviewedUpdatedPurchase += unviewedUpdatedPurchase[key];
      });
      totalCount = totalPurchaseCount["ALL"] ? totalPurchaseCount["ALL"] : 0;
    } else {
      totalCount = totalPurchaseCount[status]
      totalUnviewedNewPurchase = unviewedNewPurchase[status] ? unviewedNewPurchase[status] : 0;
      totalUnviewedUpdatedPurchase = unviewedUpdatedPurchase[status] ? unviewedUpdatedPurchase[status] : 0
    }
    let leftString = `${String(status).toUpperCase()}`;
    const unviewedNewString = totalUnviewedNewPurchase > 0 ? `(${totalUnviewedNewPurchase} New)` : "";
    const unviewUpdatedString = totalUnviewedUpdatedPurchase > 0 ? `(${totalUnviewedUpdatedPurchase} Updates)` : "";
    const rightString = `${unviewedNewString}${unviewUpdatedString}`;
    if (totalCount > 0) {
      leftString = leftString + ` (${totalCount})`;
    }
    const optionString = rightString.length > 0 ? `${leftString}\u00A0\u00A0\u00A0-\u00A0\u00A0\u00A0${rightString}` : leftString;
    */
    let optionString = `${String(status).toUpperCase()}`;
    if (totalCount > 0) {
      optionString = optionString + ` (${totalCount})`;
    }

    return (
      <option value={status}>
        { optionString }
      </option>
    );
  }

  const nonCRPurchases = setPurchases(false);
  const CRPurchases = setPurchases(true);

  return (
    <>
      <MetaData title={"All Purchases"} />
      <HeaderAdmin title={"All Purchases"}  bg_color={"lightblue"}/>
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.PURCHASES.name}>
        <div id = "purchase_table" className="col-11 col-lg-11 my-0 offset-lg-0">
          <div className="row my-3 col-12">
            {/* Filter by status */}
            <div className="col-4">
              <text className="text-start" style={{ display: 'block', width: '100%' }}>Purchase Status</text>
              <select
                id="type_field"
                className="form-select"
                name="purchaseFilter"
                value={purchaseFilter}
                onChange={(e) => handlePurchaseStatusFilterChange(e.target.value)}
              >
                {appendPurchaseOption("ALL")}
                {appendPurchaseOption(PURCHASE_STATUS.PENDING)}
                {appendPurchaseOption(PURCHASE_STATUS.ORDERED)}
                {appendPurchaseOption(PURCHASE_STATUS.CONFIRMED)}
                {appendPurchaseOption(PURCHASE_STATUS.DELIVERED)}
              </select>
            </div>

            {/* Filter by pruchase date */}
            <div className="column col-4">
              <div className="form-group" style={{ width: '100%' }}>
                <label className="text-start" style={{ display: 'block', width: '100%' }}>Purchase Date</label>
                <DatePicker
                  selected={filterDate}
                  onChange={handleFilterDateChange}
                  dateFormat="MM/dd/yyyy"
                  className="form-control"
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-3 row my-0">
              {(user?.role === USER_ROLES.SUPER_ADMIN || user?.role === USER_ROLES.ADMIN || user?.role === USER_ROLES.ACCOUNTANT ) && (
                <div className="col-7">
                  <select className="form-select"
                    aria-label="Quickbooks Resync"
                    onChange={ (e) => QBResyncOptionChange(e) }
                    selected={selectedQBResyncOption}
                    defaultValue="All"
                  >
                    <option key ={"all"} value="All">All</option>
                    <option key={"unsyncedonly"} value='NotSynced'>Un-Synced</option>
                    {(user?.role === USER_ROLES.SUPER_ADMIN && user?.name === "KingGeorge") && (
                      <option key={"alltime"} value='AllTime'>All Time</option>
                    )}
                  </select>
                </div>
              )}
              {(user?.role === USER_ROLES.SUPER_ADMIN && user?.name === "KingGeorge") && (
                <div className="col-5">
                  <button 
                      onClick={() => {executeResyncMultiple();}} 
                      className="btn btn-success"
                      style={{width: '100%'}}
                  >
                      QB Sync
                  </button>
                </div>
              )}  
            </div>
            <div className="col-6">
              <h1 className="text-center">{nonCRPurchases.rows.length} Purchases</h1>
            </div>
            <div className="col-3">
              {/*New purchase Button - rigjht */}
              <div>
                {canCreateNewPurchase && (
                <button 
                  onClick={() => {createNewPurchaseHandler();}} 
                  className="btn btn-primary"
                  style={{width: '100%'}}>
                  Create New Purchase
                </button>)}     
              </div>
              <div className="py-2">
                {/* Admin only */}
                { canCreditPurchase && (
                  <button 
                    onClick={() => {createNewPurchaseVendorCreditHandler();}} 
                    className="btn"
                    style={{width: '100%', backgroundColor: 'orange'}}
                  >
                      New Vendor Credit
                  </button>          
               )}
              </div>
            </div>
          </div>
          {/* Loader */}
          {(isForcingQuickbooksResync || isForcingQuickbooksResyncMultiple ) && (
            <div style={{position: 'relative', height: '100%', width: '100%'}}>
              <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                <Loader />
              </div>
            </div>
          )}
          <MDBDataTable
            data={nonCRPurchases}
            className="px-10 myMDBDataListPurchaseTableRow"
            bordered
            striped
            hover
            noBottomColumns
            searching={true}
            paging={false}
          />

          { CRPurchases.rows.length > 0 && (
            <h1 className="text-center width-100">{CRPurchases.rows.length} Vendor Credits</h1>
          )}
          {CRPurchases.rows.length > 0 && (
              <MDBDataTable
                data={CRPurchases}
                className="px-10 myMDBDataListPurchaseTableRow"
                bordered
                striped
                hover
                noBottomColumns
                searching={true}
                paging={false}
              />
          )}
          <ConfirmDialog message={`Are you sure to delete purchase #${purchaseToBeDeleted?.purchaseNumber}?`} show={purchaseToBeDeleted !== null} confirm={()=>{confirmDeletingPurchase()}} cancel={()=> {showDeletePurchaseConfirmDialog(null)}}> </ConfirmDialog>
        </div>
      </AdminLayout>
      {isLoadingPurchase && <Loader />}
    </>
  );
};

export default ListPurchases;
