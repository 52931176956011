import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import Loader from "../layout/Loader";
import { INACTIVE_LOG_OUT_TIME, USER_ROLES } from "../../constants/constants.js";
import { useAdminGetMeQuery } from "../../redux/api/adminApi";
import { useLazyAdminGetInProgressOrdersQuery } from "../../redux/api/orderAdminApi";
import { useLazyAdminGetInProgressPurchasesQuery } from "../../redux/api/purchaseAdminApi";
import { CanUserViewOrder, CanUserViewPurchase } from "../admin/AdminActionEligibilities";
import { useLazyAdminReadLogsQuery } from "../../redux/api/adminApi";

import { updateUserUnviewedOrders, updateUserUnviewedPurchases } from "../../redux/features/adminSlice";
import { useLazyAdminLogoutQuery } from "../../redux/api/adminAuthApi.js";

//import { checkAndUpdateVersion } from "../../utils/clearCache.js";

export const ProtectedAdminRoute = ({ children, role }) => {
  const dispatch = useDispatch();

  const { isLoading } = useAdminGetMeQuery();
  const { isAuthenticatedAdmin, loadingAdmin, user, logTimestamp, needReloadingLogs } = useSelector((state) => state.adminAuth);
  const { inProgressOrderData } = useSelector((state) => state.inProgressOrders);
  const { inProgressPurchaseData } = useSelector((state) => state.inProgressPurchases);

  const [adminReadLogs] = useLazyAdminReadLogsQuery();
  const [AdminGetInProgressOrdersQuery ] = useLazyAdminGetInProgressOrdersQuery(undefined, { refetchOnMountOrArgChange: true });
  const [AdminGetInProgressPurchasesQuery ] = useLazyAdminGetInProgressPurchasesQuery(undefined, { refetchOnMountOrArgChange: true });

  // Logout inactive admin
  const [adminLogout] = useLazyAdminLogoutQuery();
  const logoutTimerRef = useRef(null);
  const debounceTimeoutRef = useRef(null);

  const location = useLocation();

  /*
  useEffect(() => {
    // Check and update version on page load
    checkAndUpdateVersion(location.pathname);
  }, [location.pathname]);
  */

  const debounce = (func, delay) => {
    return (...args) => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
      debounceTimeoutRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Reset timer for inactive admin logout
  const resetTimer = () => {
    if (logoutTimerRef.current) {
      clearTimeout(logoutTimerRef.current);
    }
    const newExpiryTime = Date.now() + INACTIVE_LOG_OUT_TIME;
    localStorage.setItem('expiryTime', newExpiryTime);

    logoutTimerRef.current = setTimeout(() => {
      const expiryTime = localStorage.getItem('expiryTime');
      if (expiryTime && Date.now() > expiryTime) {
        console.log('Admin is inactive for too long. Logging out...');
        adminLogout();
      }
    }, INACTIVE_LOG_OUT_TIME);
  };

  // rate limit the resetTimer function
  const debouncedResetTimer = debounce(resetTimer, 1000);

  // add event listeners for mousemove, keypress, click, and scroll events
  useEffect(() => {
    window.addEventListener('keypress', debouncedResetTimer);
    window.addEventListener('click', debouncedResetTimer);
    window.addEventListener('scroll', debouncedResetTimer);

    resetTimer();

    return () => {
      window.removeEventListener('keypress', debouncedResetTimer);
      window.removeEventListener('click', debouncedResetTimer);
      window.removeEventListener('scroll', debouncedResetTimer);
      if (logoutTimerRef.current) {
        clearTimeout(logoutTimerRef.current);
      }
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);
  //-------------------------- End of AdminInactiveLogout --------------------------

  useEffect(() => {
    if (isAuthenticatedAdmin && user && CanUserViewOrder(user) && CanUserViewPurchase(user)) {
      AdminGetInProgressOrdersQuery();
      AdminGetInProgressPurchasesQuery();
      if (needReloadingLogs) {
        adminReadLogs({timestamp: logTimestamp});
      }
    }
  }, [isAuthenticatedAdmin, user]);

  useEffect(() => {
    if (user && inProgressOrderData && inProgressOrderData.orders) {
      const orderStatsHash = {};
      inProgressOrderData.orders.forEach(order => {
        const timestamp = new Date(order.updatedAt).getTime();
        orderStatsHash[order._id] = {timestamp, status: order.status, needAttn: order.needAttn};
      });
      dispatch(updateUserUnviewedOrders(orderStatsHash));
    }

    if (user && inProgressPurchaseData && inProgressPurchaseData.purchases) {
      const purchaseStatsHash = {};
      inProgressPurchaseData.purchases.forEach(purchase => {
        const timestamp = new Date(purchase.updatedAt).getTime();
        purchaseStatsHash[purchase._id] = {timestamp, status: purchase.status, needAttn: purchase.needAttn};
      });
      dispatch(updateUserUnviewedPurchases(purchaseStatsHash));
    }
  }, [inProgressOrderData, inProgressPurchaseData, user]);

  if (loadingAdmin || isLoading) return <Loader />;

  if (!user || !isAuthenticatedAdmin || (!role.includes(user.role))) {
    return <Navigate to="/admin" replace />;
  }

  return children;
};
