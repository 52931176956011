import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { Link, useNavigate } from "react-router-dom";
import MetaData from "../../layout/MetaData";
import { useSelector } from "react-redux";

import AdminLayout from "../../layout/AdminLayout";

import {
  useAdminDeleteUserMutation,
  useAdminGetAllUsersQuery,
} from "../../../redux/api/adminApi";
import { ADMIN_MENU_ITEMS, USER_ROLES } from "../../../constants/constants.js";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { ConfirmDialog } from "../ConfirmDialog";
import { CanDeleteUser, CanCreateUser, CanUpdateUser } from "../AdminActionEligibilities";
import { CenterAlignedCell } from "../../layout/CustomMDBDataTableCell";
import { appendAppVersionToUrl } from "../../../utils/utilities.js";

const ListUsers = () => {
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const [adminLogout] = useLazyAdminLogoutQuery(undefined, { refetchOnMountOrArgChange: true });
  const { data, isLoading, error } = useAdminGetAllUsersQuery();
  const [adminDeleteUser, { data: deletedUserData, error: deleteError, isLoading: isDeleteLoading, isSuccess: deletedUserSuccess }] = useAdminDeleteUserMutation();
  const [showingDeleteConfirmUserIndex, showDeleteUserConfirmDialog] = useState(-1);
  const navigate = useNavigate();

  /*
  useEffect(() => {
    localStorage.setItem('lastVisitedPage', window.location.pathname);
  }, []);
  */
  
  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error?.status === 401) {
        adminLogout();
      }
    }

    if (deleteError) {
      toast.error(deleteError?.data?.message);
    }

    if (deletedUserSuccess) {
      toast.success(`User ${deletedUserData.userName} deleted`);
    }
  }, [error, deleteError, deletedUserSuccess]);

  const confirmDeletingUser = () => {
    if (showingDeleteConfirmUserIndex >= 0) {
      adminDeleteUser(data[showingDeleteConfirmUserIndex]._id);
      showDeleteUserConfirmDialog(-1);
    }
  }

  const setUsers = () => {
    const users = {
      columns: [
        {
          label: <CenterAlignedCell value={"ID"}> </CenterAlignedCell>,
          field: "id",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Name"}> </CenterAlignedCell>,
          field: "name",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Role"}> </CenterAlignedCell>,
          field: "role",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Status"}> </CenterAlignedCell>,
          field: "status",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };

    const canDeleteUser = CanDeleteUser(user);
    const canUpdateUser = CanUpdateUser(user);

    data?.forEach((user, index) => {
      users.rows.push({
        id: index + 1,
        name: user?.name,
        role: String(user?.role).toUpperCase(),
        status: String(user?.status).toUpperCase(),
        actions: (
          <>
            {canUpdateUser && (
            <Link
              to={appendAppVersionToUrl(`/admin/users/${user?._id}`)}
              className="btn btn-outline-primary"
            >
              <i className="fa fa-pencil"></i>
            </Link>
            )}
            {canDeleteUser && (
            <button
              className="btn btn-outline-danger ms-2"
              onClick={() => showDeleteUserConfirmDialog(index)}
              disabled={isDeleteLoading}
            >
              <i className="fa fa-trash"></i>
            </button>)}
          </>
        ),
      });
    });

    return users;
  };

  // Create new user
  const createNewUserHandler = () => {
    // Redirect to create new user page
    navigate(appendAppVersionToUrl("/admin/users/new"));
  }
  if (isLoading || loadingAdmin) return <Loader />;

  const canCreateUser = CanCreateUser(user);

  return (
    <>
      <MetaData title={"All Users"} />
      <HeaderAdmin title={"All Users"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.USERS.name}>
      <div className="row my-4 mx-2">
        <div className="col-4 col-lg-4">
          <h1>{data?.length} Users</h1>
        </div>
        <div className="col-3 col-lg-3 offset-5 text-end">
          {/*New Order Button - rigjht */}
          {canCreateUser && (
          <button 
            onClick={() => {createNewUserHandler();}} 
            className="btn btn-primary"
            style={{width: '100%'}}>
            Create New User
          </button>)}             
        </div>
      </div>  
      <MDBDataTable 
        data={setUsers()} 
        className="px-3" 
        bordered striped hover 
        noBottomColumns
      />
      <ConfirmDialog message={`Are you sure to delete this user, ${showingDeleteConfirmUserIndex >=0 ? data[showingDeleteConfirmUserIndex].name : ""} ?`} show={showingDeleteConfirmUserIndex >= 0} confirm={()=>{confirmDeletingUser()}} cancel={()=> {showDeleteUserConfirmDialog(-1)}}> </ConfirmDialog>
      </AdminLayout>
    </>
  );
};

export default ListUsers;
