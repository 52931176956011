import React,  { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { useNavigate } from "react-router-dom";

import MetaData from "../../layout/MetaData.jsx";
import AdminLayout from "../../layout/AdminLayout.jsx";
import HeaderAdmin from "../../layout/HeaderAdmin.jsx";
import Loader from "../../layout/Loader.jsx";
import { EditableNumberCell, ButtonCell, RightAlignedCell, CenterAlignedCell } from "../../layout/CustomMDBDataTableCell.jsx";
import { ADMIN_MENU_ITEMS, CUSTOMER_STATUS, PRICE_THRESHOLD_STATUS, USER_ROLES } from "../../../constants/constants.js";
import { useAdminCreateNewCreditMemoMutation } from "../../../redux/api/orderAdminApi.js";
import { useAdminGetInventoryQuery } from "../../../redux/api/inventoriesAdminApi.js";
import { valueIsANonNegativeNumber } from "../../../utils/validators.js";
import {  appendAppVersionToUrl, CalculateOrderSummary, calculateSellPriceThresholds, getPSTStartOfDay, getSellPrice, isInventoryTrackingForTomorrow, SellPriceDoubleCheck } from "../../../utils/utilities.js";
import { ConfirmDialog, ConfirmDialogUpdateDeliveredOrder } from "../ConfirmDialog.jsx";
import { ProductSelection } from "../../product/ProductSelection.jsx";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi.js";
import { CustomerSelection } from "../../customer/CustomerSelection.jsx";
import { useAdminGetAllCustomersQuery } from '../../../redux/api/adminApi.js';
import { forEach } from "lodash";
import DatePicker from "react-datepicker";

const CreateSellCreditMemo = () => {
  const navigate = useNavigate();
  
  // Redux
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { inventory } = useSelector((state) => state.inventory);
  const { inventoryDataOrdersByProduct } = useSelector((state) => state.inProgressOrders);
  const { inventoryDataPurchasesByProduct } = useSelector((state) => state.inProgressPurchases);

  // state
  const [formUpdated, setFormUpdated] = useState(false);
  const [showingCancelNewOrderConfirm, showCancelNewOrderConfirmDialog] = useState(false);
  const [showingCreateNewCreditMemoConfirm, showNewCreditMemoConfirm] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customerTier, setCustomerTier] = useState(0);
  const [customerAddress, setCustomerAddress] = useState("");
  const [order, setOrder] = useState({orderDate: getPSTStartOfDay(), itemList: [{}]});
  const [notes, setNotes] = useState("");
  let pallet = 0;

  // Hooks
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [adminCreateNewCreditMemo, {data: createdOrder, isLoading: isCreatingNewOrder, error: orderCreateError, isSuccess: orderCreateSuccess }] = useAdminCreateNewCreditMemoMutation();
  const { data: customersData, isLoading: isLoadingCustomer } = useAdminGetAllCustomersQuery({params: {status: CUSTOMER_STATUS.ACTIVE}, refetchOnMountOrArgChange: true});
  
  // Query for data
  const {isLoading: isLoadingInventory } = useAdminGetInventoryQuery();

  const filterValidRows = (items) => {
    const validRows = [];
    for (const item of items) {
      if (item.product) {
        validRows.push(item);
      }
    }
    return validRows;
  };

  useEffect(() => {
    const today = getPSTStartOfDay();
    const isTrackingForTomorrow = isInventoryTrackingForTomorrow();
    const initialDate = new Date(today.getTime() + (isTrackingForTomorrow ? 24 * 60 * 60 * 1000 : 0));

    //console.log('initialDate', isTrackingForTomorrow, today, initialDate);
    setOrder({orderDate: initialDate.toISOString(), itemList: [{}]});
  }, []);

  useEffect(() => {
    if (customersData) {
      setCustomers(customersData);
    }
  }, [customersData]);

  useEffect(() => {
    if (orderCreateError) {
      toast.error(orderCreateError?.data?.message);
      if (orderCreateError.status === 401) {
        adminLogout();
      }
    }
    else if (orderCreateSuccess && createdOrder) {
      //toast.success("Order Created");
      // Update local data to reflect the new order
      const orderId = createdOrder.order._id;

      navigate(appendAppVersionToUrl(`/admin/orders/${orderId}`));
    }
  }, [orderCreateError, orderCreateSuccess]);

  useEffect(() => {
    // If last row is not empty, add a new row
    if (order.itemList && order.itemList.length > 0 && order.itemList[order.itemList.length - 1].name) {
      addItemRowHandler();
    }
  },[formUpdated]);

  // --------------------------------- Render ----------------------------------
  // If the order, inventory, user are not loaded yet, show the loader
  if (isLoadingCustomer || isLoadingInventory  || loadingAdmin || user === null || inventory === null) {
    return <Loader />;
  }
  //--------------------------------- Functions ---------------------------------
  // Remove item
  const removeItem = (index) => {
    if (index >= 0) {
      order.itemList.splice(index, 1);
      setOrder(order);
      setFormUpdated(!formUpdated);
    }
  }

  // Cancel
  const handleCancelNewOrder = () => {
    showCancelNewOrderConfirmDialog(true);
  };

  // Confirm cancel new order
  const confirmCancelNewOrder = () => {
    showCancelNewOrderConfirmDialog(false);
    navigate(appendAppVersionToUrl("/admin"));
  }

  // Creating order
  const createNewOrderHandler = () => {
    if (order.customer === undefined) {
      toast.error("Please select a customer.");
      return;
    }

    showNewCreditMemoConfirm(true);
  }

  const doCreateNewCreditMemo = (updateStock) => {
    // Clean up the cloned order items
    const filteredList = filterValidRows(order.itemList);
    let thereIsError = false;
    filteredList.forEach((item, index) => {
      if (item.finalQuantity <= 0) {
        toast.error(`${item.name}'s quantity must be greater than 0.`);
        thereIsError = true;
      }
      if (item.finalPrice === undefined || item.finalPrice < 0) {
        toast.error(`${item.name}'s price is not entered.`);
        thereIsError = true;
      }

      /*
      for (let index = 0; index < filteredList.length; index++) {
        const item = filteredList[index];
        if (item.product) {
          const product = inventory[item.product]?.product;
          const { ok, message } = SellPriceDoubleCheck(user, product, item.finalPrice);
          if (!ok) {
            toast.error(message);
            thereIsError = true;
            return; 
          }
        }
      }
      */
    });

    if (thereIsError) {
      return;
    }

    // Check if the order is empty
    if (filteredList.length === 0) {
      toast.error("Please add items to the order.");
      return;
    }

    //console.log(filteredList)

    adminCreateNewCreditMemo({ updateStock, customerId: order.customer, itemList: filteredList, notes, orderDate: order.orderDate, extraName: order.extraName, pallet, noQB: order.noQB });
  };

  // Handle price
  const handlePriceChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && order.itemList[index].finalPrice !== value) {
      /*
      const product = order.itemList[index].product ?? inventory[order.itemList[index].product]?.product;
      const { ok, message } = SellPriceDoubleCheck(user, product, value);
      if (!ok) {
        toast.error(message);
      } else 
      */
      {
        order.itemList[index].finalPrice = Number(value);
      }
      setFormUpdated(!formUpdated);
    }
  };

  // Handle note
  const handleNoteChange = (index, value) => {
    order.itemList[index].note = value;
    setFormUpdated(!formUpdated);
  }

  // Handle quantity
  const handleQuantityChange = (index, value) => {
    if (valueIsANonNegativeNumber(value) && order.itemList[index].finalQuantity !== value) {
      order.itemList[index].finalQuantity = Number(value);
      setFormUpdated(!formUpdated);
    }
  };

  const handleExtraNameChange = (extraName) => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      extraName: extraName,
    }));
  };

  const handleCheckBoxChange = (e) => {
    setOrder({ ...order, noQB: !e.target.checked });
    setFormUpdated(!formUpdated);
  }

  // Set product
  const setProduct = (index, product) => {
    if (order.customer === undefined) {
      toast.error("Please select a customer.");
      return;
    }

    const { price } = getSellPrice(product, customerTier);
    Object.assign(order.itemList[index], {
      product: product._id,
      name: product.name,
      initQuantity: 1,
      finalQuantity: 1,
      initPrice: price,
      finalPrice: price,
    });
    setFormUpdated(!formUpdated);
  }

  // Handle customer select
  const handleCustomerSelect = (customer) => {
    if (order.customer !== customer._id) {
      order.customer = customer._id; 
      setCustomerTier(customer.tier);
      setCustomerAddress(customer.addressInfo.address);
      forEach(order.itemList, (item, index) => {
        const productId = order.itemList[index].product;
        if (productId) {
          const product = productId && inventory ? inventory[productId].product : undefined;
          const { price } = getSellPrice(product, customerTier);
          order.itemList[index].finalPrice = order.itemList[index].initPrice = price;
        }
      });
      setFormUpdated(!formUpdated);
      if (customer.name === "Walk in Customer") {
        order.noQB = true;
      }
    }
  }

  // Add item row
  const addItemRowHandler = () => {
    order.itemList.push({});
    setFormUpdated(!formUpdated);
  };

  const handleDateChange = (date) => {
    const selectedDate = getPSTStartOfDay(date);
    order.orderDate = selectedDate.toISOString();
    setFormUpdated(!formUpdated);
  };

  const {units, estPallet, itemTotal, tax, total} = CalculateOrderSummary(order.itemList, inventory);
  pallet = estPallet;
  const standardColumnWidth = '8vw';
  const setOrders = () => {
    const items = {
      columns: [
        {
          label: <CenterAlignedCell value={"SKU"}> </CenterAlignedCell>,
          field: "sku",
          sort: "asc",
          width: '20vw',
        },
        {
          label: <CenterAlignedCell value={"Product"}> </CenterAlignedCell>,
          field: "name",
          sort: "asc",
          width: '20vw',
        },
        {
          label: <CenterAlignedCell value={"Note"}> </CenterAlignedCell>,
          field: "note",
          sort: "asc",
          width: '20vw',
        },
        {
          label: <CenterAlignedCell value={"Quantity"}> </CenterAlignedCell>,
          field: "quantity",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: <CenterAlignedCell value={"Price"}> </CenterAlignedCell>,
          field: "price",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: <CenterAlignedCell value={"Item Total"}> </CenterAlignedCell>,
          field: "itemTotal",
          sort: "asc",
          width: standardColumnWidth,
        },
        /*
        {
          label: <CenterAlignedCell value={"Stock"}> </CenterAlignedCell>,
          field: "stock",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: <CenterAlignedCell value={"Inbound"}> </CenterAlignedCell>,
          field: "incommingQuantity",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: <CenterAlignedCell value={"Outbound"}> </CenterAlignedCell>,
          field: "outcommingQuantity",
          sort: "asc",
          width: standardColumnWidth,
        },
        */
        {
          label: <CenterAlignedCell value={"Balance"}> </CenterAlignedCell>,
          field: "balance",
          sort: "asc",
          width: standardColumnWidth,
        },
        {
          label: <CenterAlignedCell value={"Action"}> </CenterAlignedCell>,
          field: "actions",
          sort: "asc",
          width: standardColumnWidth,
        },
      ],
      rows: [],
    };  

    // Clone the inventory object - allow duplicate items in the order - no need to filter out items that are already in the order
    /*
    let filteredInventory = { ...inventory };
    // Remove entries that are already in order.itemList
    // Only allow adding new items 
    order.itemList.forEach(item => {
        if (item.product in filteredInventory) {
            delete filteredInventory[item.product];
        }
    });
    */

    order.itemList?.forEach((item, index) => {
      const productId = item.product;
      const product = productId && inventory ? inventory[productId] : undefined;
      const stock = product?.quantity;
      const quantity = item.finalQuantity;
      const incommingQuantity = (inventoryDataPurchasesByProduct && inventoryDataPurchasesByProduct[productId]?.inProgressTotal? inventoryDataPurchasesByProduct[productId].inProgressTotal : 0);
      const outcommingQuantity = (inventoryDataOrdersByProduct && inventoryDataOrdersByProduct[productId]?.inProgressTotal? inventoryDataOrdersByProduct[productId].inProgressTotal : 0);
      
      let totalOutQty;
      let balance;
      // Existing item
      if (quantity !== undefined && outcommingQuantity !== undefined) {
        totalOutQty = outcommingQuantity - quantity;
      }
      if (totalOutQty !== undefined && stock !== undefined) {
        balance = stock + incommingQuantity - totalOutQty;
      }
      const price = item.finalPrice ? item.finalPrice.toFixed(2) : '';
      //const priceString = price?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      const itemTotal = price ? Number(price) * Number(quantity) : undefined;
      const itemTotalString = isNaN(itemTotal) ? "" : "$" + itemTotal.toFixed(2);
      let priceBackgroundColor;

      // Highlight price cell for Admin by checking the sell price threshold
      // product is an inventory item, product.product is the product object
      if (product && product.product && price &&  price !== '' && price !== undefined && (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SUPER_ADMIN)) {
        const sellPriceThreshold = calculateSellPriceThresholds(product.product.cost, price);
        priceBackgroundColor = sellPriceThreshold === PRICE_THRESHOLD_STATUS.BELOW_COST ? 'red' 
                                : (sellPriceThreshold === PRICE_THRESHOLD_STATUS.BELOW_TARGET ? 'lightyellow' 
                                  : (sellPriceThreshold === PRICE_THRESHOLD_STATUS.ABOVE_TARGET ? 'lightgreen' : 'transparent'));
      }
      
      items.rows.push({
        className: 'myMDBDataProcessOrderTableRow',
        sku: product ? <CenterAlignedCell value={product.product?.sku} > </CenterAlignedCell> : '',
        name: ProductSelection({ productList: inventory, defaultItem: {...item, sku: product?.product.sku }, onChange: (product) => {
                setProduct(index, product);
              }}),
        note: product ? <EditableNumberCell type={'text'} value={item.note} onChange={(val) => {handleNoteChange(index, val); }} > </EditableNumberCell> : '',
        price: product ? <EditableNumberCell  backgroundColor={priceBackgroundColor} value={price} onBlur={(val) => {handlePriceChange(index, val)}} > </EditableNumberCell> : '',
        quantity: product ? <EditableNumberCell value={quantity} onBlur={(val) => {handleQuantityChange(index, val)}}> </EditableNumberCell> : '',
        itemTotal: product ? <RightAlignedCell value={itemTotalString} > </RightAlignedCell> : '',
        //stock: product ? <RightAlignedCell value={stock} > </RightAlignedCell> : '',
        //incommingQuantity: product ? <RightAlignedCell value={incommingQuantity} > </RightAlignedCell> : '',
        //outcommingQuantity: product ? <RightAlignedCell value={totalOutQty} > </RightAlignedCell> : '',
        balance: product ? <span style={{ color: balance < 0 ? "red" : "black"}}><RightAlignedCell value={balance !== undefined ? balance : '_'} ></RightAlignedCell> </span> : '',
        actions: <center>
                  { index < order.itemList.length - 1 && (<ButtonCell buttonType={"btn btn-outline-danger ms-2"} labelType={"fas fa-trash"} action={(e) => {removeItem(index);}} > </ButtonCell>)}            
                </center>,
      });
    });

    return items;
  };

  return (
    <>
      <MetaData title={"Create Customer Credit Memo"} />
      <HeaderAdmin title={"Create Customer Credit Memo"} bg_color={"lightgreen"}/>
      <AdminLayout  menuItem={ADMIN_MENU_ITEMS.ORDERS.name}>
        <div id = "order_table" className="col-11 col-lg-11 my-0 offset-0">
          <div className="row my-0 ">
            <div className="col-2 width-100">
              <DatePicker
                selected={order.orderDate}
                onChange={(date) => { handleDateChange(date); } }
                dateFormat="MM/dd/yyyy"
                className="form-control"
                minDate={getPSTStartOfDay} 
              />
            </div>
            <div className="col-1 width-100"></div>
            <div className="col-4 my-0 text-center">
              <h1 style={{ color: 'orange' }}>New Credit Memo</h1>
            </div>
            <div className="col-3 width-100">
              {(user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ACCOUNTANT) && (
                <div>
                  <h3 className="mb-4">{`QB `} 
                    <input 
                      type="checkbox" 
                      name="isActive" 
                      checked={!order.noQB} 
                      onChange={handleCheckBoxChange}
                      style={{ 
                        transform: 'scale(2.0)', 
                        marginRight: '20px',
                        marginLeft: '20px',
                      }} 
                    /> 
                  </h3>
                </div>
              )}
            </div>
            <div className="col-2 my-0">
              {/* Cancel Order Button - right */}
              <ButtonCell buttonType={"btn btn-outline-danger ms-"} width={'100%'} disabled={showingCancelNewOrderConfirm} label={'Cancel'} action={(e) => {handleCancelNewOrder();}} > </ButtonCell>
            </div>
          </div>
          <div className="row my-0 ">
            <div className="col-9 my-0">
              <h5 className="my-1 text-left col-12"> Customer: {CustomerSelection({ customers , onChange: (customer)=> {handleCustomerSelect(customer)}})}</h5>
              <h5> {customerAddress} </h5>
            </div>
          </div>
          <h5 className="my-4 text-left col-4"> Extra Info: </h5>
          <div className="my-1 text-left col-4">
            <input value={order.extraName} onChange={(e) => handleExtraNameChange(e.target.value)} className="form-control" />
          </div>

          {/* Order Items */}
          <MDBDataTable
            data={setOrders()}
            className="px-10 myMDBDataProcessOrderTableRow"
            bordered
            small
            hover
            noBottomColumns
            noRecordsFoundLabel={"No items added"}
            displayEntries={false}
            searchLabel={"Filter"}
            paging={false}
            striped
          />
          <div className="row my-0">
            <div className="col-6 col-lg-6 my-4 offset-0">
              <label htmlFor="notes_field">Notes</label>
              <textarea
                className="form-control"
                value={notes}
                style={{ height: '200px' }}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
            <div className="col-4 col-lg-4 my-0 offset-2">
              {/*
              <div className="my-4">
                <button 
                    disabled={isCreatingNewOrder}
                    onClick={() => {addItemRowHandler();}} 
                    className="btn btn-primary"
                    style={{width: '100%'}}>
                    Add More Items
                </button>
              </div>
              */}
              {/* Confirm Create Order */}
              <div className="my-4">
                <button 
                    disabled={isCreatingNewOrder || order.itemList.length === 0}
                    onClick={() => {createNewOrderHandler();}} 
                    className="btn btn-success"
                    style={{width: '100%', backgroundColor: 'orange'}}>
                    Create Credit Memo
                </button>
              </div>

              {/* Order Summary */} 
              <div>
                <div id="order_summary">
                  <h4>Order Summary</h4>
                  <hr />
                  <p>
                    Units:{" "}
                    <span className="order-summary-values">
                      {units} (Units)
                    </span>
                  </p>
                  <p>
                    Est. size:{" "}
                    <span className="order-summary-values">
                      {estPallet?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (Pallet)
                    </span>
                  </p>
                  <hr />
                  <p>
                    Item Total:{" "}
                    <span className="order-summary-values">
                      $
                      {itemTotal?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>      
                  <p>
                    Tax:{" "}
                    <span className="order-summary-values">
                      $
                      {tax?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>      
                  <hr />
                  <p>
                    <strong>Total:{" "}</strong>
                    <span className="order-summary-values">
                      $
                      {total?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </span>
                  </p>            
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog message={`Are you sure to cancel?`} show={showingCancelNewOrderConfirm} confirm={()=>{confirmCancelNewOrder()}} cancel={()=> {showCancelNewOrderConfirmDialog(false)}}> </ConfirmDialog>
        <ConfirmDialogUpdateDeliveredOrder 
          message={'Creating new Credit Memo.'} 
          show={showingCreateNewCreditMemoConfirm}  
          cancel={()=> {showNewCreditMemoConfirm(false)}} 
          updateStock={()=>{doCreateNewCreditMemo(true); showNewCreditMemoConfirm(false);}} 
          dontUpdateStock={()=>{doCreateNewCreditMemo(false); showNewCreditMemoConfirm(false);}} 
        />
      </AdminLayout>
    </>
  );
};

export default CreateSellCreditMemo;
