import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';   // Import createApi and fetchBaseQuery from the toolkit/query/react module
import { setInventory, setLoadingInventory, updateInventory } from "../features/inventorySlice.js";
import { getBackendURI, getQueryCorsMode } from './helper.js';

const convertInventoriesToHash = (data) => {
    const inventory = data.inventories.reduce((acc, item) => {
        if (item.product) {
            acc[item.product._id] = item;
        }
        return acc;
    }, {});
    return inventory;
}

export const inventoriesAdminApi = createApi({   // Create a new API using the createApi function
    reducerPath: 'inventoriesAdminApi',   // Define a unique reducer path for the API
    baseQuery: fetchBaseQuery(
        { 
            baseUrl: getBackendURI('/api/v1/admin'),
            mode: getQueryCorsMode(),
            credentials: 'include', // Include cookies in the request
         }),   // Define the base query using the fetchBaseQuery function
    tagTypes: ["Inventory"],   // Define the tag types for the API
    endpoints: (builder) => ({   // Define the endpoints for the API
        // Get all inventory
        adminGetInventory: builder.query({
            query: () => `/inventories/`,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    // Convert to hash map
                    const inventory = convertInventoriesToHash(data);
                    const timestamp = new Date(data.updatedAt).getTime();
                    dispatch(setInventory(inventory, timestamp));
                    dispatch(setLoadingInventory(false));
                } catch (error) {
                    dispatch(setLoadingInventory(false));
                    console.log(error);
                }
            },
            providesTags: ["Inventory"],
        }),
        // Get an inventory details
        adminGetInventoryDetails: builder.query({ 
            query: ({ inventoryId, timestamp }) => `/inventories/${inventoryId}?t=${timestamp}`, 
            providesTags: (result, error, id) => [{ type: 'Inventory', id }],
        }),
        adminCreateInventory: builder.mutation({
            query(body) {
              return {
                url: "/inventories/new",
                method: "POST",
                body,
              };
            },
            invalidatesTags: ["Inventory"],
        }),
        adminUpdateInventory: builder.mutation({
            query({ id, body }) {
                return {
                    url: `/inventories/${id}`,
                    method: "PUT",
                    body,
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                // Update locally first
                //console.log("onQueryStarted", args);
                const id = args.id;
                const body = args.body;
                dispatch(updateInventory({ id, body }));
              },
            invalidatesTags: ["Inventory"],
        }),
        adminDeleteInventory: builder.mutation({
            query(id) {
                return {
                url: `/inventories/${id}`,
                method: "DELETE",
                };
            },
            invalidatesTags: ["Inventory"],
        }),
        // Get an inventory change log
        adminGetInventoryChangeLog: builder.query({ 
            query: (id) => `/inventoryhistories/${id}`, 
        }),
        
    }),
});

export const { 
    useAdminGetInventoryQuery,
    useLazyAdminGetInventoryDetailsQuery,
    useAdminGetInventoryChangeLogQuery,
    useAdminCreateInventoryMutation,
    useAdminUpdateInventoryMutation,
    useAdminDeleteInventoryMutation,
} = inventoriesAdminApi;