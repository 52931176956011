import React, { useEffect, useState } from "react";
//import { countries } from "countries-list";
import { useDispatch, useSelector } from "react-redux";
import { setAddressInfo } from "../../redux/features/cartSlice";
import { useNavigate } from "react-router-dom";
import MetaData from "../layout/MetaData";
import CheckoutSteps from "./CheckoutSteps";
import Header from "../layout/Header";
import { US_STATES } from  "../../constants/constants.js";
import { set } from "lodash";
import { appendAppVersionToUrl } from "../../utils/utilities.js";

const Shipping = () => {
  //const countriesList = Object.values(countries);

  const { customer } = useSelector((state) => state.customerAuth);
  const addressInfo = customer?.addressInfo;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [address, setAddress] = useState(addressInfo.address || "");
  const [billingAddress, setBillingAddress] = useState(addressInfo.billingAddress || "");
  const [phone, setPhone] = useState(addressInfo.phone || "");
  const [copyAddress, setCopyAddress] = useState(false);

  //const [country, setCountry] = useState("United States");
  
  const submiHandler = (e) => {
    e.preventDefault();

    dispatch(setAddressInfo({ address, billingAddress, phone }));
    navigate(appendAppVersionToUrl("/confirm_order"));
  };

  const handleCopyAddressChange = (e) => {
    setCopyAddress(e.target.checked);
    if (e.target.checked) {
      setBillingAddress(address);
    }
  };

  return (
    <>
      <MetaData title={"Shipping Info"} />
      <Header  title={"Shipping Info"} />
      <div className="container">
        <CheckoutSteps shipping />

        <div className="row wrapper mb-5">
          <div className="col-10 col-lg-5">
            <form className="shadow rounded bg-body" onSubmit={submiHandler}>
              <div className="mb-3">
                <label htmlFor="address_field" className="form-label">
                  Shipping Address
                </label>
                <input
                  type="text"
                  id="address_field"
                  className="form-control"
                  name="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
              {/* Billing Address */}
              <div className="d-flex justify-content-between align-items-center">
                  <label htmlFor="address_field" className="form-label">
                    Billing Address
                  </label>
                  <div className="d-flex align-items-center">
                    <label className="form-check-label" htmlFor="copy_address_checkbox">
                        Copy Shipping Address to Billing Address
                    </label>
                    &nbsp;
                    <input
                        type="checkbox"
                        className="form-check-input me-2"
                        id="copy_address_checkbox"
                        checked={copyAddress}
                        onChange={handleCopyAddressChange}
                    />
                </div>
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  id="billing_address_field"
                  className="form-control"
                  name="billingAddress"
                  value={billingAddress}
                  onChange={(e) => setBillingAddress(e.target.value)}
                  disabled={copyAddress} 
                />
              </div>

              <div className="mb-3">
                <label htmlFor="phone_field" className="form-label">
                  Phone No
                </label>
                <input
                  type="tel"
                  id="phone_field"
                  className="form-control"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <button id="shipping_btn" type="submit" className="btn w-100 py-2">
                CONTINUE
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shipping;
