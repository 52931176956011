import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

import { useAdminUpdateProfileMutation } from "../../../redux/api/adminApi";
import AdminProfileLayout from "../../layout/AdminProfileLayout";
import MetaData from "../../layout/MetaData";
import { validateAdminProfileForm } from "../../../utils/validators";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { appendAppVersionToUrl } from "../../../utils/utilities";

const UpdateAdminProfile = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const { user } = useSelector((state) => state.adminAuth);
  const [adminUpdateProfile, { isLoading, error, isSuccess }] = useAdminUpdateProfileMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();

  useEffect(() => {
    if (user) {
      setName(user?.name);
      setPhone(user?.phone);
      setEmail(user?.email);
    }

    if (isSuccess) {
      toast.success("Profile Updated");
      navigate(appendAppVersionToUrl("/admin/profile"));
    }
  }, [user, isSuccess]);

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error.status === 401) {
        adminLogout();
      }
    }
  }, [error]);

  const submitHandler = (e) => {
    e.preventDefault();

    const user = {  
      name, 
      phone, 
      email 
    };
    const { formIsValid, formErrors } = validateAdminProfileForm(user);

    if (formIsValid) {
      const signUpData = {
        name,
        phone,
        email,
      };
      adminUpdateProfile(signUpData);
    } else {
      Object.values(formErrors).forEach((error) => {
        toast.error(error);
      });
    }
  };

  return (
    <>
      <MetaData title={"Update Profile"} />
      <HeaderAdmin />
      <div className="container">
        <AdminProfileLayout title="Update Profile">
          <div className="row wrapper">
            <div className="col-10 col-lg-8">
              <form className="shadow rounded bg-body" onSubmit={submitHandler}>
                <h2 className="mb-4">Update Profile</h2>
                {/* Name */}
                <div className="mb-3">
                  <label htmlFor="name_field" className="form-label">
                    {" "}
                    Name{" "}
                  </label>
                  <input
                    type="text"
                    id="name_field"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                {/* Phone */}
                <div className="mb-3">
                  <label htmlFor="phone_field" className="form-label">
                    {" "}
                    Phone{" "}
                  </label>
                  <input
                    type="text"
                    id="phone_field"
                    className="form-control"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>

                {/* email */}
                <div className="mb-3">
                  <label htmlFor="email_field" className="form-label">
                    {" "}
                    Email{" "}
                  </label>
                  <input
                    type="email"
                    id="email_field"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <button
                  type="submit"
                  className="btn update-btn w-100"
                  disabled={isLoading}
                >
                  {isLoading ? "Updating..." : "Update"}
                </button>
              </form>
            </div>
          </div>
        </AdminProfileLayout>
      </div>
    </>
  );
};

export default UpdateAdminProfile;
