import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCustomerUpdateProfileMutation } from "../../redux/api/customerApi";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import UserLayout from "../layout/UserLayout";
import MetaData from "../layout/MetaData";
import { US_STATES } from  "../../constants/constants.js";
import { validateCustomerProfileForm } from "../../utils/validators";
import Header from "../layout/Header";
import { useLazyCustomerLogoutQuery } from "../../redux/api/customerAuthApi";
import { appendAppVersionToUrl } from "../../utils/utilities.js";

const UpdateCustomerProfile = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  //const [city, setCity] = useState("");
  //const [state, setState] = useState("");
  //const [zipCode, setZipCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const { customer } = useSelector((state) => state.customerAuth);
  const [customerUpdateProfile, { isLoading, error, isSuccess }] = useCustomerUpdateProfileMutation();
  const [customerLogout] = useLazyCustomerLogoutQuery();

  useEffect(() => {
    if (customer) {
      setName(customer?.name);
      setAddress(customer?.addressInfo?.address);
      //setCity(customer?.addressInfo?.city);
      //setState(customer?.addressInfo?.state);
      //setZipCode(customer?.addressInfo?.zipCode);
      setPhone(customer?.addressInfo?.phone);
      setEmail(customer?.email);
    }

    if (isSuccess) {
      toast.success("User Updated");
      navigate(appendAppVersionToUrl("/me/profile"));
    }
  }, [customer, isSuccess]);

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error.status === 401) {
        customerLogout();
      }
    }
  }, [error]);

  const submitHandler = (e) => {
    e.preventDefault();

    const user = {  
      name, 
      addressInfo: {
        address,
        //city,
        //state,
        phone,
        //zipCode,
      }, 
      email 
    };
    const { formIsValid, formErrors } = validateCustomerProfileForm(user);

    if (formIsValid) {
      const signUpData = {
        name,
        addressInfo: {
          address,
          //city,
          //state,
          phone,
          //zipCode,
        },
        email,
      };
      customerUpdateProfile(signUpData);
    } else {
      Object.values(formErrors).forEach((error) => {
        toast.error(error);
      });
    }
  };

  return (
    <>
      <MetaData title={"Update Profile"} />
      <Header />
      <div className="container">
        <UserLayout title="Update Profile">
          <div className="row wrapper">
            <div className="col-10 col-lg-8">
              <form className="shadow rounded bg-body" onSubmit={submitHandler}>
                <h2 className="mb-4">Update Profile</h2>
                {/* Name */}
                <div className="mb-3">
                  <label htmlFor="name_field" className="form-label">
                    {" "}
                    Name{" "}
                  </label>
                  <input
                    type="text"
                    id="name_field"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                {/* Address */}
                <div className="mb-3">
                  <label htmlFor="address_field" className="form-label">
                    {" "}
                    Address{" "}
                  </label>
                  <input
                    type="text"
                    id="address_field"
                    className="form-control"
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>

                {/* Phone */}
                <div className="mb-3">
                  <label htmlFor="phone_field" className="form-label">
                    {" "}
                    Phone{" "}
                  </label>
                  <input
                    type="text"
                    id="phone_field"
                    className="form-control"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>

                {/* email */}
                <div className="mb-3">
                  <label htmlFor="email_field" className="form-label">
                    {" "}
                    Email{" "}
                  </label>
                  <input
                    type="email"
                    id="email_field"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <button
                  type="submit"
                  className="btn update-btn w-100"
                  disabled={isLoading}
                >
                  {isLoading ? "Updating..." : "Update"}
                </button>
              </form>
            </div>
          </div>
        </UserLayout>
      </div>
    </>
  );
};

export default UpdateCustomerProfile;
