import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { Link, useNavigate } from "react-router-dom";
import MetaData from "../../layout/MetaData";
import { useSelector } from "react-redux";

import AdminLayout from "../../layout/AdminLayout";

import {
  useAdminDeleteVendorMutation,
  useAdminGetAllVendorsQuery,
  useAdminForceResyncOneVendorQuickbooksMutation,
  useAdminForceResyncMultipleVendorQuickbooksMutation,
} from "../../../redux/api/adminApi";
import { ADMIN_MENU_ITEMS, QUICKBOOKS_CATEGORY, USER_ROLES } from "../../../constants/constants.js";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { ConfirmDialog } from "../ConfirmDialog";
import { CanDeleteVendor, CanUserCreateVendor, CanUserUpdateVendor } from "../AdminActionEligibilities";
import { CenterAlignedCell } from "../../layout/CustomMDBDataTableCell";
import QuickbooksIcon from "../../layout/QuickbooksIcon.jsx";
import { appendAppVersionToUrl } from "../../../utils/utilities.js";

const ListVendors = () => {
  const navigate = useNavigate();
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const [adminLogout] = useLazyAdminLogoutQuery();
  const { data, isLoading, error } = useAdminGetAllVendorsQuery();
  const [vendorList, setVendorList] = useState([]);
  const [adminDeleteVendor, { data: deletedVendorData, error: deleteError, isLoading: isDeleteLoading, isSuccess: deletedVendorSuccess }] = useAdminDeleteVendorMutation();
  const [showingDeleteConfirmVendorIndex, showDeleteVendorConfirmDialog] = useState(-1);
  const [showingCannotDeleteConfirmVendorIndex, showCannotDeleteVendorConfirmDialog] = useState(-1);
  const [AdminForceResyncOneVendorQuickbooks, {data: forcedQBSyncedData, isLoading: isForcingQBResync}] = useAdminForceResyncOneVendorQuickbooksMutation();
  const [AdminForceResyncMultipleVendorQuickbooks, { data: forcedQBMultipleSyncedData, isLoading: isForcingMultipleVendorResync }] = useAdminForceResyncMultipleVendorQuickbooksMutation();

  const [selectedQBResyncOption, setSelectedQBResyncOption] = useState("All");

  /*
  useEffect(() => {
    localStorage.setItem('lastVisitedPage', window.location.pathname);
  }, []);
  */
  
  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error?.status === 401) {
        adminLogout();
      }
    }

    if (deleteError) {
      toast.error(deleteError?.data?.message);
    }

    if (deletedVendorSuccess) {
      toast.success(`Vendor ${deletedVendorData.vendorName} deleted`);
    }
  }, [error, deleteError, deletedVendorSuccess]);

  const confirmDeletingVendor = () => {
    if (showingDeleteConfirmVendorIndex >= 0) {
      adminDeleteVendor(vendorList[showingDeleteConfirmVendorIndex]._id);
      showDeleteVendorConfirmDialog(-1);
    }
  }

  useEffect(() => {
    if (forcedQBMultipleSyncedData) {
      // refresh the page
      window.location.reload();
    }
  }, [forcedQBMultipleSyncedData]);

  useEffect(() => {
    if (forcedQBSyncedData && forcedQBSyncedData.vendor) {
      // Find matched vendor
      const index = vendorList.findIndex((vendor) => vendor._id === forcedQBSyncedData.vendor._id);
      if (index !== -1) {
        // Create a new array with the updated vendor
        const updatedVendorList = [...vendorList];
        updatedVendorList[index] = forcedQBSyncedData.vendor;
        setVendorList(updatedVendorList);
      }
    }
  }, [forcedQBSyncedData]);

  useEffect(() => {
    if (data) {
      setVendorList(data);
    }
  }, [data]);

  const handleOneVendorQuickbooksResync = (id) => {
    AdminForceResyncOneVendorQuickbooks(id);
  };

  const QBResyncOptionChange = (e) => {
    setSelectedQBResyncOption(e.target.value);
  };

  const executeResyncMultiple = () => {
    AdminForceResyncMultipleVendorQuickbooks({ option: selectedQBResyncOption });
  };

  const setVendors = () => {
    const vendorsTable = {
      columns: [
        {
          label: <CenterAlignedCell value={"ID"}> </CenterAlignedCell>,
          field: "id",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Name"}> </CenterAlignedCell>,
          field: "name",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Updated Time"}> </CenterAlignedCell>,
          field: "updatedTime",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Status"}> </CenterAlignedCell>,
          field: "status",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };

    const canDelete = CanDeleteVendor(user);
    const canUpdate = CanUserUpdateVendor(user);
    const CanUserUpdatePriceList = CanUserUpdateVendor(user);

    vendorList?.forEach((vendor, index) => {
      const isAlreadyInUse = vendor.QBId || !vendor.notInUse ? true : false;  
      vendorsTable.rows.push({
        id: index + 1,
        name: vendor.name,
        //phone: <CenterAlignedCell value={vendor.phone}> </CenterAlignedCell>,
        updatedTime: <CenterAlignedCell value={new Date(vendor.updatedAt).toLocaleDateString()}>  </CenterAlignedCell>,
        status: String(vendor.status).toUpperCase(),
        actions: (
          <center>
             {(user?.role === USER_ROLES.SUPER_ADMIN || user?.role === USER_ROLES.ADMIN || user?.role === USER_ROLES.ACCOUNTANT) && (
              <QuickbooksIcon qbCategory={QUICKBOOKS_CATEGORY.VENDOR} qbRef={vendor._id} QBStatus={vendor?.QBStatus} function1={() => handleOneVendorQuickbooksResync(vendor?._id)}/>
            )}
            {canUpdate && (
            <div style={{ display: 'inline-block', marginRight: '8px' }}>
              <Link
                to={appendAppVersionToUrl(`/admin/vendors/${vendor._id}`)}
                className="btn btn-outline-primary"
              >
                <i className="fa fa-pencil"></i>
              </Link>
            </div>  
            )}
            {CanUserUpdatePriceList && (
            <div style={{ display: 'inline-block', marginRight: '8px' }}>
              <Link
                to={appendAppVersionToUrl(`/admin/vendors/${vendor._id}/pricelist`)}
                className="btn btn-outline-info"
              >
                <i className="fa fa-tag"></i>
              </Link>
            </div>  
            )}

            {canDelete && (
            <div style={{ display: 'inline-block', marginRight: '8px' }}>
              <button
                className="btn btn-outline-danger ms-2"
                onClick={() => isAlreadyInUse ? showCannotDeleteVendorConfirmDialog(index) : showDeleteVendorConfirmDialog(index)}
                disabled={isDeleteLoading}
              >
                <i className="fa fa-trash"></i>
              </button>
              </div> 
            )}
          </center>
        ),
      });
    });

    return vendorsTable;
  };

  // Create new vendor
  const createNewVendorHandler = () => {
    // Redirect to create new vendor page
    navigate(appendAppVersionToUrl("/admin/vendors/new"));
  }

  if (isLoading || loadingAdmin) return <Loader />;

  const canCreateVendor = CanUserCreateVendor(user);
  return (
    <>
      <MetaData title={"All Vendors"} />
      <HeaderAdmin title={"All Vendors"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.VENDORS.name}>
        <div id = "vendor_table" className="col-12 col-lg-11">
          <div className="row my-3 mx-2">
            <div className="col-3 row my-0">
              {(user?.role === USER_ROLES.SUPER_ADMIN && user?.name === "KingGeorge") && (
                <div className="col-7">
                  <select className="form-select"
                    aria-label="Quickbooks Resync"
                    onChange={ (e) => QBResyncOptionChange(e) }
                    selected={selectedQBResyncOption}
                    defaultValue="All"
                  >
                    <option key ={"all"} value="All">All</option>
                    <option key={"unsyncedonly"} value='NotSynced'>Un-Synced Only</option>
                  </select>
                </div>
              )}
              {(user?.role === USER_ROLES.SUPER_ADMIN && user?.name === "KingGeorge") && (
                <div className="col-5">
                  <button 
                      onClick={() => {executeResyncMultiple();}} 
                      className="btn btn-success"
                      style={{width: '100%'}}
                  >
                      QB Sync
                  </button>
                </div>
              )}  
            </div>
            <h1 className="col-6">{vendorList?.length} Vendors</h1>
            <div className="col-3 col-lg-3 offset-lg-5">
              {/*New Order Button - rigjht */}
              {canCreateVendor && (
              <button 
                onClick={() => {createNewVendorHandler();}} 
                className="btn btn-primary"
                style={{width: '100%'}}>
                Create New Vendor
              </button>)}             
            </div>
          </div>
          {/* Loader */}
          {(isForcingQBResync || isForcingMultipleVendorResync ) && (
            <div style={{position: 'relative', height: '100%', width: '100%'}}>
              <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                <Loader />
              </div>
            </div>
          )}
          <MDBDataTable 
            data={setVendors()} 
            displayEntries={true}
            entriesOptions={[50, 100, 500]} 
            className="myMDBDataVendorPriceListTableRow" 
            bordered striped hover 
            noBottomColumns
          />
        </div>
        <ConfirmDialog message={`Are you sure to delete this vendor, ${showingDeleteConfirmVendorIndex >=0 ? vendorList[showingDeleteConfirmVendorIndex].name : ""} ?`} show={showingDeleteConfirmVendorIndex >= 0} confirm={()=>{confirmDeletingVendor()}} cancel={()=> {showDeleteVendorConfirmDialog(-1)}}> </ConfirmDialog>
        <ConfirmDialog message={`You cannot delete this vendor because it is already in use, ${showingCannotDeleteConfirmVendorIndex >=0 ? vendorList[showingCannotDeleteConfirmVendorIndex].name : ""}.`} show={showingCannotDeleteConfirmVendorIndex >= 0} ok={()=> {showCannotDeleteVendorConfirmDialog(-1)}}> </ConfirmDialog>
      </AdminLayout>
    </>
  );
};

export default ListVendors;
