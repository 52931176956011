import React, { useEffect, useState } from "react";
import MetaData from "../layout/MetaData.jsx";
import { useSelector } from "react-redux";
import { useGetProductsQuery } from "../../redux/api/productsApi.js";
import { toast } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../layout/Loader.jsx";
import Filters from "../layout/Filters.jsx";
import CustomPagination from "../layout/CustomPagination.jsx";
import { PRODUCT_CATEGORIES } from "../../constants/constants.js";

import ProductItem from "../product/ProductItem.jsx";
import Header from "../layout/Header.jsx";
import { useLazyCustomerLogoutQuery } from "../../redux/api/customerAuthApi.js";
import { useCustomerGetMeQuery } from "../../redux/api/customerApi.js";
import Search from "../layout/Search.jsx";
import { appendAppVersionToUrl } from "../../utils/utilities.js";

const Shop = () => {
    const navigate = useNavigate();

    useCustomerGetMeQuery();
    const { customer, loading: loadingCustomer, isAuthenticated } = useSelector((state) => state.customerAuth);
    // Get params
    let [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const keyword = searchParams.get("keyword") || "";
    const category = searchParams.get("category");
    const params = { page, keyword };
    category !== null && (params.category = category);

    const { data, isLoading, error, isError } = useGetProductsQuery(params);
    const [customerLogout] = useLazyCustomerLogoutQuery();
    const [loadingAssets, setLoadingAssets] = useState(false);

    const showLoaderAfterPageChange = () => {
        setLoadingAssets(true);
        // Simulate an async operation like fetching data
        setTimeout(() => {
            setLoadingAssets(false);
        }, 1000); // Adjust the timeout as needed
    };

    useEffect(() => {
        if (!isAuthenticated && navigate) {
            navigate(appendAppVersionToUrl("/login"));
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        if (error) {
            //toast.error(`error.data?.message - ${error.status}`);
            if (error.status === 401 || error.status === 423) {
                customerLogout();
            }    
        }
    }, [isError]);


    if (loadingCustomer || isLoading || isError || !data?.products || !customer) {
        return <Loader />;
    }

    const matchedCategory = PRODUCT_CATEGORIES.find((cat) => cat.id === category);
    const categoryName = matchedCategory?.name || "All Products";

    return (
        <>
            <MetaData title={"Shop Produce Online"} />
            <Header title={"Shop Produce Online"} />
            <div style={{width: '100%'}}>
                <div className="row col-12">
                    <div className="col-2">
                        <Filters />
                    </div>
                    <div className="col-10">
                        <div className="row">
                            <div className="col-5">
                                <h1 id="products_heading" className="text-secondary">
                                    {categoryName}
                                </h1>
                                <h3 id="products_heading" className="text-secondary">
                                    {keyword ? `${data?.filteredProductsCount} Products found with keyword: ${keyword}`
                                            : `${data?.filteredProductsCount} Products found`}
                                </h3>
                            </div>
                            <div className="col-7 text-center d-flex align-items-center justify-content-center">
                                {/* Search bar  */}
                                <div className="col-10">
                                    <Search />
                                </div>
                            </div>
                        </div>
                        {/* Loader */}
                        {loadingAssets  && (
                            <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
                                <Loader />
                            </div>
                        )}

                        <section id="products" className="mt-5">
                            <div className="row">
                                {data.products?.map((product) => (
                                    <ProductItem key={product._id} product={product} customer={customer} />
                                ))}
                            </div>
                        </section>
                        <CustomPagination 
                            resPerPage={Number(data.resPerPage)} 
                            filteredProductsCount={Number(data.filteredProductsCount)}
                            onPageChanged={(page) => {showLoaderAfterPageChange()}}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};


export default Shop;