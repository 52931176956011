
// Hard coded product categories for faster searching
export const PRODUCT_CATEGORIES = [
  { name: "Chinese Vegetable", id: "66457712f1831932b1fcfd4f", otherName: "國菜", image: "/images/product_categories/chinese_vegetables.png", },
  { name: "Sprout", id: "6665bce8b91ff4c41b0f3d95", otherName: "發芽", image: "/images/product_categories/sprout.png",},
  { name: "Root", id: "66544dc97818660d81987715", otherName: "根類", image: "/images/product_categories/roots.png",},
  { name: "Fruit", id: "66458f437a853ef78a3319e0", otherName: "水果類", image: "/images/product_categories/fruits.png", },
  { name: "Vegetables", id: "66457792f1831932b1fcfd51", otherName: "外國菜", image: "/images/product_categories/vegetables.png", },
  { name: "Herbs", id: "66544dd77818660d81987716", otherName: "香菜", image: "/images/product_categories/herbs.png", },
  { name: "Squash", id: "6665bd24b91ff4c41b0f3d96", otherName: "瓜類", image: "/images/product_categories/squashes.png",},
  { name: "Mushroom", id: "66497c509fdb6992b978a5c1", otherName: "菇類", image: "/images/product_categories/mushrooms.png", },
  { name: "Egg, Tofu, Noodles", id: "6665bbe4b91ff4c41b0f3d94", otherName: "蛋豆腐粉麵", image: "/images/product_categories/tofu.png", hasLongName: true},
  { name: "Misc", id: "67191c03be562efaf62e78ad", otherName: "", image: "", hasLongName: true},
];

export const PRODUCT_CATEGORIES_ON_PDF = [
  { name: "Chinese Vegetable", id: "66457712f1831932b1fcfd4f", otherName: "國菜", image: "/images/product_categories/chinese_vegetables.png", },
  { name: "Sprout", id: "6665bce8b91ff4c41b0f3d95", otherName: "發芽", image: "/images/product_categories/sprout.png",},
  { name: "Root", id: "66544dc97818660d81987715", otherName: "根類", image: "/images/product_categories/roots.png",},
  { name: "Vegetables", id: "66457792f1831932b1fcfd51", otherName: "外國菜", image: "/images/product_categories/vegetables.png", },
  { name: "Herbs", id: "66544dd77818660d81987716", otherName: "香菜", image: "/images/product_categories/herbs.png", },
  { name: "Mushroom", id: "66497c509fdb6992b978a5c1", otherName: "菇類", image: "/images/product_categories/mushrooms.png", },
  { name: "Fruit", id: "66458f437a853ef78a3319e0", otherName: "水果類", image: "/images/product_categories/fruits.png", },
  { name: "Squash", id: "6665bd24b91ff4c41b0f3d96", otherName: "瓜類", image: "/images/product_categories/squashes.png",},
  { name: "Egg, Tofu, Noodles", id: "6665bbe4b91ff4c41b0f3d94", otherName: "蛋豆腐粉麵", image: "/images/product_categories/tofu.png", hasLongName: true},
  { name: "Misc", id: "67191c03be562efaf62e78ad", otherName: "", image: "", hasLongName: true},
];

// Hard coded US states for faster searching
export const US_STATES = [
  { name: "Alabama", abbreviation: "AL" },
  { name: "Alaska", abbreviation: "AK" },
  { name: "Arizona", abbreviation: "AZ" },
  { name: "Arkansas", abbreviation: "AR" },
  { name: "California", abbreviation: "CA" },
  { name: "Colorado", abbreviation: "CO" },
  { name: "Connecticut", abbreviation: "CT" },
  { name: "Delaware", abbreviation: "DE" },
  { name: "Florida", abbreviation: "FL" },
  { name: "Georgia", abbreviation: "GA" },
  { name: "Hawaii", abbreviation: "HI" },
  { name: "Idaho", abbreviation: "ID" },
  { name: "Illinois", abbreviation: "IL" },
  { name: "Indiana", abbreviation: "IN" },
  { name: "Iowa", abbreviation: "IA" },
  { name: "Kansas", abbreviation: "KS" },
  { name: "Kentucky", abbreviation: "KY" },
  { name: "Louisiana", abbreviation: "LA" },
  { name: "Maine", abbreviation: "ME" },
  { name: "Maryland", abbreviation: "MD" },
  { name: "Massachusetts", abbreviation: "MA" },
  { name: "Michigan", abbreviation: "MI" },
  { name: "Minnesota", abbreviation: "MN" },
  { name: "Mississippi", abbreviation: "MS" },
  { name: "Missouri", abbreviation: "MO" },
  { name: "Montana", abbreviation: "MT" },
  { name: "Nebraska", abbreviation: "NE" },
  { name: "Nevada", abbreviation: "NV" },
  { name: "New Hampshire", abbreviation: "NH" },
  { name: "New Jersey", abbreviation: "NJ" },
  { name: "New Mexico", abbreviation: "NM" },
  { name: "New York", abbreviation: "NY" },
  { name: "North Carolina", abbreviation: "NC" },
  { name: "North Dakota", abbreviation: "ND" },
  { name: "Ohio", abbreviation: "OH" },
  { name: "Oklahoma", abbreviation: "OK" },
  { name: "Oregon", abbreviation: "OR" },
  { name: "Pennsylvania", abbreviation: "PA" },
  { name: "Rhode Island", abbreviation: "RI" },
  { name: "South Carolina", abbreviation: "SC" },
  { name: "South Dakota", abbreviation: "SD" },
  { name: "Tennessee", abbreviation: "TN" },
  { name: "Texas", abbreviation: "TX" },
  { name: "Utah", abbreviation: "UT" },
  { name: "Vermont", abbreviation: "VT" },
  { name: "Virginia", abbreviation: "VA" },
  { name: "Washington", abbreviation: "WA" },
  { name: "West Virginia", abbreviation: "WV" },
  { name: "Wisconsin", abbreviation: "WI" },
  { name: "Wyoming", abbreviation: "WY" }
];

export const PAYMENT_TYPE = {
  COD: 'cod',
  CARD: 'card',
  BANK: 'bank',
  WALLET: 'wallet',
};

export const PAYMENT_STATUS = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  FAILED: 'failed',
};

export const ORDER_STATUS = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  PROCESSING: 'processing', // generic for orders that are not delivered nor completed
  PACKING: 'packing',
  READY_TO_SHIP: 'ready to ship',
  SHIPPED: 'shipped',
  DELIVERED: 'delivered',
  CANCELLED: 'cancelled',
};

export const ORDER_STATUS_ICON = {
  [ORDER_STATUS.PROCESSING]: { icon: 'fas fa-spinner', color: 'black'},  // generic for orders that are not delivered nor completed
  [ORDER_STATUS.PENDING]: { icon: 'fas fa-pencil', color: 'orange'},
  [ORDER_STATUS.CONFIRMED]: { icon: 'fas fa-check', color: 'crimson'},
  [ORDER_STATUS.PACKING]: { icon: 'fas fa-box-open', color: 'blue'},
  [ORDER_STATUS.READY_TO_SHIP]: { icon: 'fas fa-box', color: 'lightgreen'}, 
  [ORDER_STATUS.SHIPPED]: { icon: 'fas fa-truck', color: 'blueviolet'},
  [ORDER_STATUS.DELIVERED]: { icon: 'fas fa-check-circle', color: 'green'},
  [ORDER_STATUS.CANCELLED]: { icon: 'fas fa-ban', color: 'red'},
};

export const PURCHASE_STATUS = {
  PENDING: 'pending',
  ORDERED: 'ordered', // placed order to vendor but not yet confirmed
  NOT_CONFIRMED: 'notconfirmed', // only for filtering
  CONFIRMED: 'confirmed',
  DELIVERED: 'received', // for purchases, delivered means received
  CANCELLED: 'cancelled',
};

export const QUICKBOOKS_CATEGORY = {
  ORDER: 'order',
  PURCHASE: 'purchase',
  PRODUCT: 'product',
  CUSTOMER: 'customer',
  VENDOR: 'vendor',
};

export const QUICKBOOKS_TRANSACTION_TYPE = {
  CREATE_ORDER: 'create_order',
  READ_ORDER: 'read_order',
  UPDATE_ORDER: 'update_order',

  CREATE_SELL_CREDIT: 'create_sell_credit',
  READ_SELL_CREDIT: 'read_sell_credit',
  UPDATE_SELL_CREDIT: 'update_sell_credit',

  CREATE_PURCHASE: 'create_purchase',
  READ_PURCHASE: 'read_purchase',
  UPDATE_PURCHASE: 'update_purchase',

  CREATE_VENDOR_CREDIT: 'create_vendor_credit',
  READ_VENDOR_CREDIT: 'read_vendor_credit',
  UPDATE_VENDOR_CREDIT: 'update_vendor_credit',

  ADD_PRODUCT: 'add_product',
  READ_PRODUCT: 'read_product',
  UPDATE_PRODUCT: 'update_product',

  ADD_CUSTOMER: 'add_customer',
  READ_CUSTOMER: 'read_customer',
  UPDATE_CUSTOMER: 'update_customer',

  ADD_VENDOR: 'add_vendor',
  READ_VENDOR: 'read_vendor',
  UPDATE_VENDOR: 'update_vendor',
};

export const QUICKBOOKS_STATUS = {
  NOT_SYNCED: 'NotSynced',
  READING: 'Reading',
  SYNCING: 'Syncing',
  SYNCED: 'Synced',
  OUTDATED: 'Outdated',
  ERROR: 'Error',
};

export const QUICKBOOKS_ERROR = {
  
};

export const PURCHASE_STATUS_ICON = {
  [PURCHASE_STATUS.PENDING]: { icon: 'fas fa-pencil', color: 'black'},
  [PURCHASE_STATUS.ORDERED]: { icon: 'fas fa-phone', color: 'orange'},
  [PURCHASE_STATUS.CONFIRMED]: { icon: 'fas fa-check-circle', color: 'green'},
  [PURCHASE_STATUS.DELIVERED]: { icon: 'fas fa-warehouse', color: 'blue'},
  [PURCHASE_STATUS.CANCELLED]: { icon: 'fas fa-ban', color: 'red'},
};

export const USER_ROLES = {
  SUPER_ADMIN: 'superadmin', 
  ADMIN: 'admin', 
  SALE: 'sale', 
  WAREHOUSE: 'warehouse', 
  ACCOUNTANT: 'accountant',
  CUSTOMER: 'customer', // customer is treated in a different schema  
  DRIVER: 'driver',
  ALL: 'all',
};

export const ADMIN_MENU_ITEMS = {
  ORDERS: { id: "orders", name: "Orders", icon: "fas fa-shopping-cart", link: "/admin/orders", roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT] },
  PURCHASES: { id: "purchases", name: "Purchases", icon: "fas fa-shopping-bag", link: "/admin/purchases", roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT] },
  INVENTORY: {  id: "inventories", name: "Inventories", icon: "fas fa-clipboard-list", link: "/admin/inventories", roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT] },
  PRODUCTS: {  id: "products", name: "Products", icon: "fas fa-apple-alt", link: "/admin/products", roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE] },
  USERS: {  id: "users", name: "Users", icon: "fas fa-user-tie", link: "/admin/users", roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN] },
  CUSTOMERS: {  id: "customers", name: "Customers", icon: "fas fa-user", link: "/admin/customers", roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT] },
  PRICE_LIST: {  id: "customersPriceList", name: "Price List", icon: "fas fa-tags", link: "/admin/customerpricelist", roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SALE] },
  VENDORS: {  id: "vendors", name: "Vendors", icon: "fas fa-store", link: "/admin/vendors", roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.ACCOUNTANT] },
  DRIVERS: {  id: "drivers", name: "Drivers", icon: "fas fa-truck", link: "/admin/drivers", roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.ACCOUNTANT] },
  DASHBOARD: {  id: "dashboard", name: "Dashboard", icon: "fas fa-tachometer-alt", link: "/admin/dashboard", roles: [USER_ROLES.SUPER_ADMIN] },
  HISTORY: {  id: "history", name: "Search", icon: "fas fa-search", link: "/admin/history",roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SALE, USER_ROLES.ADMIN, USER_ROLES.WAREHOUSE, USER_ROLES.ACCOUNTANT] },
  TOOLS: {  id: "tools", name: "Tools", icon: "fas fa-tools", link: "/admin/quickbookslog",roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN] },
};

export const ORDER_USER_RIGHTS = {
  VIEW: 'view',
  CREATE: 'create',
  UPDATE: 'update',
  UPDATE_CUSTOMER_EXTRA_NAME: 'update_customer_extra_name',
  SWITCH_CUSTOMER: 'switch_customer',
  ADD: 'add',
  DELETE: 'delete',
  CONFIRM: 'confirm',
  BUY: 'buy',
  PACK: 'pack',
  FINISH_PACKING: 'finish_packing',
  TRANSFER_TO_QUICKBOOKS: 'transfer_to_quickbooks',
  SHIP: 'ship',
  DELIVER: 'deliver',
  VERIFY_INVOICE_SIGNATURE: 'verify_invoice_signature',
  DELETE_DOCUMENT: 'delete_document',
};

export const PURCHASE_USER_RIGHTS = {
  VIEW: 'view',
  CREATE: 'create',
  UPDATE: 'update',
  ADD: 'add',
  DELETE: 'delete',
  ORDER: 'order',
  CONFIRM: 'confirm',
  RECEIVE: 'receive',
};

export const CUSTOMER_STATUS = {
  ACTIVE: 'active', 
  INACTIVE: 'inactive', 
  PENDING: 'pending', 
};

export const VENDOR_STATUS = {
  ACTIVE: 'active', 
  INACTIVE: 'inactive', 
};

export const USER_STATUS = {
  ACTIVE: 'active', 
  INACTIVE: 'inactive', 
};

export const DELIVERY_TYPE = {
  DELIVER: 'deliver', 
  PICK_UP: 'pick_up', 
};

export const DRIVER_DELIVERY_STATUS = {
  CURRENT: 'current',
  HISTORY: 'history',
};

export const PRODUCT_UNIT = {
  BOX: 'box',
  LBS: 'lbs',
  COUNT: 'count',
  PALLET: 'pallet',
};

export const INVENTORY_CHANGE_TYPE = {
  REFUND: 'refund',
  MANUAL: 'manual', 
  PURCHASE: 'purchase', 
  PURCHASE_NEW: 'new_purchase', 
  PURCHASE_REMOVE: 'remove_purchase', 
  VENDOR_CREDIT: 'vendor_credit',
  VENDOR_CREDIT_NEW: 'new_vendor_credit',
  VENDOR_CREDIT_REMOVE: 'remove_vendor_credit',
  ORDER: 'order',
  ORDER_NEW: 'new_order',
  ORDER_REMOVE: 'remove_order',
  SELL_CREDIT: 'sell_credit',
  SELL_CREDIT_NEW: 'new_sell_credit',
  SELL_CREDIT_REMOVE: 'remove_sell_credit',
};

export const MISC_DATA_TAGS = {
  DAILY_ROLLOVER: 'daily_rollover',
  DAILY_BACKUP: 'daily_backup',
  NEW_ARRIVALS: 'new_arrivals',
  OLD_PURCHASE_DATE: 'old_purchase_date',
  OLD_ORDER_DATE: 'old_order_date',
};

export const PRICE_THRESHOLD_STATUS = {
  BELOW_COST: 'below_cost',
  BELOW_TARGET: 'below_target',
  NORMAL: 'normal',
  ABOVE_TARGET: 'above_target',
};

export const TAX_PERCENTAGE = 0;
export const PRICE_PERCENTAGE_DIFFERENCE_PER_TIER=5; // 5% difference between each tier
export const MAX_CUSTOMER_TIER=3;
export const MAX_NUM_OF_DISPLAYED_LOGS=100;
export const DEFAULT_PRICE_PERCENTAGE_DIFFERENCE_RATE=15;
export const PRODUCT_SEARCH_PAGE_RESULT_PER_PAGE=12;
export const INVENTORY_SEARCH_PAGE_RESULT_PER_PAGE=8;
export const DELIVERY_SEARCH_PAGE_RESULT_PER_PAGE_WEB=100;
export const DELIVERY_SEARCH_PAGE_RESULT_PER_PAGE_MOBILE=10;
export const OLD_ORDER_SEARCH_PAGE_RESULT_PER_PAGE=500;
export const COOKIE_EXPIRES_TIME=12*60 * 60 * 1000; // 30 minutes
export const RESET_PASSWORD_TOKEN_EXPIRES_TIME_MINUTE=30;
export const MAX_LOG_COUNT_PER_ORDER=100;
export const MAX_LOG_COUNT_PER_PURCHASE=100;
export const UPLOAD_IMAGE_PRODUCT_MAX_WIDTH=256;
export const UPLOAD_IMAGE_DOCUMENT_MAX_WIDTH=800;
export const MAX_INVENTORY_TRANSACTION_LOG=50;

export const COMPANY_NAME = "Tom Produce Inc.";
export const COMPANY_ADDRESS_LINE1 = "1995 E 20th St Unit 5";
export const COMPANY_ADDRESS_LINE2 = "Los Angeles"
export const COMPANY_ADDRESS_LINE3 = "CA 90058, USA"
export const COMPANY_ADDRESS_PHONE = "(626)678-5818";
export const COMPANY_ADDRESS_FAX = "(213)402-3030";
export const COMPANY_EMAIL = "accounting@tomproduce.com";

export const INACTIVE_LOG_OUT_TIME = 30 * 60 * 1000; // 30 minutes
