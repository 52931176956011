import { createSlice } from "@reduxjs/toolkit";
import { MAX_NUM_OF_DISPLAYED_LOGS, ORDER_STATUS } from "../../constants/constants.js";
import { set } from "lodash";

const initialState = {
  user: null,
  isAuthenticatedAdmin: false,
  loadingAdmin: false,
  unviewedNewOrder: {},
  unviewedUpdatedOrder: {},
  viewOrderHistory: {},
  unviewedNewPurchase: {},
  unviewedUpdatedPurchase: {},
  viewPurchaseHistory: {},
  logTimestamp: localStorage.getItem("logTimestamp") ? localStorage.getItem("logTimestamp") : 0,
  logsData: [],
  needReloadingLogs: true,
};

export const adminSlice = createSlice({
  initialState,
  name: "adminSlice",
  reducers: {
    setAdmin(state, action) {
      state.user = action.payload;

      if (state.user) {
        // Set order view history
        if (state.user.viewOrderHistory) {
          state.viewOrderHistory = state.user.viewOrderHistory;
        }
        if (state.user.viewPurchaseHistory) {
          state.viewPurchaseHistory = state.user.viewPurchaseHistory;
        }
      }
    },
    setIsAuthenticatedAdmin(state, action) {
      state.isAuthenticatedAdmin = action.payload;
    },
    setLoadingAdmin(state, action) {
      state.loadingAdmin = action.payload;
    },
    updateUserUnviewedOrders: (state, action) => {
      const orders = action.payload;

      // reset counts
      state.unviewedNewOrder = {};
      state.unviewedUpdatedOrder = {};

      Object.entries(orders).forEach(([key, value]) => {
        const status = (value.status === ORDER_STATUS.CONFIRMED || value.status === ORDER_STATUS.PACKING || value.status === ORDER_STATUS.READY_TO_SHIP) ? ORDER_STATUS.PROCESSING : value.status;
        const viewedItem = state.viewOrderHistory[key];
        if (value.needAttn) {
          if (viewedItem) {
            // only set the count if the order is not viewed and needs attention
            if (viewedItem.timestamp < value.timestamp) {
              if (state.unviewedUpdatedOrder[status] === undefined) {
                state.unviewedUpdatedOrder[status] = 0;
              }
              state.unviewedUpdatedOrder[status] += 1;
            }
          } else {
            if (state.unviewedNewOrder[status] === undefined) {
              state.unviewedNewOrder[status] = 0;
            }
            state.unviewedNewOrder[status] += 1;
          }
        }
      });
    },
    updateUserUnviewedPurchases: (state, action) => {
      const purchases = action.payload;

      // reset counts
      state.unviewedNewPurchase = {};
      state.unviewedUpdatedPurchase = {};

      Object.entries(purchases).forEach(([key, value]) => {
        const status = value.status;
        const viewedItem = state.viewPurchaseHistory[key];
        if (value.needAttn) {
          if (viewedItem) {
            if (viewedItem.timestamp < value.timestamp) {
              if (state.unviewedUpdatedPurchase[status] === undefined) {
                state.unviewedUpdatedPurchase[status] = 0;
              }
              state.unviewedUpdatedPurchase[status] += 1;
            }
          } else {
            if (state.unviewedNewPurchase[status] === undefined) {
              state.unviewedNewPurchase[status] = 0;
            }
            state.unviewedNewPurchase[status] += 1;
          }
        }
      });
    },
    setLogsData: (state, action) => {
      state.logsData = action.payload ? action.payload : [];
    },
    addLog: (state, action) => {
      if (!state.logsData) {
        state.logsData = [];
      }
      if (state.logsData.length > MAX_NUM_OF_DISPLAYED_LOGS) {
        state.logsData.shift();
      }
      // Check if the log already exists
      if (state.logsData.length > 0 && state.logsData[state.logsData.length - 1]._id === action.payload._id) {
        return;
      }
      state.logsData.push(action.payload);
    },
    updateLogTimestamp: (state, action) => {
      state.logTimestamp = action.payload;
      localStorage.setItem("logTimestamp", state.logTimestamp);
    },
    setNeedReloadingLogs: (state, action) => {
      state.needReloadingLogs = action.payload;
    }
  },
});

export default adminSlice.reducer;

export const { 
  setIsAuthenticatedAdmin, 
  setAdmin, 
  setLoadingAdmin,
  updateUserUnviewedOrders,
  updateUserUnviewedPurchases,
  updateLogTimestamp,
  setLogsData,
  addLog,
  setNeedReloadingLogs,
} = adminSlice.actions;
