import React, { useEffect } from "react";
import { useMyOrdersQuery } from "../../redux/api/orderApi";
import Loader from "../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import MetaData from "../layout/MetaData";
import { useDispatch } from "react-redux";
import { clearCart } from "../../redux/features/cartSlice";
import Header from "../layout/Header";
import { CenterAlignedCell, RightAlignedCell } from "../layout/CustomMDBDataTableCell";
import { appendAppVersionToUrl } from "../../utils/utilities";

const MyOrders = () => {
  const { data, isLoading, error } = useMyOrdersQuery();

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderSuccess = searchParams.get("order_success");

  /*
  useEffect(() => {
    localStorage.setItem('lastCustomerVisitedPage', window.location.pathname);
  }, []);
  */

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }

    if (orderSuccess) {
      dispatch(clearCart());
      navigate(appendAppVersionToUrl("/me/orders"));
    }
  }, [error, orderSuccess]);

  const setOrders = () => {
    const orders = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
          attributes: { style: { textAlign: 'center' } }
        },
        {
          label: "Amount",
          field: "amount",
          sort: "asc",
          attributes: { style: { textAlign: 'center' } }
        },
        {
          label: "Order Status",
          field: "orderStatus",
          sort: "asc",
          attributes: { style: { textAlign: 'center' } }
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          attributes: { style: { textAlign: 'center' } }
        },
        /*
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          attributes: { style: { textAlign: 'center' } }
        },
        */
      ],
      rows: [],
    };

    data?.orders?.forEach((order) => {
      orders.rows.push({
        id: <CenterAlignedCell value={order?.orderNumber}> </CenterAlignedCell>,
        amount: <RightAlignedCell value={order?.totalAmount === undefined ? 'Call' : `$${order?.totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}> </RightAlignedCell>,
        orderStatus: <CenterAlignedCell value={order?.status?.toUpperCase()}> </CenterAlignedCell>,
        date: <CenterAlignedCell value={new Date(order?.createdAt).toLocaleString("en-US")}> </CenterAlignedCell>,
        /*
        actions: (
          <>
            <Link
              to={`/invoice/order/${order?._id}`}
              className="btn btn-success ms-2"
              onClick={(e) => e.stopPropagation()} // Stop event propagation
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <i className="fa fa-print"></i>
                </div>
            </Link>
          </>
        ),
        */
        clickEvent: () => navigate(appendAppVersionToUrl(`/me/orders/${order?._id}`)), // Correct usage of navigate
      });
    });

    return orders;
  };

  if (isLoading) return <Loader />;

  return (
    <div>
      <MetaData title={"My Orders"} />
      <Header title={"My Orders"} />
      <div className="container">
        <h1 className="my-5">{data?.orders?.length} Orders</h1>
        <MDBDataTable
          data={setOrders()}
          className="px-3"
          bordered
          striped
          hover
          noBottomColumns
        />    
      </div>
    </div>
  );
};

export default MyOrders;
