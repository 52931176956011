import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setInventoryDataPurchasesByProduct, setLoadingInventoryDataPurchasesByProduct, setInprogressPurchaseData } from "../features/inProgressPurchasesSlice.js";
import { PURCHASE_STATUS } from "../../constants/constants.js";
import { setAdmin, updateUserUnviewedPurchases } from "../features/adminSlice.js";
import { getBackendURI, getQueryCorsMode } from "./helper.js";
import { convertToPST } from "../../utils/utilities.js";
import { formatInTimeZone } from "date-fns-tz";

export const purchaseAdminApi = createApi({
  reducerPath: "purchaseAdminApi",
  baseQuery: fetchBaseQuery(
    { 
      baseUrl: getBackendURI("/api/v1/admin"),
      mode: getQueryCorsMode(),
      credentials: 'include', // Include cookies in the request
    }),
  tagTypes: ["Purchase", "OldPurchase", "Inventory"],
  endpoints: (builder) => ({
    // New Purchase
    adminCreateNewPurchase: builder.mutation({
      query(body) {
        return {
          url: "/purchases/new",
          method: "POST",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Purchase', id: result.purchase.id }, "Purchase"],
    }),
    // New vendor credit
    adminCreateNewVendorCredit: builder.mutation({
      query(body) {
        return {
          url: "/vendorcredits/new",
          method: "POST",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Purchase', id: result.purchase.id }, "Purchase"],
    }),
    // Purchase details
    adminPurchaseDetails: builder.query({
      query: ({id, isOldPurchase}) => {
        return isOldPurchase ? `/oldpurchases/${id}` : `/purchases/${id}`;
      },
      transformResponse: (result) => result.purchase,
      providesTags: (result, error, id) => [{ type: 'OldPurchase', id }, { type: 'Purchase', id }],
      forceRefetch: ({ currentArg, previousArg }) => true, // Always refetch
    }),
    // Dashboard purchases
    adminGetDashboardPurchases: builder.query({
      query: ({ startDate, endDate }) => `/getpurchasesinrange/?startDate=${startDate}&endDate=${endDate}`,
    }),
    // Get in-progress Purchases
    adminGetInProgressPurchases: builder.query({
      query: () => `/purchasesinprogress`,
      providesTags: ["Purchase"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {  
        try {
            const { data } = await queryFulfilled;
            const purchaseStatsHash = {};
            let collectionTimestamp = 0;
            // Convert to hash map - key is product id
            const purchasesByProduct = data.purchases.reduce((acc, purchase) => {
              const timestamp = new Date(purchase.updatedAt).getTime();
              collectionTimestamp = Math.max(timestamp, collectionTimestamp);
              purchaseStatsHash[purchase._id] = {timestamp, status: purchase.status, needAttn: purchase.needAttn};
              const purchaseId = purchase._id;
              const purchaseNumber = purchase.purchaseNumber;
              const vendor = purchase.vendor;
              if (purchase.purchaseItems) {
                purchase.purchaseItems.forEach(item => {
                  const productId = item.product.toString();
                  if (!acc[productId]) {
                    acc[productId] = {total: 0, inProgressTotal: 0, purchases: []}; 
                  }
                  acc[productId].purchases.push({ purchaseId, purchaseNumber, vendor, price: item.finalPrice, quantity: item.finalQuantity, note: item.note, status: purchase.status, updatedAt: purchase.updatedAt, _id: item._id });
                  acc[productId].total += item.finalQuantity;
                  if (purchase.status !== PURCHASE_STATUS.DELIVERED) {
                    acc[productId].inProgressTotal += item.finalQuantity;
                  }
                });
              }
              return acc;
            }, {});
            dispatch(setInprogressPurchaseData(data));
            dispatch(setInventoryDataPurchasesByProduct({purchasesByProduct, collectionTimestamp}));
            dispatch(setLoadingInventoryDataPurchasesByProduct(false));
            dispatch(updateUserUnviewedPurchases(purchaseStatsHash));
        } catch (error) {
            dispatch(setLoadingInventoryDataPurchasesByProduct(false));
            console.log(error);
        }
      },
    }),
    // Get Purchases by date
    adminGetPurchasesByDate: builder.query({
      query: ({startDate, endDate}) => ({
        url: "/purchasesbydate",
        params: {
          startDate,
          endDate,
        },
      }),
      transformResponse: (response) => {
        // Transform the response data here
        return{ ...response, purchases: response.purchases.map(purchase => ({
          ...purchase,
          purchaseDate: formatInTimeZone(convertToPST(new Date(purchase.purchaseDate)), 'America/Los_Angeles', 'MM/dd/yyyy'),
        }))};
      },
      providesTags: ["Purchase", "OldPurchase"],
    }),
    // Search purchases
    adminSearchPurchases: builder.query({
      query: (params) => ({
        url: "/searchpurchases",
        params: {
          page: params?.page,
          extraParams: params?.extraParams,
          searchValue: params?.searchValue,
          startDate: params?.startDate,
          endDate: params?.endDate,
        },
      }),
      providesTags: ["OldPurchase"],
      forceRefetch: ({ currentArg, previousArg }) => true, // Always refetch
    }),
    // Old Purchase details
    adminOldPurchaseDetails: builder.query({
      query: (id) => `/oldpurchases/${id}`,
      providesTags: (result, error, id) => [{ type: 'OldPurchase', id }],
      transformResponse: (result) => result.purchase,
      forceRefetch: ({ currentArg, previousArg }) => true, // Always refetch
    }),    
    // Get purchases by vendor Id - inProgress and delivered
    adminGetPurchasesByVendor: builder.query({
      query: (id) => `/vendors/${id}/purchases`,
      providesTags: ["Purchase"],
    }),
    // Update Purchase
    adminUpdatePurchase: builder.mutation({
      query({ id, isOldPurchase, body }) {
        //console.log("Updating Purchase ...", body);
        return {
          url: isOldPurchase ? `/oldpurchases/${id}` : `/purchases/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'Purchase', id: result.purchase.id }, "Purchase", { type: 'OldPurchase', id: result.purchase.id }, "OldPurchase", "Inventory"],
    }),
    // Update Old Purchase
    adminUpdateOldPurchase: builder.mutation({
      query({ id, body }) {
        //console.log("Updating Purchase ...", body);
        return {
          url: `/oldpurchases/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags:  (result) => [{ type: 'OldPurchase', id: result.purchase.id }, "OldPurchase"],
    }),
    
    // Receive Purchase
    adminReceivePurchase: builder.mutation({
      query({ id }) {
        //console.log("Updating Purchase ...", body);
        return {
          url: `/purchases/${id}/receive`,
          method: "PUT",
        };
      },
      invalidatesTags:  (result) => [{ type: 'Purchase', id: result.purchase.id }, "Purchase", "Inventory"],
    }),
    adminForceResyncMultiplePurchaseQuickbooks: builder.mutation({
      query({ body }) {
        return {
          url: `/purchases/forcequickbooksresync`,
          method: "PUT",
          params: {
            key: body.option,
            startDate: body.startDate,
            endDate: body.endDate,
          },
        };
      },
      invalidatesTags:  (result) => [{ type: 'Order', id: result.order.id }],
    }),
    adminForceResyncOnePurchaseQuickbooks: builder.mutation({
      query({ id, isHistory }) {
        return {
          url: `/purchases/${id}/forcequickbooksresync`,
          method: "PUT",
          params: {
            isHistory,
          },
        };
      },
    }),
    // Delete purchase
    adminDeletePurchase: builder.mutation({
      query(id) {
        return {
          url:`/purchases/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Purchase", "OldPurchase"],
    }),
    // Get purchases by product Id
    adminGetPurchasesByProduct: builder.query({
      query: (id) => `/purchasesinprogress/product/${id}`,
      transformResponse: (result) => result.purchases,
      providesTags: ["Purchase"],
    }),
    // Update purchases by product Id
    adminUpdatePurchasesByProduct: builder.mutation({
      query({ id, body }) {
        return {
          url: `/purchasesinprogress/product/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Purchase"],
    }),
    // Remove purchase item by product Id
    adminRemovePurchaseItemByProduct: builder.mutation({
      query({ productId, purchaseId }) {
        return {
          url: `/purchasesinprogress/product/${productId}/remove/${purchaseId}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Purchase"],
    }),
    // Mark purchase as viewed
    adminMarkPurchaseAsViewed: builder.mutation({
      query({ purchaseId, body }) {
        return {
          url: `/purchases/${purchaseId}/markasviewed`,
          method: "PUT",
          body
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          if (result && result.data?.data) {
            dispatch(setAdmin(result.data.data));
          }
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const {
  useAdminCreateNewPurchaseMutation,
  useAdminCreateNewVendorCreditMutation,
  useAdminPurchaseDetailsQuery,
  useLazyAdminPurchaseDetailsQuery,
  useAdminOldPurchaseDetailsQuery,
  useLazyAdminGetDashboardPurchasesQuery,
  useAdminUpdatePurchaseMutation,
  useAdminUpdateOldPurchaseMutation,
  useAdminReceivePurchaseMutation,
  useAdminDeletePurchaseMutation,

  useLazyAdminGetInProgressPurchasesQuery,
  useLazyAdminGetPurchasesByDateQuery,
  useLazyAdminSearchPurchasesQuery,

  useAdminGetPurchasesByProductQuery,
  useAdminGetPurchasesByVendorQuery,
  useAdminUpdatePurchasesByProductMutation,
  useAdminRemovePurchaseItemByProductMutation,

  useAdminMarkPurchaseAsViewedMutation,

  useAdminForceResyncMultiplePurchaseQuickbooksMutation,
  useAdminForceResyncOnePurchaseQuickbooksMutation,
} = purchaseAdminApi;
