import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useLazyCustomerLogoutQuery } from "../../redux/api/customerAuthApi";
import { appendAppVersionToUrl } from "../../utils/utilities";

const Header = ({ noVerification, title }) => {
  const navigate = useNavigate();
  const [customerLogout] = useLazyCustomerLogoutQuery();
  const { isAuthenticated, customer } = useSelector((state) => state.customerAuth);
  const { cartItems } = useSelector((state) => state.cart);

  useEffect(() => {
    if (!noVerification && !isAuthenticated) {
      navigate(appendAppVersionToUrl('/login'));
    }
  }, [isAuthenticated]);

  const handleLogout = () => {
    customerLogout();
  }

  return (
    <div className="row border" style={{width: '100%'}}>
      <div className="col-2 align-items-start">
        <div className="navbar-brand">
          <Link to={appendAppVersionToUrl("/shop")}>
            <img src="/images/TomProduce_logo.jpg" alt="Tomproduce Logo" style={{ height: '10vh', width: 'auto' }}/>
          </Link>
        </div>
        <div>
          <span style={{ fontSize: '80%', color: 'gray' }}>
            {`${process.env.REACT_APP_NODE_ENV === 'PRODUCTION' ? '' : 'Test' } v${process.env.REACT_APP_VERSION}`}
          </span>
        </div>
      </div>

      <div className="col-7 text-center d-flex align-items-center justify-content-center">
        {/* Title */}
        {title && (
          <div className="col-10 text-center"> {/* Modified: Added text-center class */}
            <h2 className="text-black">{title}</h2>
          </div>
        )}
      </div>

      <div className="col-3 text-center d-flex align-items-center">
        {/* Cart button */}
        <div className="col-6 text-end d-flex align-items-center justify-content-center">
          {isAuthenticated && (
            <Link to={appendAppVersionToUrl("/cart")} style={{ textDecoration: "none" }}>
              <span id="cart" className="ms-3">
                {" "}
                Cart{" "}
              </span>
              <i className="fas fa-shopping-cart ms-1"></i>
              <span className="ms-1" id="cart_count">
                {cartItems?.length}
              </span>
            </Link>)
          }
        </div>

        {/*User button*/}
        <div className="col-6 text-end d-flex align-items-center justify-content-center">
          {customer && (
            <div className="ms-4 dropdown">
              <button
                className="btn dropdown-toggle text-black"
                type="button"
                id="dropDownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <figure className="avatar avatar-nav">
                  <img
                    src={
                      customer?.avatar
                        ? customer?.avatar?.url
                        : "/images/default_avatar.jpg"
                    }
                    alt="User Avatar"
                    className="rounded-circle"
                  />
                </figure>
                <span>{customer?.name}</span>
              </button>
              <div
                className="dropdown-menu w-100"
                aria-labelledby="dropDownMenuButton"
              >
                <Link className="dropdown-item" to="/">
                  {" "}
                  Home{" "}
                </Link>

                <Link className="dropdown-item" to="/me/oldorders">
                  {" "}
                  History{" "}
                </Link>

                <Link className="dropdown-item" to="/me/orders">
                  {" "}
                  Orders{" "}
                </Link>

                <Link className="dropdown-item" to="/me/profile">
                  {" "}
                  Profile{" "}
                </Link>

                <Link
                  className="dropdown-item text-danger"
                  onClick={handleLogout}
                >
                  Logout{" "}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div> 
    </div>
  );
};

export default Header;
