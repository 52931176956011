import { Modal, Button } from 'react-bootstrap';
import { DELIVERY_TYPE } from '../../constants/constants.js';
import { useState } from 'react';

export const ConfirmDialog = ({ title, show, message, confirm, cancel, ok }) => {
    return (
        <div>
          <Modal show={show} onHide={cancel ? cancel : ok}>
            <Modal.Header closeButton>
              <Modal.Title>{title ? title : "Confirmation" }</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
              { ok && (
                <Button variant="secondary" onClick={()=>{ok()}}>
                 OK
                </Button>
              )}
              { cancel && (
                <Button variant="secondary" onClick={()=>{cancel()}}>
                  No
                </Button>
              )}
              {confirm && (
                <Button variant="danger" onClick={()=>{confirm()}}>
                  Yes
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
    );
};

export const ConfirmDialogUpdateDeliveredOrder = ({ title, show, message, updateStock, dontUpdateStock, cancel, action }) => {
  return (
      <div>
        <Modal show={show} onHide={cancel}>
          <Modal.Header closeButton>
            <Modal.Title>{title ? title : "Confirmation" }</Modal.Title>
          </Modal.Header>
          <Modal.Body>{message}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{cancel()}}>
              No
            </Button>
            <Button variant="danger" onClick={()=>{updateStock()}}>
              Yes - Update Stock
            </Button>
            <Button variant="danger" onClick={()=>{dontUpdateStock()}}>
              Yes - Dont Update Stock
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
  );
};

export const ConfirmDialogReceivePurchase = ({ title, invoice, itemCount, show, confirm, cancel }) => {
  return (
    <div>
      <Modal show={show} onHide={cancel}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row py-1">
              <div className="col-6 text-end">
                <strong>Invoice #: </strong>
              </div>
              <div className="col-6">
                {invoice} 
              </div>
            </div> 
            <div className="row py-1">
              <div className="col-6 text-end">
                <strong>Total Items: </strong>
              </div>
              <div className="col-6">
                {itemCount} units
              </div>
            </div> 
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{cancel()}}>
            No
          </Button>
          <Button variant="danger" onClick={()=>{confirm()}}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const ConfirmDialogShippingOrder = ({ title, invoice, deliverType, selectedDriver, pallet, show, confirm, cancel }) => {
  return (
    <div>
      <Modal show={show} onHide={cancel}>
        <Modal.Header closeButton>
          <Modal.Title>{title ? title : "Confirmation" }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row py-1">
              <div className="col-6 text-end">
                <strong>Pallet Size: </strong>
              </div>
              <div className="col-6">
                {pallet} pallets
              </div>
            </div> 
            <div className="row py-1">
              <div className="col-6 text-end">
                <strong>Delivery Type: </strong>
              </div>
              <div className="col-6">
                {String(deliverType).toUpperCase()} 
              </div>
            </div>
            {deliverType === DELIVERY_TYPE.DELIVER && selectedDriver && (
              <div className="row py-1">
                <div className="col-6 text-end">
                  <strong>Driver: </strong>
                </div>
                <div className="col-6">
                  {selectedDriver}
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{cancel()}}>
            No
          </Button>
          <Button variant="danger" onClick={()=>{confirm()}}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};