import React, { useEffect, useState } from "react";
import { useCustomerResetPasswordMutation } from "../../redux/api/customerApi";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import MetaData from "../layout/MetaData";
import { validateUserPassword } from "../../utils/validators";
import Header from "../layout/Header";
import { appendAppVersionToUrl } from "../../utils/utilities";

const CustomerResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  const [resetPassword, { isLoading, error, isSuccess }] = useCustomerResetPasswordMutation();

  const { isAuthenticated } = useSelector((state) => state.customerAuth);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(appendAppVersionToUrl("/"));
    }
    if (error) {
      toast.error(error?.data?.message);
    }

    if (isSuccess) {
      toast.success("Password reset successfully");
      navigate(appendAppVersionToUrl("/"));
    }
  }, [error, isAuthenticated, isSuccess]);

  const submitHandler = (e) => {
    e.preventDefault();

    const user = {  
      password,
      confirmPassword,
    };
    const { passwordIsValid, passwordErrors } = validateUserPassword(user);

    if (passwordIsValid) {
      const data = {
        password,
        confirmPassword,
      };
      resetPassword({ token: params?.token, body: data });
    } else {
      Object.values(passwordErrors).forEach((error) => {
        toast.error(error);
      });
    }
  };

  return (
    <>
      <MetaData title={"Reset Password"} />
      <Header noVerification={true} />
      <div className="container">
        <div className="row wrapper">
          <div className="col-10 col-lg-5">
            <form className="shadow rounded bg-body" onSubmit={submitHandler}>
              <h2 className="mb-4">Reset Password</h2>

              <div className="mb-3">
                <label htmlFor="password_field" className="form-label">
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password_field"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="confirm_password_field" className="form-label">
                  Confirm Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="confirm_password_field"
                  className="form-control"
                  name="confirm_password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>

              <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
              </span>

              <button
                id="new_password_button"
                type="submit"
                className="btn w-100 py-2"
                disabled={isLoading}
              >
                Set Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerResetPassword;
