import React, { useEffect, useState } from "react";
import { useLazyMyOldOrdersQuery } from "../../redux/api/orderApi";
import Loader from "../layout/Loader";
import { MDBDataTable } from "mdbreact";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import MetaData from "../layout/MetaData";
import Header from "../layout/Header";
import CustomPagination from "../layout/CustomPagination";
import DatePicker from "react-datepicker";
import { appendAppVersionToUrl } from "../../utils/utilities";

const MyOldOrders = () => {
  // Get params
  let [searchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(new Date().setDate(1));
  const [endDate, setEndDate] = useState(new Date());

  const params = { page, startDate, endDate };
  const [myOldOrders, { data, isLoading, error }] = useLazyMyOldOrdersQuery(params);

  const fetchOrders = () => {
    myOldOrders({
      page,
      startDate: new Date(startDate).toISOString(),
      endDate: endDate.toISOString(),
    });
  };

  /*
  useEffect(() => {
    localStorage.setItem('lastCustomerVisitedPage', window.location.pathname);
  }, []);
  */

  useEffect(() => {
    fetchOrders();
  }, [page]);

  const setOrders = () => {
    const orders = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Amount",
          field: "amount",
          sort: "asc",
        },
        {
          label: "Payment Status",
          field: "paymentStatus",
          sort: "asc",
        },
        {
          label: "Order Status",
          field: "orderStatus",
          sort: "asc",
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };

    data?.orders?.forEach((order) => {
      orders.rows.push({
        id: order?.orderNumber,
        amount: `$${order?.totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
        paymentStatus: order?.paymentInfo?.status?.toUpperCase(),
        orderStatus: order?.status?.toUpperCase(),
        date: new Date(order?.createdAt).toLocaleString("en-US"),
        actions: (
          <>
            <Link
              to={appendAppVersionToUrl(`/invoice/oldorder/${order?._id}`)}
              className="btn btn-success ms-2"
              onClick={(e) => e.stopPropagation()} // Stop event propagation
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <i className="fa fa-print"></i>
                </div>
            </Link>
          </>
        ),
        clickEvent: () => navigate(appendAppVersionToUrl(`/me/oldorders/${order?._id}`)), // Correct usage of navigate
      });
    });

    return orders;
  };

  if (isLoading || !data) return <Loader />;

  return (
    <div>
      <MetaData title={"History Orders"} />
      <Header  title={"History Orders"}/>
      <div className="row" style={{ padding: '16px' }}>        
        <div className="col-3 column">
          <div className="py-5">
            <label className="form-label d-block">Start Date</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              className="form-control"
            />
          </div>
          <div  className="py-1">
            <label className="form-label d-block">End Date</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              className="form-control"
            />
          </div>
          <div className="col-6 py-1">
            <button
              className="btn fetch-btn ms-4 mt-3 px-5"
              onClick={fetchOrders}
            >
              Fetch
            </button>
          </div>
        </div>

        <div className="col-9 column" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', height: '100vh' }}>
          <div className="container">
            <h1 className="my-5">{data?.filteredOrdersCount} Orders</h1>
            <MDBDataTable
              data={setOrders()}
              className="px-3"
              bordered
              striped
              hover
              noBottomColumns
              paging={false}
            />    
          </div>
          <div>
            <CustomPagination 
              resPerPage={Number(data.resPerPage)} 
              filteredProductsCount={Number(data.filteredOrdersCount)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOldOrders;
