import HomePageLayout from "../layout/HomePageLayout";
import { COMPANY_ADDRESS_FAX, COMPANY_ADDRESS_LINE1, COMPANY_ADDRESS_LINE2, COMPANY_ADDRESS_LINE3, COMPANY_ADDRESS_PHONE, COMPANY_EMAIL } from "../../constants/constants";

const PrivacyPolicy = () => {
    return (
        <HomePageLayout title="Privacy Policy">
            <div style={{ padding: '20px', textAlign: 'left', fontSize: '1.2em', lineHeight: '1.4', marginTop: '20px' }}>
                <h1>Privacy Policy</h1>
                <p><strong>Last Updated:</strong> 11/08/2024</p>
                
                <h2>1. Information We Collect</h2>
                <p>We collect the following types of information when you use our services:</p>
                <ul>
                <li><strong>Personal Information:</strong> Name, phone number, email address, and location data.</li>
                <li><strong>Usage Data:</strong> Information about how you interact with our app and website.</li>
                </ul>
        
                <h2>2. How We Use Your Information</h2>
                <p>We use your information to provide and improve our services, communicate with you, and comply with legal requirements.</p>
        
                <h2>3. Sharing Your Information</h2>
                <p>We may share your information with third-party service providers, legal authorities, or in the event of a business transfer (such as a merger or sale).</p>
        
                <h2>4. Data Security</h2>
                <p>We take reasonable steps to protect your data, but we cannot guarantee its absolute security due to the nature of the internet.</p>
        
                <h2>5. Your Rights</h2>
                <p>You may have rights regarding your personal data, such as the right to access, correct, or delete your information. Please contact us for assistance.</p>
        
                <h2>6. Third-Party Links</h2>
                <p>Our app and website may contain links to third-party services. We are not responsible for the privacy practices of those services.</p>
        
                <h2>7. Children’s Privacy</h2>
                <p>Our services are not intended for children under 13, and we do not knowingly collect information from children.</p>

                <h2>8. Changes to This Privacy Policy</h2>
                <p>We may update this policy from time to time. Please check this page for updates regularly.</p>
        
                <h2>9. Contact Us</h2>
                <p>If you have questions about this Privacy Policy, contact us at:</p>
                <ul>
                <li>Email: <a href="mailto:admin@tomproduce.com">admin@tomproduce.com</a></li>
                <li>Phone: {COMPANY_ADDRESS_PHONE}</li>
                <li>Address: {COMPANY_ADDRESS_LINE1} {COMPANY_ADDRESS_LINE2}  {COMPANY_ADDRESS_LINE3}</li>
                </ul>
            </div>
        </HomePageLayout>
    );
  };
  
  const styles = {
    container: {
      width: '80%',
      margin: '0 auto',
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
      backgroundColor: '#f4f4f4',
    },
    heading: {
      textAlign: 'center',
      color: '#333',
    },
  };
  
  export default PrivacyPolicy;