
import React, { useState, useEffect, useRef  } from 'react';
import Loader from '../Loader';
import { EditableNumberCell } from '../CustomMDBDataTableCell';
import { useLazyAdminGetInventoryDetailsQuery, useAdminUpdateInventoryMutation } from "../../../redux/api/inventoriesAdminApi";
import { toast } from 'react-toastify';

export const InventoryUpdatePopup = ({ onClose, inventoryId, title, visibility }) => {
  const [adminUpdateInventoryMutation, { data: updatedInventoryData, isLoading: isUpdatingInventories, error: errorUpdatingInventoryData }] = useAdminUpdateInventoryMutation(inventoryId);
  const [triggerGetInventoryData, { data: inventoryData, isLoading: isLoadingInventoryData, error: errorLoadingInventoryData }] = useLazyAdminGetInventoryDetailsQuery();
  const [stock, setStock] = useState();
  const [thereAreValueChanges, setThereAreValueChanges] = useState(false);
  const [note, setNote] = useState('');
  const [histories, setHistories] = useState([]);

  const handleNotesChange = (event) => {
    setNote(event.target.value);
  };
  const popupRef = useRef(); // Create a ref

  useEffect(() => {
    if (errorLoadingInventoryData) {
      toast.error("Error loading inventory");
    }
  }, [errorLoadingInventoryData]);

  useEffect(() => {
    if (errorUpdatingInventoryData) {
      toast.error("Error updating inventory");
    }
  }, [errorUpdatingInventoryData]);

  useEffect(() => {
    if (inventoryData) {
      setStock(inventoryData.inventory.quantity);
      setHistories(inventoryData.histories);
      setThereAreValueChanges(false);
    }
  }, [inventoryData]);

  useEffect(() => {
    if (updatedInventoryData) {
      setStock(updatedInventoryData.quantity);
      setHistories(updatedInventoryData.changeLog);
      setThereAreValueChanges(false);
    }
  }, [updatedInventoryData]);

  // Add this useEffect hook
  useEffect(() => {
    // force reload the inventory data if visibility changes
    if (visibility === 'visible') {
      const timestamp = new Date().getTime();
      triggerGetInventoryData({ inventoryId, timestamp });
    }

    const handleClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        // don't allow click below the popup
        event.preventDefault();
        event.stopPropagation();
      }
    };

    // Bind the event listener
    if(visibility === 'visible') {
      document.addEventListener("mouseup", handleClick);
    } else {
      document.removeEventListener("mouseup", handleClick);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClick);
    };
  }, [visibility]);

  
  //--------------------------------- Functions ---------------------------------
  // Update quantity of the orders
  const handleStockChange = (value) => {
    if (!isNaN(value)) {
      setStock(Number(value));
      setThereAreValueChanges(value !== inventoryData?.inventory.quantity);
    }
  };


  // Update inventory
  const updateHandler = () => {
    // There there are chages, update first
    if (thereAreValueChanges) {
      adminUpdateInventoryMutation({ id: inventoryId, body: { newStock: stock, note } });
    }
  };

  const popupStyle = {
    position: 'fixed', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
    visibility: visibility,
  };

  return(
    <div ref={popupRef} id="popupMedium" class="popup" style={{...popupStyle, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <div className="row col-12 my-2">
        <div className="col-2"> 
          <button onClick={updateHandler} className="btn btn-success" disabled={!thereAreValueChanges || isLoadingInventoryData || isUpdatingInventories}>
            Update
          </button>
        </div> 
        <div className="col-8 text-center"><h5>{title}</h5></div>
        <div className="col-2 text-end">
          <button type="button" className="close" aria-label="Close" onClick={onClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div style={{ width: '96%', maxHeight: '80vh', overflow: 'overlay'}}>
        <table className="table table-bordered" style={{ width: '100%' }}>
          <tbody>
            <tr style={{width: '100%'}}>
              {/* stock */}
              <td className={'text-start'} style={{width: '50%'}}>
                Stock
              </td>
              <td className={'text-end'} style={{width: '50%'}}>
                <EditableNumberCell 
                  value={stock} onChange={(val) => {handleStockChange(val);}} color={stock !== inventoryData?.inventory.quantity ? 'red' : 'black'}> 
                </EditableNumberCell>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ width: '96%', marginTop: '10px' }}>
        <label htmlFor="notes" style={{ display: 'block', marginBottom: '5px' }}>Note:</label>
        <textarea
          id="notes"
          value={note}
          onChange={handleNotesChange}
          style={{ width: '100%', height: '150px' }}
          maxLength={200}
        />
      </div>
      {(isLoadingInventoryData || isUpdatingInventories) && ( 
        <div style={{position: 'relative', height: '100%', width: '100%'}}>
          <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
            <Loader />
          </div>
        </div>)
      }

      {/* History */}
      <div style={{ width: '96%', marginTop: '10px' }}>
        <label htmlFor="history" style={{ display: 'block', marginBottom: '5px' }}>History:</label>
        <textarea
          id="history"
          value={histories.map(entry => `${new Date(entry.date).toLocaleDateString()}- ${entry.note}`).join('\n')}
          readOnly // Make the textarea read-only
          style={{ width: '100%', height: '250px', overflowY: 'scroll' }} // Make it scrollable
        />
      </div>
    </div>
  );
};