import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { Link, useNavigate } from "react-router-dom";
import MetaData from "../../layout/MetaData";
import { useSelector } from "react-redux";

import AdminLayout from "../../layout/AdminLayout";

import {
  useAdminDeleteDriverMutation,
  useAdminGetAllDriversQuery,
} from "../../../redux/api/adminApi";
import { ADMIN_MENU_ITEMS, USER_ROLES } from "../../../constants/constants.js";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { ConfirmDialog } from "../ConfirmDialog";
import { CanDeleteDriver, CanCreateDriver, CanUpdateDriver } from "../AdminActionEligibilities";
import { CenterAlignedCell } from "../../layout/CustomMDBDataTableCell";
import { appendAppVersionToUrl } from "../../../utils/utilities.js";

const ListDrivers = () => {
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const [adminLogout] = useLazyAdminLogoutQuery(undefined, { refetchOnMountOrArgChange: true });
  const { data, isLoading, error } = useAdminGetAllDriversQuery();
  const [adminDeleteDriver, { data: deletedDriverData, error: deleteError, isLoading: isDeleteLoading, isSuccess: deletedDriverSuccess }] = useAdminDeleteDriverMutation();
  const [showingDeleteConfirmDriverIndex, showDeleteDriverConfirmDialog] = useState(-1);
  const navigate = useNavigate();

  /*
  useEffect(() => {
    localStorage.setItem('lastVisitedPage', window.location.pathname);
  }, []);
  */
  
  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error?.status === 401) {
        adminLogout();
      }
    }

    if (deleteError) {
      toast.error(deleteError?.data?.message);
    }

    if (deletedDriverSuccess) {
      toast.success(`Driver ${deletedDriverData.driverName} deleted`);
    }
  }, [error, deleteError, deletedDriverSuccess]);

  const confirmDeletingDriver = () => {
    if (showingDeleteConfirmDriverIndex >= 0) {
      adminDeleteDriver(data[showingDeleteConfirmDriverIndex]._id);
      showDeleteDriverConfirmDialog(-1);
    }
  }

  const showDriverDeliveryRecord = (driverId) => {
    navigate(appendAppVersionToUrl(`/admin/drivers/${driverId}/deliveries`));
  }

  const setDrivers = () => {
    const drivers = {
      columns: [
        {
          label: <CenterAlignedCell value={"ID"}> </CenterAlignedCell>,
          field: "id",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Name"}> </CenterAlignedCell>,
          field: "name",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Status"}> </CenterAlignedCell>,
          field: "status",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };

    const canDelete = CanDeleteDriver(user);
    const canUpdate = CanUpdateDriver(user);
    const canViewDeliveryRecord = user.role === USER_ROLES.SUPER_ADMIN || user.role === USER_ROLES.ADMIN;

    data?.forEach((driver, index) => {
      drivers.rows.push({
        id: index + 1,
        name: driver?.name,
        status: String(driver?.status).toUpperCase(),
        actions: (
          <>
            {canUpdate && (
              <Link
                to={appendAppVersionToUrl(`/admin/drivers/${driver?._id}`)}
                className="btn btn-outline-primary"
              >
                <i className="fa fa-pencil"></i>
              </Link>
            )}
            {canDelete && (
              <button
                className="btn btn-outline-danger ms-2"
                onClick={() => showDeleteDriverConfirmDialog(index)}
                disabled={isDeleteLoading}
              >
                <i className="fa fa-trash"></i>
              </button>)
            }
            {canViewDeliveryRecord && (
              <button
                className="btn btn-outline-primary ms-2"
                onClick={() => showDriverDeliveryRecord(driver._id)}
              >
                <i className="fa fa-book"></i>
              </button>)
            }
          </>
        ),
      });
    });

    return drivers;
  };

  // Create new driver
  const createNewDriverHandler = () => {
    // Redirect to create new driver page
    navigate(appendAppVersionToUrl("/admin/drivers/new"));
  }
  if (isLoading || loadingAdmin) return <Loader />;

  const canCreateDriver = CanCreateDriver(user);

  return (
    <>
      <MetaData title={"All Drivers"} />
      <HeaderAdmin title={"All Drivers"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.DRIVERS.name}>
      <div className="row my-4 mx-2">
        <div className="col-4 col-lg-4">
          <h1>{data?.length} Drivers</h1>
        </div>
        <div className="col-2 offset-6 text-end">
          {/*New Order Button - rigjht */}
          {canCreateDriver && (
          <button 
            onClick={() => {createNewDriverHandler();}} 
            className="btn btn-primary"
            style={{width: '100%'}}>
            Create New Driver
          </button>)}             
        </div>
      </div>  

      <div className="col-10">
        <MDBDataTable 
          data={setDrivers()} 
          className="px-3 myMDBDataListDriverTableRow" 
          bordered striped hover 
          noBottomColumns
        />
      </div>
      <ConfirmDialog message={`Are you sure to delete this driver, ${showingDeleteConfirmDriverIndex >=0 ? data[showingDeleteConfirmDriverIndex].name : ""} ?`} show={showingDeleteConfirmDriverIndex >= 0} confirm={()=>{confirmDeletingDriver()}} cancel={()=> {showDeleteDriverConfirmDialog(-1)}}> </ConfirmDialog>
      </AdminLayout>
    </>
  );
};

export default ListDrivers;
