import React, { useEffect,useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLazyAdminLogoutQuery, useLazyAdminQuickbooksLogoutQuery } from "../../redux/api/adminAuthApi";
import { updateLogTimestamp, setLogsData } from "../../redux/features/adminSlice";
import { MAX_NUM_OF_DISPLAYED_LOGS } from "../../constants/constants.js";
import { appendAppVersionToUrl } from "../../utils/utilities.js";

const HeaderAdmin = ({ title, bg_color }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, logsData, isAuthenticatedAdmin } = useSelector((state) => state.adminAuth);
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [adminQuickbooksLogout] = useLazyAdminQuickbooksLogoutQuery();

  const [isLogOpen, setIsLogOpen] = useState(false);

  useEffect(() => {
    if (!isAuthenticatedAdmin) {
      navigate(appendAppVersionToUrl('/admin'));
    }
  }, [isAuthenticatedAdmin]);


  return (
    <div className={`navbar row border `} style={{width: '100%', backgroundColor: process.env.REACT_APP_NODE_ENV === 'PRODUCTION' ? bg_color : 'red'}}>
      <div className="custom-sidebar">
        <div className="navbar-brand">
          <Link to={appendAppVersionToUrl("/admin")}>
            <img src="/images/TomProduce_logo_transparent.png" alt="Tomproduce Logo" style={{ height: '10vh', width: 'auto' }}/>
          </Link>
          
        </div>
        <div>
          <span style={{ fontSize: '80%', color: 'gray' }}>
          {`${process.env.REACT_APP_NODE_ENV === 'PRODUCTION' ? '' : 'Test' } v${process.env.REACT_APP_VERSION}`}
          </span>
        </div>
      </div>

      <div className="custom-content row" style={{width: '100%'}}>
        <div className="col-7 text-center d-flex align-items-center">
          <h1 className="text-black">{process.env.REACT_APP_NODE_ENV === 'PRODUCTION' ? title : 'THIS IS TEST SERVER - YOU SHOULD GO TO https://tomproduce.com/admin'}</h1>
        </div>
        
        {/* User button */}
        <div className="col-2 text-end d-flex align-items-center justify-content-end">
          {user && (
            <div className="ms-4 dropdown">
              <button
                className="btn dropdown-toggle text-black"
                type="button"
                id="dropDownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <figure className="avatar avatar-nav">
                  <img
                    src={
                      user?.avatar
                        ? user?.avatar?.url
                        : "/images/default_avatar.jpg"
                    }
                    alt="User Avatar"
                    className="rounded-circle"
                  />
                </figure>
                <span>{user?.name}</span>
              </button>
              <div
                className="dropdown-menu w-100"
                aria-labelledby="dropDownMenuButton"
              >
                <Link className="dropdown-item" to={appendAppVersionToUrl("/admin/profile")}>
                  {" "}
                  Profile{" "}
                </Link>
                <Link
                  className="dropdown-item text-danger"
                  onClick={() => {adminLogout(); adminQuickbooksLogout();}}
                >
                  Logout{" "}
                </Link>
              </div>
            </div>
          )}
        </div>

        {/* Log button */}
        <div className="col-2 text-end d-flex align-items-center justify-content-end">
          { user && isAuthenticatedAdmin &&  (
            <div className="text-end">
              { isLogOpen && (
                <button 
                  className="btn btn-danger clear-button" 
                  onClick={() => { 
                    dispatch(setLogsData([]));
                    dispatch(updateLogTimestamp(logsData.length > 0 ? logsData[logsData.length - 1].createdAt : 0)); 
                    setIsLogOpen(false);
                  }}
                  style={{
                    fontSize: '0.75rem', // Smaller font size
                    padding: '0.25rem 0.5rem', // Reduced padding
                    border: '1px solid', // Adjust border as needed
                  }}
                >
                  Clear
                </button>
              )}
              {` ${logsData ? (logsData.length >= MAX_NUM_OF_DISPLAYED_LOGS ? `${MAX_NUM_OF_DISPLAYED_LOGS}+` : logsData.length) : 0}`}
              <button 
                className="btn dropdown-toggle text-black" 
                onClick={() => { 
                  if (logsData && logsData.length > 0) {
                    setIsLogOpen(!isLogOpen)
                  }
                }}
              >
                <i className="fas fa-bell" style = {{color: 'red', fontSize: '24px' }}></i>
              </button>
              { isLogOpen && (
                <div className="ms-4 logs" style={{ position: 'relative' }}>
                  <div className="notification-container" >
                    {logsData?.slice().reverse().map((log, index) => (
                      <div key={index} className="log-row" style={{ padding: '10px', borderBottom: '1px solid #eee' }}>
                        {new Date(log.createdAt).toLocaleString()} - {log.note}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderAdmin;
