
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_MENU_ITEMS, USER_ROLES } from "../../constants/constants.js";

import { setAdminOptions } from "../../redux/features/adminOptionsSlice";
import { appendAppVersionToUrl } from "../../utils/utilities.js";
import { ConfirmDialog } from "../admin/ConfirmDialog.jsx";
import { use } from "react";

const SideMenu = ({ menuItems, activeMenu }) => {
  const { adminOptions } = useSelector((state) => state.adminOptions);

  const {user, unviewedNewOrder, unviewedUpdatedOrder, unviewedNewPurchase, unviewedUpdatedPurchase} = useSelector((state) => state.adminAuth);

  const [newIndicator, setNewIndicator] = useState({});
  const [updateIndicator, setUpdateIndicator] = useState({});

  const [showingPendinChangesPopup, setShowingPendinChangesPopup] = useState(false);
  const [nextMenuItem, setNextMenuItem] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    const totalNewOrder = Object.values(unviewedNewOrder).reduce((acc, currentValue) => acc + currentValue, 0);
    const totalNewPurchase = Object.values(unviewedNewPurchase).reduce((acc, currentValue) => acc + currentValue, 0);
    setNewIndicator(
      {
        ...newIndicator, 
        [ADMIN_MENU_ITEMS.ORDERS.id]: totalNewOrder, 
        [ADMIN_MENU_ITEMS.PURCHASES.id]: totalNewPurchase,
      }
    )

    const totalUpdatedOrder = Object.values(unviewedUpdatedOrder).reduce((acc, currentValue) => acc + currentValue, 0);
    const totalUpdatedPurchase = Object.values(unviewedUpdatedPurchase).reduce((acc, currentValue) => acc + currentValue, 0);
    setUpdateIndicator(
      {
        ...updateIndicator, 
        [ADMIN_MENU_ITEMS.ORDERS.id]: totalUpdatedOrder, 
        [ADMIN_MENU_ITEMS.PURCHASES.id]: totalUpdatedPurchase,
      }
    )
  }, [unviewedNewOrder, unviewedUpdatedOrder, unviewedNewPurchase, unviewedUpdatedPurchase]);

  // when loading the page, clear pending changes
  useEffect(() => {
    // clear pending changes
    if (adminOptions.thereArePendingChanges) {
      const newAdminOptions = {...adminOptions, thereArePendingChanges: false};
      dispatch(setAdminOptions(newAdminOptions));
    }
  }, []);

  const handleClick = (event, isDisabled, menuItem) => {
    event.preventDefault();
    if (isDisabled) {
      return;
    }

    if (adminOptions.thereArePendingChanges) {
      setShowingPendinChangesPopup(true);
      setNextMenuItem(menuItem);
    } else {
      navigate(appendAppVersionToUrl(menuItem.link));
    }
  };

  const navigateToNextMenu = () => {
    if (nextMenuItem) {
      const newAdminOptions = {...adminOptions, thereArePendingChanges: false};
      dispatch(setAdminOptions(newAdminOptions));
      navigate(appendAppVersionToUrl(nextMenuItem.link));
    }
  };

  return (
    <div className="list-group mt-5 pl-4">
      
      {menuItems?.map((menuItem, index) => {
        const isDisabled = (!menuItem.roles || !user || !menuItem.roles.includes(user.role)) && !menuItem.roles.includes(USER_ROLES.ALL);
        return(
          <div
            key={index}
            className={`fw-bold list-group-item list-group-item-action ${
              menuItem.name === activeMenu ? "active" : ""
            } ${isDisabled ? "disabled-text" : ""}`}
            aria-current={menuItem.name === activeMenu ? "true" : "false"}
            tabIndex={isDisabled ? -1 : 0}
            onClick={(event) => handleClick(event, isDisabled, menuItem)}
          >
            <div className="menu-item d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i className={`${menuItem.icon} fa-fw pe-2`} style={{marginRight: '4px'}}></i> {menuItem.name}
              </div>
              <div className="d-flex align-items-center">
                {newIndicator && newIndicator[menuItem.id] !== null && newIndicator[menuItem.id] !== undefined && newIndicator[menuItem.id] > 0 && (
                  <span className="new-items-indicator">{`${newIndicator[menuItem.id]} New`}</span>
                )}
                
                {updateIndicator && updateIndicator[menuItem.id] !== null && updateIndicator[menuItem.id] !== undefined && updateIndicator[menuItem.id] > 0 && (
                  <span className="updated-items-indicator">{`${updateIndicator[menuItem.id]} Updates`}</span>
                )}
              </div>
            </div>
          </div>
        );
      })}

      <ConfirmDialog message={`There are pending changes. Are you sure to leave?`} show={showingPendinChangesPopup} confirm={()=>{setShowingPendinChangesPopup(false); navigateToNextMenu(); }} cancel={()=> {setShowingPendinChangesPopup(false)}}> </ConfirmDialog>    
    </div>
  );
};

export default SideMenu;
