import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import MetaData from "../../layout/MetaData";
import Loader from "../../layout/Loader";
import AdminLayout from "../../layout/AdminLayout";
import {
  useAdminGetCustomerDetailsQuery,
  useAdminUpdateCustomerMutation,
  useAdminResetCustomerPasswordMutation,
  useAdminGetAllUsersQuery,
  useAdminForceResyncOneCustomerQuickbooksMutation
} from "../../../redux/api/adminApi";
import HeaderAdmin from "../../layout/HeaderAdmin";
import { ADMIN_MENU_ITEMS, CUSTOMER_STATUS, QUICKBOOKS_CATEGORY, QUICKBOOKS_STATUS, US_STATES } from "../../../constants/constants.js";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { validateAdminProfileForm, validateCustomerProfileForm, validateUserPassword } from "../../../utils/validators.js";
import QuickbooksIcon from "../../layout/QuickbooksIcon.jsx";

const UpdateCustomer = () => {
  const [name, setName] = useState("");
  const [info, setInfo] = useState("");
  const [note, setNote] = useState("");
  const [username, setUsername] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [tier, setTier] = useState("");
  const [addressInfo, setAddressInfo] = useState({
    address: '',
    billingAddress: ''
  });
  const [copyAddress, setCopyAddress] = useState(false);
  const [salesRep, setSalesRep] = useState("");

  //const navigate = useNavigate();
  const params = useParams();

  const { loadingAdmin } = useSelector((state) => state.adminAuth);
  const { data, isLoading } = useAdminGetCustomerDetailsQuery(params?.id, { refetchOnMountOrArgChange: true });
  const { data: allUserData, isLoading: isLoadingAllUserData, error: errorLoadingAllUserData } = useAdminGetAllUsersQuery();

  const [updateCustomer, { error, isSuccess, isLoadding: isUpdatingCustomer }] = useAdminUpdateCustomerMutation();
  const [AdminForceResyncOneCustomerQuickbooks, {data: forceSyncedData, isLoading: isForcingQBResync}] = useAdminForceResyncOneCustomerQuickbooksMutation();
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [clonedCustomerData, setClonedCustomerData] = useState(null);
  const [clonedUsername, setClonedUsername] = useState(null);
  const [formChanged, setFormChanged] = useState(false);
  const [QBStatus, setQBStatus] = useState(QUICKBOOKS_STATUS.NOT_SYNCED);

  // Reset password part
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [adminResetCustomerPassword, { data: dataResetCustomerPassword, error: errorResettingCustomerPassword, isSuccess: resettingDCustomerPasswordSuccess, isLoading: isResettingCustomerPassword }] = useAdminResetCustomerPasswordMutation();
  

  useEffect(() => {
    if (data?.customer) {
      setCustomerData(data.customer);
    }
  }, [data]);

  useEffect(() => {
    if (forceSyncedData?.customer) {
      setCustomerData(forceSyncedData.customer);
    }
  }, [forceSyncedData]);

  function setCustomerData(customer) {
    setName(customer.name);
    setInfo(customer.info);
    setNote(customer.note);
    setUsername(customer.username);
    setEmail(customer.email);
    setStatus(customer.status);
    setTier(customer.tier);
    setSalesRep(customer.salesRep);
    setAddressInfo(customer.addressInfo);
    setQBStatus(customer.QBStatus);
    setClonedUsername(customer.username);
    setClonedCustomerData(
    {
      name: customer.name, 
      info: customer.info,
      note: customer.note,
      email: customer.email, 
      status: customer.status, 
      tier: customer.tier,
      salesRep: customer.salesRep,
      addressInfo: {...customer.addressInfo} 
    });    
  }

  useEffect(() => {
    const newCustomerData = {
      name,
      info,
      note,
      email,
      status,
      tier,
      salesRep,
      addressInfo,
    };

    setFormChanged(!_.isEqual(newCustomerData, clonedCustomerData));
  }, [name, info, note, email, status, tier, salesRep, addressInfo, clonedCustomerData]);

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
      if (error.status === 401) {
        adminLogout();
      }
    }

    if (errorResettingCustomerPassword) {
      toast.error(errorResettingCustomerPassword?.data?.message);
      if (errorResettingCustomerPassword.status === 401) {
        adminLogout();
      }
    }

    if (isSuccess) {
      toast.success("Customer Updated");
      //navigate("/admin/customers");
    }

    if (resettingDCustomerPasswordSuccess) {
      toast.success("Customer password reset successfully");
      if (dataResetCustomerPassword) {
        setUsername(dataResetCustomerPassword.username);
        setClonedUsername(dataResetCustomerPassword.username);
      }
      setPassword("");
      setConfirmPassword("");
    }
  }, [error, errorResettingCustomerPassword, isSuccess, resettingDCustomerPasswordSuccess]);

  const handleForceResync = () => {
    AdminForceResyncOneCustomerQuickbooks(params?.id);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (formChanged) {
      const newCustomerData = {
        name,
        info,
        note,
        email,
        status,
        tier,
        salesRep,
        addressInfo,
      };
      const { formIsValid, formErrors } = validateCustomerProfileForm(newCustomerData);
      if (!formIsValid) {
        Object.values(formErrors).forEach((error) => {
          toast.error(error);
        });
        return;
      }

      updateCustomer({ id: params?.id, body: newCustomerData });
    }
  };

  const submitResetPasswordHandler = (e) => {
    e.preventDefault();
    const { passwordIsValid, passwordErrors } = validateUserPassword({ password, confirmPassword, username });
    if (!passwordIsValid) {
      Object.values(passwordErrors).forEach((error) => {
        toast.error(error);
      });
      return;
    }
    adminResetCustomerPassword({ id: params?.id, body: { username, password, confirmPassword } });
  };

  const updateAddressHandler = (e) => {
    const { name, value } = e.target;
    setAddressInfo({
        ...addressInfo,
        [name]: value
    });
  };

  const handleCopyAddressChange = (e) => {
    setCopyAddress(e.target.checked);
    if (e.target.checked) {
      setAddressInfo({
          ...addressInfo,
          billingAddress: addressInfo.address
      });
    }
  };

  if (isLoading || loadingAdmin) {
    return <Loader />;
  } 

  return (
    <>
      <MetaData title={"Update Customer"} />
      <HeaderAdmin title={"Update Customer"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.CUSTOMERS.name}>
        <div className="row wrapper">
          <div className="col-10 col-lg-8">
            <form className="shadow-lg" onSubmit={submitHandler}>
            <div className="mb-3">
              <div className="row">
                <div className="col-3">
                  <label htmlFor="status_field" className="form-label">
                    Status
                  </label>
                  <select
                    id="status_field"
                    className="form-select"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value={CUSTOMER_STATUS.ACTIVE}>{String(CUSTOMER_STATUS.ACTIVE).toUpperCase()}</option>
                    <option value={CUSTOMER_STATUS.PENDING}>{String(CUSTOMER_STATUS.PENDING).toUpperCase()}</option>
                    <option value={CUSTOMER_STATUS.INACTIVE}>{String(CUSTOMER_STATUS.INACTIVE).toUpperCase()}</option>
                  </select>
                </div>
                <div className="col-3">
                  <label htmlFor="status_field" className="form-label">
                    Tier
                  </label>
                  <select
                    id="status_field"
                    className="form-select"
                    name="status"
                    value={tier}
                    onChange={(e) => setTier(e.target.value)}
                  >
                    <option value={1}>Tier 1</option>
                    <option value={2}>Tier 2</option>
                    <option value={3}>Tier 3</option>
                  </select>
                </div>

                <div className="col-3">
                  <label htmlFor="name_field" className="form-label">
                    Sales Rep.
                  </label>
                  <select
                    id="salesrep_field"
                    className="form-select"
                    name="salesrep"
                    value={salesRep}
                    onChange={(e) => setSalesRep(e.target.value)}
                  >
                    <option value="">-None-</option> {/* Default empty option */}
                    {allUserData && allUserData.map((rep) => (
                      <option key={rep._id} value={rep._id}>
                        {rep.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="column col-3">
                  <div style={{verticalAlign: 'top'}}>
                    <QuickbooksIcon  qbCategory={QUICKBOOKS_CATEGORY.CUSTOMER} qbRef={params?.id}  QBStatus={QBStatus} function1={() => handleForceResync()}/>           
                  </div>      
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="name_field" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="name_field"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              {/* Extra Info */}
              <div className="mb-3">
                <label htmlFor="name_field" className="form-label">
                  Extra Info (synced with Quickbooks customer's Company)
                </label>
                <input
                  type="text"
                  id="info_field"
                  className="form-control"
                  name="info"
                  value={info}
                  onChange={(e) => setInfo(e.target.value)}
                />
              </div>

              {/* Note */}
              <div className="mb-3">
                <label htmlFor="name_field" className="form-label">
                 Note (not synced with Quickbooks)
                </label>
                <input
                  type="text"
                  id="note_field"
                  className="form-control"
                  name="note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>

              {/* Email */}
              <div className="mb-3">
                <label htmlFor="email_field" className="form-label">
                  Email (multiple emails are separated by comma)
                </label>
                <input
                  type="text"
                  id="email_field"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={(e) => {setEmail(e.target.value)}}
                />  
              </div>

              {/* Address */}
              <div className="mb-3">
                <label htmlFor="address_field" className="form-label">
                  Shipping Address
                </label>
                <input
                  type="text"
                  id="address_field"
                  className="form-control"
                  name="address"
                  value={addressInfo.address}
                  onChange={(e) => { updateAddressHandler(e) }}
                />
              </div>

              {/* Billing Address */}
              <div className="d-flex justify-content-between align-items-center">
                  <label htmlFor="address_field" className="form-label">
                    Billing Address
                  </label>
                  <div className="d-flex align-items-center">
                    <label className="form-check-label" htmlFor="copy_address_checkbox">
                        Copy Shipping Address to Billing Address
                    </label>
                    &nbsp;
                    <input
                        type="checkbox"
                        className="form-check-input me-2"
                        id="copy_address_checkbox"
                        checked={copyAddress}
                        onChange={handleCopyAddressChange}
                    />
                </div>
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  id="billing_address_field"
                  className="form-control"
                  name="billingAddress"
                  value={addressInfo.billingAddress}
                  onChange={(e) => { updateAddressHandler(e) }}
                  disabled={copyAddress} 
                />
              </div>

              <div className="row">
                {/* Phone Number */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="phone_field" className="form-label">
                    Phone
                  </label>
                  <input
                    type="tel"
                    id="phone_field"
                    className="form-control"
                    name="phone"
                    value={addressInfo.phone}
                    pattern="^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$"
                    onInvalid={(e) => {
                      e.target.setCustomValidity('Please enter a valid phone number');
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity('');
                    }}
                    onChange={(e) => { updateAddressHandler(e) }}
                  />
                </div>
                 {/* Fax Number */}
                 <div className="col-6 col-lg-6">
                  <label htmlFor="fax_field" className="form-label">
                    Fax
                  </label>
                  <input
                    type="tel"
                    id="fax_field"
                    className="form-control"
                    name="fax"
                    value={addressInfo.fax}
                    pattern="^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$"
                    onInvalid={(e) => {
                      e.target.setCustomValidity('Please enter a valid fax number');
                    }}
                    onInput={(e) => {
                      e.target.setCustomValidity('');
                    }}
                    onChange={(e) => { updateAddressHandler(e) }}
                  />
                </div>
              </div>
              <br />
              <button disabled={!formChanged || isUpdatingCustomer} type="submit" className="btn update-btn w-100 py-2">
                Update
              </button>
            </div>  
            </form>
          </div>

          {/* Reset Password */}
          <div className="col-10 col-lg-8">
            <form className="shadow-lg" onSubmit={submitResetPasswordHandler}>
            {/* Username */}
            <div className="mb-3">
              <label htmlFor="username_field" className="form-label">
                Username (leave blank to disable username login)
              </label>
              <input
                type="username"
                id="username_field"
                className="form-control"
                name="username_field_name"
                value={username}
                onChange={(e) => {setUsername(e.target.value)}}
                autoComplete="off"
              />  
            </div>
            <div className="row">
                {/* Password */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="password_field" className="form-label">
                    Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password_field"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}  
                    autoComplete="new-password"       
                  />
                  <p className="password-rule" style={{ color: "lightgray", fontWeight: "thin" }}>
                    Password must be at least 8 characters long
                  </p>
                </div>

                {/* Confirm Password */}
                <div className="col-6 col-lg-6">
                  <label htmlFor="confirm_password_field" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="confirm_password_field"
                    className="form-control"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}   
                    autoComplete="new-password" 
                  />
                </div>
              </div>
              <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
              </span>

              <button disabled={(isResettingCustomerPassword || isUpdatingCustomer || !password || !confirmPassword || password !== confirmPassword) && username === clonedUsername} type="submit" className="btn update-btn w-100 py-2">
                Reset Login Credential
              </button>              
            </form>
          </div>
        </div>
      </AdminLayout>
      </>
  );
};

export default UpdateCustomer;
