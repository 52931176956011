import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../layout/Loader";
import MetaData from "../../layout/MetaData";
import HeaderAdmin from "../../layout/HeaderAdmin";
import AdminLayout from "../../layout/AdminLayout";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { useSelector } from "react-redux";

import { useAdminPayDeliveryMutation, useAdminGetDriverDetailsQuery, useLazyAdminGetDriverDeliveriesQuery, useLazyAdminGetDriverPaymentsQuery, useAdminUpdateDriverDeliveryMutation, useAdminUpdateDriverPaymentMutation } from "../../../redux/api/adminApi";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { ConfirmDialog } from "../ConfirmDialog";
import { CanUpdateDriver } from "../AdminActionEligibilities";
import { CenterAlignedCell, EditableNumberCell, RightAlignedCell } from "../../layout/CustomMDBDataTableCell";

import { ADMIN_MENU_ITEMS, DRIVER_DELIVERY_STATUS, USER_ROLES } from "../../../constants/constants.js";
import Pagination from "react-js-pagination";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatInTimeZone } from "date-fns-tz";
import { appendAppVersionToUrl, convertToPST, getDateEndTimeInPST, getDateStartTimeInPST, getPSTStartOfDay } from "../../../utils/utilities.js";
import VerifiedIcon from "../../layout/VerifiedIcon.jsx";

const ListDriverDeliveries = () => {
  const navigate = useNavigate();

  // Get URL params
  const driverId = useParams().id;

  // Get search params
  const [ page, setPage ] = useState(1);
  const firstDayOfMonth = getPSTStartOfDay();
  firstDayOfMonth.setDate(1);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(getPSTStartOfDay());

  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const [adminLogout] = useLazyAdminLogoutQuery(undefined, { refetchOnMountOrArgChange: true });
  const { data: driverData, isLoading: isLoadingDriverDetails, error: errorLoadingDriverDetails } = useAdminGetDriverDetailsQuery(driverId);
  // Delivery hooks
  const [AdminGetDriverDeliveries, { isLoading: isLoadingDriverDeliveries, error: errorLoadingDriverDeliveries }] = useLazyAdminGetDriverDeliveriesQuery();
  const [adminUpdateDriverDelivery, { data: updatedDriverDeliveryData, isLoading: isUpdatingDriverDelivery, error: errorUpdatingDriverDelivery }] = useAdminUpdateDriverDeliveryMutation();
  const [adminPayDelivery, { data: payDeliveryData, isLoading: isPayingDeliveries, error: errorPayingDeliveries }] = useAdminPayDeliveryMutation();

  // Payment hooks
  const [AdminGetDriverPayments, { isLoading: isLoadingDriverPayments, error: errorLoadingDriverPayments }] = useLazyAdminGetDriverPaymentsQuery();
  const [adminUpdateDriverPayment, { data: updatedDriverPaymentData, isLoading: isUpdatingDriverPayment, error: errorUpdatingDriverPayment }] = useAdminUpdateDriverPaymentMutation();


  const [viewMode, setViewMode] = useState("unpaiddelivery"); // or payment

  // Delivery data
  const [driverDeliveriesData, setDriverDeliveriesData] = useState(null);
  const [deliveries, setDeliveries] = useState([]);
  const [clonedDeliveries, setClonedDeliveries] = useState([]);
  const [deliveryToUpdate, setDeliveryToUpdate] = useState(null);
  const [showConfirmPaymentPopup, setShowConfirmPaymentPopup] = useState(false);
  const [selectedDeliveries, setSelectedDeliveries] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentIdForSelectedDeliveries, setPaymentIdForSelectedDeliveries] = useState("");

  // Payment data
  const [driverPaymentData, setDriverPaymentData] = useState(null);
  const [payments, setPayments] = useState([]);
  const [clonedPayments, setClonedPayments] = useState([]);
  const [paymentToUpdate, setPaymentToUpdate] = useState(null);

  useEffect(() => {
    // Driver error handling
    if (errorLoadingDriverDetails) {
      toast.error(errorLoadingDriverDetails?.data?.message);
      if (errorLoadingDriverDetails.status === 401) {
        adminLogout();
      }
    }

    // Delivery error handling
    if (errorLoadingDriverDeliveries) {
      toast.error(errorLoadingDriverDeliveries?.message);
      if (errorLoadingDriverDeliveries?.status === 401) {
        adminLogout();
      }
    }

    if (errorUpdatingDriverDelivery) {
      toast.error(errorUpdatingDriverDelivery?.data?.message);
    }

    if (errorPayingDeliveries) {
      toast.error(errorPayingDeliveries?.data?.message);
    }

    // Payment error handling
    if (errorLoadingDriverPayments) {
      toast.error(errorLoadingDriverPayments?.message);
      if (errorLoadingDriverPayments?.status === 401) {
        adminLogout();
      }
    }

    if (errorUpdatingDriverPayment) {
      toast.error(errorUpdatingDriverPayment?.data?.message);
    }
  }, [errorLoadingDriverDetails, errorLoadingDriverDeliveries, errorUpdatingDriverDelivery, errorPayingDeliveries, errorLoadingDriverPayments, errorUpdatingDriverPayment]);

  // Handle delivery update success
  useEffect(() => {
    if (updatedDriverDeliveryData?.delivery) {
      setDeliveries((prevDeliveries) =>
        prevDeliveries.map((delivery) =>
          delivery._id === updatedDriverDeliveryData.delivery._id ? updatedDriverDeliveryData.delivery : delivery
        )
      );
      const updatedDeliveries = clonedDeliveries.map((delivery) =>
        delivery._id === updatedDriverDeliveryData.delivery._id ? updatedDriverDeliveryData.delivery : delivery
      );
      setClonedDeliveries(JSON.parse(JSON.stringify(updatedDeliveries)));
    }
  }, [updatedDriverDeliveryData]);

  // Handle payment update success
  useEffect(() => {
    if (updatedDriverPaymentData?.payment) {
      const updatePayment = updatedDriverPaymentData.payment;
      setPayments((prevPayments) =>
        prevPayments.map((payment) =>
          payment._id === updatePayment._id ? updatePayment : payment
        )
      );
      const updatedPayments = clonedPayments.map((payment) =>
        payment._id === updatePayment._id ? updatePayment : payment
      );
      setClonedPayments(JSON.parse(JSON.stringify(updatedPayments)));
    }
  }, [updatedDriverPaymentData]);

  useEffect(() => {
    if (payDeliveryData && payDeliveryData.success) {
      setSelectedDeliveries([]);
      setTotalAmount(0);
      setPaymentIdForSelectedDeliveries("");
      toast.success("Payment successful");

      // Refetch the data
      fetchDriverDeliveries(page);
    }
  }, [payDeliveryData]);

  function confirmUpdatingDelivery(deliveryId) {
    const index = clonedDeliveries.findIndex((delivery) => delivery._id === deliveryId);
    adminUpdateDriverDelivery({deliveryId: clonedDeliveries[index]._id, body: {paymentId: clonedDeliveries[index].paymentId, amount: clonedDeliveries[index].amount}});
    setDeliveryToUpdate(null);
  }

  function confirmUpdatingPayment(aPayment) {
    if (aPayment) {
      adminUpdateDriverPayment({paymentRowId: aPayment._id, body: { paymentId: aPayment.paymentId, amount: aPayment.amount }});
    }
    setPaymentToUpdate(null);
  }

  function fetchDriverDeliveries(searchPage) {
    const timestamp = new Date().getTime();
    //console.log('fetchDriverDeliveries', searchPage, startDate, endDate, timestamp);
    const _startDate = getDateStartTimeInPST(startDate);
    const _endDate = getDateEndTimeInPST(endDate);

    // Call the API
    AdminGetDriverDeliveries({driverId, params: { page: searchPage, startDate: _startDate, endDate: _endDate, _: timestamp, paid: viewMode === 'unpaiddelivery' ? false : true }})
      .then((response) => {
        // Update state with the fetched data
        if (response.data) {
          setDriverDeliveriesData(response.data);
          setDeliveries(response.data.deliveries);
          setClonedDeliveries(JSON.parse(JSON.stringify(response.data.deliveries)));
        }
      })
      .catch((error) => {
        console.error('Error fetching deliveries:', error);
      });
  }

  function fetchDriverPayments(searchPage) {
    const timestamp = new Date().getTime();
    //console.log('fetchDriverDeliveries', searchPage, startDate, endDate, timestamp);
    const _startDate = getDateStartTimeInPST(startDate);
    const _endDate = getDateEndTimeInPST(endDate);

    // Call the API
    AdminGetDriverPayments({driverId, params: { page: searchPage, startDate: _startDate, endDate: _endDate, _: timestamp  }})
      .then((response) => {
        console.log('fetchDriverPayments', response.data);
        // Update state with the fetched data
        if (response.data) {
          setDriverPaymentData(response.data);
          setPayments(response.data.payments);
          setClonedPayments(JSON.parse(JSON.stringify(response.data.payments)));

          console.log('fetchDriverPayments', response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching deliveries:', error);
      });
  }

  function setCurrentPageNo(pageNumber) {
    setPage(pageNumber);
    if (viewMode === 'payment') {
      fetchDriverPayments(pageNumber);
    } else {
      fetchDriverDeliveries(pageNumber);
    }
  }

  // ----------- On delivery page load -------------------------
  function handleDeliveryPaymentAmountChange(index, value) {
    const newDeliveries = [...clonedDeliveries];
    newDeliveries[index].amount = Number(value);
    setClonedDeliveries(newDeliveries);
  }

  const handleOrderRowClick = (orderId) => {
    navigate(appendAppVersionToUrl(`/admin/orders/${orderId}`));
  };

  const handleCheckboxChange = (deliveryId) => {
    setSelectedDeliveries((prevSelected) => {
      let newSelected;
      if (prevSelected.includes(deliveryId)) {
        newSelected = prevSelected.filter((id) => id !== deliveryId);
      } else {
        newSelected = [...prevSelected, deliveryId];
      }
      const newTotal = newSelected.reduce((sum, id) => {
        const delivery = deliveries.find(delivery => delivery._id === id);
        return sum + (delivery?.amount ? delivery.amount : 0);
      }, 0);
      setTotalAmount(newTotal);
      return newSelected;
    });
  };

  // -------------------- Handle payment on Delivery page load ------------------------
  const handlePaymentClick = () => {
    setShowConfirmPaymentPopup(true);
  }

  const handleConfirmPayment= () => {
    adminPayDelivery({ body: { driverId, deliveries: selectedDeliveries, paymentId: paymentIdForSelectedDeliveries, amount: totalAmount } });
    setShowConfirmPaymentPopup(false);
  };
  // --------------------- Handle payment page view ----------------------------
  function handlePaymentIdUpdate(index, val) {
    const newPayments = [...clonedPayments];
    newPayments[index].paymentId = val.trim();

    setClonedDeliveries(newPayments);
  }

  function handlePaymentAmountUpdate(index, val) {
    // trim leading and trailing spaces
    const newPayments = [...clonedPayments];
    newPayments[index].amount = Number(val);

    setClonedDeliveries(newPayments);
  }
  //----------------------------------------------------------------------------------

  const handleViewModeSelect = (mode) => {
    setViewMode(mode);
    setPage(1);

    setPayments([]);
    setClonedPayments([]);
    setPaymentToUpdate(null);
    setPaymentIdForSelectedDeliveries("");

    setDeliveries([]);
    setClonedDeliveries([]);
    setSelectedDeliveries([]);
    setTotalAmount(0);
    setDeliveryToUpdate(null);
  };

  // ---------------------------------------------------

  const canUpdate = CanUpdateDriver(user);

  const setDeliveriesData = () => {
    const columns = [
      {
        label: <CenterAlignedCell value={"ID"}> </CenterAlignedCell>,
        field: "id",
      },
      {
        label: <CenterAlignedCell value={"Date"}> </CenterAlignedCell>,
        field: "date",
      },
      {
        label: <CenterAlignedCell value={"Order #"}> </CenterAlignedCell>,
        field: "orderNumber",
      },
      {
        label: <CenterAlignedCell value={"Customer"}> </CenterAlignedCell>,
        field: "customerName",
      },
      {
        label: <CenterAlignedCell value={"Verified"}> </CenterAlignedCell>,
        field: "verified",
      },
      {
        label: <CenterAlignedCell value={"Pallet"}> </CenterAlignedCell>,
        field: "pallet",
      },
      {
        label: <CenterAlignedCell value={"Payment Amount"}> </CenterAlignedCell>,
        field: "amount",
      },   
      // Conditionally render the "Payment #" column
      ...(viewMode === "paiddelivery" ? [{
        label: <CenterAlignedCell value={"Payment #"}> </CenterAlignedCell>,
        field: "paymentId",
      }] : []),   
      // Conditionally render the "Payment #" column
      ...(viewMode === "unpaiddelivery" ? [{
        label: <CenterAlignedCell value={"Pay Select"}> </CenterAlignedCell>,
        field: "selected",
      }] : []),   
      {
        label: <CenterAlignedCell value={"Action"}> </CenterAlignedCell>,
        field: "action",
      },   
    ];
    const deliveriesTable = {
      columns,
      rows: [],
    };

    const startIndex = driverDeliveriesData?.resPerPage && page ? (page - 1) * driverDeliveriesData?.resPerPage : 0;

    clonedDeliveries?.forEach((delivery, index) => {
      const date = formatInTimeZone(convertToPST(new Date(delivery.date)), 'America/Los_Angeles', 'MM/dd/yyyy');
      const disablePayment = delivery.amount === null || delivery.amount === undefined || delivery.amount === '';

      const verified = delivery.verified;

      const row = {
        id: <RightAlignedCell value={index + startIndex + 1} />,
        orderNumber: delivery.orderNumber,
        customerName: delivery.customer,
        pallet: <CenterAlignedCell value={delivery.pallet} />,
        date: <CenterAlignedCell value={date} />,
        paymentId: delivery.paymentId,
        amount: <EditableNumberCell value={delivery.amount} color={clonedDeliveries[index].amount === deliveries[index].amount ? 'black' : 'red'} type={'text'} onClick={(event) => event.stopPropagation()} onChange={(val) => {handleDeliveryPaymentAmountChange(index, val)}}/>,
        verified: verified  ? 
          < VerifiedIcon verified={ true } /> :
          '',
        selected: disablePayment ? '' : (
                  <CenterAlignedCell 
                    value={
                      <input type="checkbox" 
                        checked={selectedDeliveries.includes(delivery._id)} 
                        onClick={(event) => event.stopPropagation()} 
                        onChange={() => handleCheckboxChange(delivery._id)}
                        style={{
                          height: '1.5em', // Adjust this value as needed
                          width: '1.5em',  // Maintain aspect ratio
                        }}
                      />
                    } 
                  />),
        action: canUpdate && (
          <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              className="btn btn-primary"
              disabled={clonedDeliveries[index].amount === deliveries[index].amount && clonedDeliveries[index].paymentId === deliveries[index].paymentId}
              onClick={(event) => {
                setDeliveryToUpdate(delivery._id);
              }}
              style={{
                width: '2.5em', // Set width to make the button square
                height: '2.5em', // Set height to make the button square
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px' // Add some margin to separate the buttons
              }}
            >
              <i className="fas fa-save"></i>
            </button>
            <button
              className="btn btn-success"
              onClick={(event) => {
                handleOrderRowClick(delivery.order.toString());
              }}
              style={{
                width: '2.5em', // Set width to make the button square
                height: '2.5em', // Set height to make the button square
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px' // Add some margin to separate the buttons
              }}
            >
              <i className="fas fa-edit"></i>
            </button>
          </div>
        )
      };
      deliveriesTable.rows.push(row);
    });
    return deliveriesTable;
  };

  const setPaymentData = () => {
    const columns = [
      {
        label: <CenterAlignedCell value={"ID"}> </CenterAlignedCell>,
        field: "id",
      },
      {
        label: <CenterAlignedCell value={"Date"}> </CenterAlignedCell>,
        field: "date",
      },
      {
        label: <CenterAlignedCell value={"Invoices"}> </CenterAlignedCell>,
        field: "invoices",
      },
      {
        label: <CenterAlignedCell value={"Payment Amount"}> </CenterAlignedCell>,
        field: "amount",
      },   
      {
        label: <CenterAlignedCell value={"Payment #"}> </CenterAlignedCell>,
        field: "paymentId",
      },        
      {
        label: <CenterAlignedCell value={"Action"}> </CenterAlignedCell>,
        field: "action",
      },      
    ];
    const paymentTable = {
      columns,
      rows: [],
    };

    const startIndex = driverPaymentData?.resPerPage && page ? (page - 1) * driverPaymentData?.resPerPage : 0;

    clonedPayments?.forEach((payment, index) => {
      const date = formatInTimeZone(convertToPST(new Date(payment.date)), 'America/Los_Angeles', 'MM/dd/yyyy');

      const row = {
        id: <RightAlignedCell value={index + startIndex + 1} />,
        date: <CenterAlignedCell value={date} />,
        invoices: payment.deliveries,
        paymentId: <EditableNumberCell value={payment.paymentId} color={payment.paymentId === payments[index].paymentId ? 'black' : 'red'} type={'text'} onClick={(event) => event.stopPropagation()} onChange={(val) => {handlePaymentIdUpdate(index, val)}}/>,
        amount: <EditableNumberCell value={payment.amount} color={payment.amount === payments[index].amount ? 'black' : 'red'} type={'text'} onClick={(event) => event.stopPropagation()} onChange={(val) => {handlePaymentAmountUpdate(index, val)}}/>,
        action: canUpdate && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              className="btn btn-primary"
              disabled={payment.amount === payments[index].amount && payment.paymentId === payments[index].paymentId}
              onClick={(event) => {
                setPaymentToUpdate(payment);
                event.stopPropagation();
              }}
              style={{
                width: '2.5em', // Set width to make the button square
                height: '2.5em', // Set height to make the button square
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px' // Add some margin to separate the buttons
              }}
            >
              <i className="fas fa-save"></i>
            </button>
          </div>)
      };
      paymentTable.rows.push(row);
    });
    return paymentTable;
  };

  if (isLoadingDriverDetails || loadingAdmin) return <Loader />;

  // Delivery support data
  let deliveryToUpdateString = "";
  let selectedOrderNumbers = "";
  if (deliveries) {
    deliveryToUpdateString = deliveryToUpdate ? `#${deliveries.find((delivery) => delivery._id === deliveryToUpdate).orderNumber}` : "";
  }  
  if (selectedDeliveries && selectedDeliveries.length > 0) {
    selectedOrderNumbers = selectedDeliveries.map(id => {
      const delivery = deliveries.find(delivery => delivery._id === id);
      return delivery ? delivery.orderNumber : '';
    }).join(', ');
  }

  // Payment support data
  let paymentToUpdateString = "";;
  if (payments) {
    paymentToUpdateString = paymentToUpdate ? `#${paymentToUpdate.paymentId}` : "";
  }  

  return (
    <>
      <MetaData title={"Driver Deliveries"} />
      <HeaderAdmin title={"Driver Deliveries"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.DRIVERS.name}>
      <div className="col-12 align-items-center">
        {/* Driver details */}
        <div className="row my-4">
          <div className="col-8">
            <h1>{driverData.driver.name}</h1>
          </div>
          <div className=" column col-3">
            <text className="text-start" style={{ display: 'block', width: '100%' }}>View By</text>
            <select
              width="25%"
              id="type_field"
              className="form-select"
              name="salesRepFilter"
              value={viewMode}  
              onChange={(e) => handleViewModeSelect(e.target.value)}
            >
              <option key= 'unpaiddelivery' value={'unpaiddelivery'}>Unpaid Delivery</option>
              <option key= 'paiddelivery' value={'paiddelivery'}>Paid Delivery</option>
              <option key= 'payment' value={'payment'}>Payment</option>
            </select>
          </div>
        </div>  
        {/* Date range */}
        <div className="row col-11">
          <div className="col-2">
            <label className="form-label d-block">Start Date</label>
            <DatePicker
              style={{ width: "100%" }}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              className="form-control"
            />
          </div>
          <div className="col-2">
            <label className="form-label d-block">End Date</label>
            <DatePicker
              style={{ width: "100%" }}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              className="form-control"
            />
          </div>
          <div className="col-2 justify-content-center">
            <label className="form-label d-block">&nbsp;</label>
              <button
                className="btn w-100 fetch-btn"
                onClick={() => setCurrentPageNo(1)}
              >
                Fetch
              </button>
          </div>
        </div>

        {/* View by Delivery */}
        {viewMode === 'unpaiddelivery' && (
          <div className="row col-11">
            <div className="col-2">
              <label className="form-label d-block">Selected Total</label>
              <h3>{`$${totalAmount} `}</h3>
            </div>
            <div className="col-2">
              <label className="form-label d-block">Payment</label>
              <input
                type="text"
                className="form-control"
                value={paymentIdForSelectedDeliveries}
                onChange={(e) => setPaymentIdForSelectedDeliveries(e.target.value)}
              />
            </div>
            <div className="col-2 justify-content-center">
              <label className="form-label d-block">&nbsp;</label>
              <button
                  style={{ width: "100%" }}
                  className="btn btn-primary"
                  onClick={() => { handlePaymentClick() }}
                >
                Pay
              </button>
            </div>
          </div>
        )}
        {viewMode !== 'payment' && (
          <div className="col-12">
            {/* Loader */}
            {(isLoadingDriverDeliveries || isUpdatingDriverDelivery || isPayingDeliveries) && (
              <div style={{position: 'relative', height: '100%', width: '100%'}}>
                <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                  <Loader />
                </div>
              </div>
            )}
            <MDBDataTable 
              data={setDeliveriesData()} 
              className="px-3 myMDBDataListDriverPaymentsTableRow" 
              bordered striped hover 
              noBottomColumns
              paging={false}
            />
            {/* Pagination */}
            {driverDeliveriesData && driverDeliveriesData.resPerPage < driverDeliveriesData.totalDeliveryCount && (
              <div className="d-flex col-11 justify-content-center">
                <Pagination
                  activePage={page}
                  itemsCountPerPage={Number(driverDeliveriesData.resPerPage)}
                  totalItemsCount={Number(driverDeliveriesData.totalDeliveryCount)}
                  onChange={setCurrentPageNo}
                  nextPageText={"Next"}
                  prevPageText={"Prev"}
                  firstPageText={"First"}
                  lastPageText={"Last"}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            )}
          
            <ConfirmDialog message={`Are you sure to update delivery for order ${deliveryToUpdateString}?`} show={deliveryToUpdate !== null} confirm={()=>{confirmUpdatingDelivery(deliveryToUpdate)}} cancel={()=> {setDeliveryToUpdate(null)}}> </ConfirmDialog>
            <ConfirmDialog
                message={`Are you sure you want to process payment for the selected deliveries with order numbers: ${selectedOrderNumbers}?`}
                show={showConfirmPaymentPopup}
                confirm={() => handleConfirmPayment()}
                cancel={() => setShowConfirmPaymentPopup(false)}
            />
          </div>
        )}
        {/* ----------------------------- View by Payment --------------------------------------*/}
        {viewMode === 'payment' && (
          <div className="col-12">
            <div className="col-11">
              {/* Loader */}
              {(isLoadingDriverPayments || isUpdatingDriverPayment ) && (
                <div style={{position: 'relative', height: '100%', width: '100%'}}>
                  <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 6}}>
                    <Loader />
                  </div>
                </div>
              )}
              <MDBDataTable 
                data={setPaymentData()} 
                className="px-3 myMDBDataListDriverPaymentsTableRow" 
                bordered striped hover 
                noBottomColumns
                paging={false}
              />
            </div>
            {/* Pagination */}
            {driverPaymentData && driverPaymentData.resPerPage < driverPaymentData.allPaymentCount && (
              <div className="d-flex col-11 justify-content-center">
                <Pagination
                  activePage={page}
                  itemsCountPerPage={Number(driverPaymentData.resPerPage)}
                  totalItemsCount={Number(driverPaymentData.allPaymentCount)}
                  onChange={setCurrentPageNo}
                  nextPageText={"Next"}
                  prevPageText={"Prev"}
                  firstPageText={"First"}
                  lastPageText={"Last"}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            )}
          
            <ConfirmDialog message={`Are you sure to update payment ${paymentToUpdateString}?`} show={paymentToUpdate !== null} confirm={()=>{confirmUpdatingPayment(paymentToUpdate)}} cancel={()=> {setPaymentToUpdate(null)}}> </ConfirmDialog>
          </div>
        )}
      </div>
      </AdminLayout>
    </>
  );
};

export default ListDriverDeliveries;
