import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import _ from "lodash";

import MetaData from "../../layout/MetaData";
import Loader from "../../layout/Loader";
import AdminLayout from "../../layout/AdminLayout";
import HeaderAdmin from "../../layout/HeaderAdmin";
import {
  useAdminGetOrdersByProductQuery,
  useAdminUpdateOrdersByProductMutation,
} from "../../../redux/api/orderAdminApi";
import { useAdminGetProductDetailsQuery } from "../../../redux/api/productsAdminApi";
import { useAdminGetInventoryQuery } from "../../../redux/api/inventoriesAdminApi";
import { ADMIN_MENU_ITEMS, ORDER_STATUS, ORDER_USER_RIGHTS } from "../../../constants/constants.js";
import { CanUserCreateOrder, CanUserUpdateInProgressOrder, DetermineUserOrderRights } from "../AdminActionEligibilities";
import { useLazyAdminLogoutQuery } from "../../../redux/api/adminAuthApi";
import { CenterAlignedCell, EditableNumberCell, RightAlignedCell } from "../../layout/CustomMDBDataTableCell";
import { valueIsANonNegativeNumber } from "../../../utils/validators";
import { appendAppVersionToUrl } from "../../../utils/utilities.js";

const ListOrdersByProduct = () => {
  const params = useParams();
  const productId = params?.id;

  const navigate = useNavigate();

  // Redux
  const { loadingAdmin, user } = useSelector((state) => state.adminAuth);
  const { inventory } = useSelector((state) => state.inventory);

  // This query retries full orders' details by product id - more complete than inventoryDataOrdersByProduct
  const { data: ordersByProductData, isLoading: isLoadingOrders, error: errorLoadingOrders } = useAdminGetOrdersByProductQuery(productId, { refetchOnMountOrArgChange: true });
  const [adminUpdateOrdersByProductMutation, { data: ordersUpdatedData, isLoading: isUpdatingOrders, error: ordersUpdateError, isSuccess: ordersUpdateSuccess }] = useAdminUpdateOrdersByProductMutation();
  const { data: productDetails, isLoading: isLoadingProductDetails } = useAdminGetProductDetailsQuery(productId);
  const {isLoading: isLoadingInventory } = useAdminGetInventoryQuery();
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [ordersData, setOrdersData] = useState([]);
  const [clonedOrdersData, setClonedOrdersData] = useState([]);
  const [formUpdated, setFormUpdated] = useState(false);
  const [thereAreValueChanges, setThereAreValueChanges] = useState(false);

  // Fetch orders by productId
  useEffect(() => {
    if (ordersByProductData) {
      setOrdersData(ordersByProductData);
      setClonedOrdersData(JSON.parse(JSON.stringify(ordersByProductData)));
    }
  }, [ordersByProductData]);

  // Refresh orders after update
  useEffect(() => {
    if (ordersUpdatedData) {
      //console.log(ordersUpdatedData)
      setOrdersData(ordersUpdatedData);
      setClonedOrdersData(JSON.parse(JSON.stringify(ordersUpdatedData)));
    }
  }, [ordersUpdatedData]);

  // Error loading orders
  useEffect(() => {
    if (errorLoadingOrders) {
      toast.error(errorLoadingOrders?.data?.message);
      if (errorLoadingOrders.status === 401) {
        adminLogout();
      }
    }
  }, [errorLoadingOrders]);

  // Error updating orders
  useEffect(() => {
    if (ordersUpdateError) {
      toast.error(ordersUpdateError?.data?.message);
    }

    if (ordersUpdateSuccess) {
      toast.success("Orders Updated");
    }
  }, [ordersUpdateError, ordersUpdateSuccess]);

  const inventoryItem = inventory[productId];

  // --------------------------------- Render ----------------------------------
  // If the order, inventory, user are not loaded yet, show the loader
  if (isLoadingInventory || isLoadingOrders || user === null || loadingAdmin || ordersData === null || !productDetails || !inventoryItem) {
    return <Loader />;
  }
  //--------------------------------- Functions ---------------------------------

  // Update the quantity of the order
  // Handle quantity
  const handleQuantityChange = (index, itemIndex, value) => {
    const item = clonedOrdersData[index].orderItems[itemIndex];
    const originalItem = ordersData[index].orderItems[itemIndex];
    if (valueIsANonNegativeNumber(value) && item?.finalQuantity !== value) {
      item.finalQuantity = Number(value);
      setFormUpdated(!formUpdated);
      setThereAreValueChanges(!_.isEqual(item, originalItem));
    }
  };
  
  // Create new order
  const createNewOrderHandler = () => {
    // Redirect to create new order page
    navigate(appendAppVersionToUrl('/admin/orders/new'));
  }

  // Update orders
  const updateOrdesrHandler = () => {
    // There there are chages, update first
    if (thereAreValueChanges) {
      const ordersDataToUpdate = [];
      clonedOrdersData?.forEach((order, index) => {
        const itemIndex = order.orderItems?.findIndex(item => item.product.toString() === productId);
        // Only push to update the changed order
        if (!_.isEqual(clonedOrdersData[index].orderItems[itemIndex], ordersData[index].orderItems[itemIndex])) {
          ordersDataToUpdate.push({
            orderId: order._id,
            finalPrice: order.orderItems[itemIndex].finalPrice,
            finalQuantity: order.orderItems[itemIndex].finalQuantity,
          });
        }
      });
      adminUpdateOrdersByProductMutation({ id: productId, body: ordersDataToUpdate });
    }
  };

  const canUpdateOrders = CanUserUpdateInProgressOrder(user);

  const setOrders = () => {
    const ordersTable = {
      columns: [
        {
          label: <CenterAlignedCell value={"ID"}> </CenterAlignedCell>,
          field: "id",
        },
        {
          label: <CenterAlignedCell value={"Customer"}> </CenterAlignedCell>,
          field: "customer",
        },
        {
          label: <CenterAlignedCell value={"Order Status"}> </CenterAlignedCell>,
          field: "orderStatus",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Price"}> </CenterAlignedCell>,
          field: "price",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Quantity"}> </CenterAlignedCell>,
          field: "quantity",
        },
        {
          label: <CenterAlignedCell value={"Actions"}> </CenterAlignedCell>,
          field: "actions",
          width: '10vw',
        },
        {
          label: <CenterAlignedCell value={"Time"}> </CenterAlignedCell>,
          field: "time",
          sort: "asc",
          width: '20vw',
        },
      ],
      rows: [],
    };

    // clonedOrdersData has format as {orderNumber, customer, finalPrice, finalQuantity, status, createdAt}
    clonedOrdersData?.forEach((order, index) => {
      const customer = order.customer;

      // Check user rights
      const {rights} = DetermineUserOrderRights(user, order.status);
      const editable = canUpdateOrders && rights?.some(right => right === ORDER_USER_RIGHTS.UPDATE);
      const itemIndex = order.orderItems?.findIndex(item => item.product.toString() === productId);
      let item;
      let originalItem;
      if (itemIndex >= 0) {
        item = order.orderItems[itemIndex];
        originalItem = ordersData[index].orderItems[itemIndex];
      }

      if (item && originalItem) {
        const quantityColor =(item.finalQuantity !== originalItem.finalQuantity) ? "red" : "black";
        ordersTable.rows.push({ 
          id: order?.orderNumber,
          customer: customer.name,
          price: <RightAlignedCell value={item.finalPrice} > </RightAlignedCell>,
          quantity: editable ? 
            <EditableNumberCell readOnly={!editable} value={item.finalQuantity} onChange={(val) => {handleQuantityChange(index, itemIndex, val)}} color={quantityColor}> </EditableNumberCell> : 
            <RightAlignedCell value={item.finalQuantity} > </RightAlignedCell>,
          orderStatus: String(order?.status).toUpperCase(),
          time: new Date(order?.createdAt).toLocaleString(),
          actions: (
            <center>
              <Link
                to={appendAppVersionToUrl(`/admin/orders/${order?._id}`)}
                className="btn btn-outline-primary"
              >
                <i className="fa fa-pencil"></i>
              </Link>
            </center>
          ),
        });
      }
    });

    return ordersTable;
  };
  const totalUnitCountUpdate = clonedOrdersData?.reduce((acc, order) => {
    const itemIndex = order.orderItems?.findIndex(item => item.product.toString() === productId);
    if (itemIndex >= 0) {
      const item = order.orderItems[itemIndex];
      acc += item.finalQuantity;
    }
    return acc;
  }, 0);
  const stock = inventoryItem.quantity ? inventoryItem.quantity : 0;
  const inbound = "Need to load order and purchase by product data";//
  const balance = stock  + inbound - totalUnitCountUpdate;

  return (
    <>
      <MetaData title={"Orders by Product"} />
      <HeaderAdmin title={"Orders by Product"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.ORDERS.name}>
        <div id = "order_table" className="col-11 col-lg-11 offset-lg-0">
          <div className="row my-3">
            <h1 className="col-4 col-lg-4 text-start ">{productDetails.name}</h1>
            <div className="col-3 col-lg-3 offset-lg-5">
              {/*New Order Button - rigjht */}
              {CanUserCreateOrder && (
              <button 
                onClick={() => {createNewOrderHandler();}} 
                className="btn btn-primary"
                style={{width: '100%'}}>
                Create New Order
              </button>)}             
            </div>
          </div>
          {/* Inventory Data */}
          <div className="row my-3">
            <h4 className="col-3 col-lg-3 text-start"><strong>Stock: </strong>{stock}</h4>
            <h4 className="col-3 col-lg-3 text-start"><strong>Inbound: </strong>{inbound}</h4>
            <h4 className="col-3 col-lg-3 text-start"><strong>Outbound: </strong>{totalUnitCountUpdate}</h4>
            <h4 className="col-3 col-lg-3 text-start"><strong>Balance: </strong><span style={{color: balance < 0 ? "red" : "black"}}>{balance}</span></h4>
          </div>
          <MDBDataTable
            data={setOrders()}
            className="px-10 myMDBDataOrdersByProductTable"
            bordered
            striped
            hover
            noBottomColumns
          />
          <div className="row my-3">
            <div className="col-3 col-lg-3 offset-9">
              {/* Update Button */}
              {canUpdateOrders && (
              <div className="my-4">
                <button 
                    disabled={!thereAreValueChanges || isUpdatingOrders}
                    labelType="fa fa-save"
                    onClick={() => {updateOrdesrHandler();}} 
                    className="btn btn-success"
                    style={{width: '100%'}}>
                    Update
                </button>
              </div>)}
            </div>
          </div>
        </div>
      </AdminLayout>
      {isUpdatingOrders && <Loader />}
    </>
  );
};

export default ListOrdersByProduct;
