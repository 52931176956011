import React, { useEffect, useState } from "react";
import AdminLayout from "../layout/AdminLayout.jsx";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useLazyAdminSearchOrdersQuery } from "../../redux/api/orderAdminApi.js";
import { useLazyAdminSearchPurchasesQuery } from "../../redux/api/purchaseAdminApi.js";
import { toast } from "react-hot-toast";
import Loader from "../layout/Loader.jsx";
import MetaData from "../layout/MetaData.jsx";
import HeaderAdmin from "../layout/HeaderAdmin.jsx";
import { ADMIN_MENU_ITEMS, DELIVERY_TYPE, ORDER_STATUS, ORDER_STATUS_ICON, PURCHASE_STATUS_ICON } from "../../constants/constants.js";
import { MDBDataTable } from "mdbreact";
import { CenterAlignedCell, RightAlignedCell } from "../layout/CustomMDBDataTableCell.jsx";
import Pagination from "react-js-pagination";
import { appendAppVersionToUrl, convertToPST, formatDateToISOString, getDateEndTimeInPST, getDateStartTimeInPST, getPSTStartOfDay } from "../../utils/utilities.js";
import { formatInTimeZone } from "date-fns-tz";

const SEARCH_CRITERIA = [
  { value: "all", label: "All" },
  { value: "searchByInvoice", label: "Invoice" },
  { value: "searchByCustomer", label: "Customer" },
  { value: "searchByVendor", label: "Vendor" },
  { value: "searchByProduct", label: "Product Name" },
  { value: "searchBySku", label: "Product SKU" },
  { value: "searchById", label: "ID" },
];

const History = () => {
  const [startDate, setStartDate] = useState(getPSTStartOfDay().setDate(1));
  const [endDate, setEndDate] = useState(getPSTStartOfDay());
  const [type, setType] = useState("Sales"); // Search type
  const [dataType, setDataType] = useState("Sales"); // Data type
  const [searchCriteria, setSearchCriteria] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [displayedData, setDisplayedData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [AdminSearchOrders, { error: errorLoadingSales, isLoading: isLoadingSales, data: salesData }] = useLazyAdminSearchOrdersQuery();
  const [AdminSearchPurchases, { error: errorLoadingPurchases, isLoading: isLoadingPurchases, data: purchaseData }] = useLazyAdminSearchPurchasesQuery();

  useEffect(() => {
    if (errorLoadingSales) {
      toast.error(errorLoadingSales?.data?.message);
    }
    if (errorLoadingPurchases) {
      toast.error(errorLoadingPurchases?.data?.message);
    }
    setIsLoading(false);
  }, [errorLoadingSales, errorLoadingPurchases]);

  useEffect(() => {
    //console.log("salesData", salesData);
    if (salesData && type === "Sales") {
      setDisplayedData(salesData);
    } 
    setIsLoading(false);
  }, [salesData]);

  useEffect(() => {
    if (purchaseData && type === "Purchases") {
      setDisplayedData(purchaseData);
    }
    setIsLoading(false);
  }, [purchaseData]);

  const handleDataTypeChange = (e) => {
    const newType = e.target.value;
    setType(newType);
    // clear search value
    setSearchValue("");
  };

  const handleOrderRowClick = (id, isHistory) => {
    window.open(appendAppVersionToUrl(isHistory ? `/admin/oldorders/${id}` : `/admin/orders/${id}`), '_blank');
  };

  const handlePurchaseRowClick = (id, isHistory) => {
    window.open(appendAppVersionToUrl(isHistory ? `/admin/oldpurchases/${id}` : `/admin/purchases/${id}`), '_blank');
  };


  const onSubmit = () => {
    setCurrentPage(1);
    performSearch(1, true);
  };

  const performSearch = (page, refreshPage) => {
    let params = { };
    params.extraParams = searchCriteria;
    params.searchValue = searchValue?.trim();
    params.page = page;

    if (searchCriteria !== 'searchByID' && searchCriteria !== 'searchByInvoice') {
      params.startDate = getDateStartTimeInPST(startDate);
      params.endDate = getDateEndTimeInPST(endDate);
    }

    if (type === "Sales") {
      AdminSearchOrders(params);
    } else {
      AdminSearchPurchases(params);
    }
    if (refreshPage) {
      setDataType(type);
      setDisplayedData(null);
    }    
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000); // 3 seconds
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default action
      onSubmit();
    }
  };

  const setCurrentPageNo = (page) => {
    setCurrentPage(page);
    performSearch(page, false);
  }

  // ----------------------------- Set up Orders -----------------------------
  const setOrders = () => {
    const orders = {
      columns: [
        {
          label: '',
          field: "id",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Order #"}> </CenterAlignedCell>,
          field: "orderNumber",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Customer"}> </CenterAlignedCell>,
          field: "customer",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Total"}> </CenterAlignedCell>,
          field: "total",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Order Date"}> </CenterAlignedCell>,
          field: "orderTime",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Status"}> </CenterAlignedCell>,
          field: "status",
          sort: "asc",
        },
      ],
      rows: [],
    };

    let index = salesData?.resPerPage && salesData.page ? Number(salesData?.resPerPage) * Number(salesData.page - 1) + 1 : 1;

    salesData?.orders?.forEach((order) => {
      const totalAmount = order.totalAmount.toFixed(2);
      const orderDate = order.orderDate ? formatInTimeZone(convertToPST(new Date(order.orderDate)), 'America/Los_Angeles', 'MM/dd/yyyy') : '';
      const customerName = `${order.customerName} ${order.extraName ? `- ${order.extraName}` : ''}`;
      let orderStatus = String(order?.status).toUpperCase();
      if (order.deliverType === DELIVERY_TYPE.PICK_UP && order.status === ORDER_STATUS.SHIPPED){
        orderStatus = 'PICKED UP';
      } else if (order.deliverType === DELIVERY_TYPE.PICK_UP && order.status === ORDER_STATUS.DELIVERED){
        orderStatus = 'FINISHED';
      }
      orders.rows.push({
        clickEvent: () => handleOrderRowClick(order._id, order.isHistory),
        id: <CenterAlignedCell value={index++}></CenterAlignedCell>,
        orderNumber: <CenterAlignedCell value={order?.orderNumber}></CenterAlignedCell>,
        customer: customerName,
        //paymentStatus: order?.paymentInfo?.status?.toUpperCase(),
        total: <RightAlignedCell value={`$${totalAmount}`}> </RightAlignedCell>,
        orderTime: <CenterAlignedCell value={orderDate}></CenterAlignedCell>,
        status: <span style={{alignContent: 'center'}}>
            { ORDER_STATUS_ICON[order.status] && (<i className={ORDER_STATUS_ICON[order.status].icon}  style={{ color: ORDER_STATUS_ICON[order.status].color }} /> )} {orderStatus}
        </span>,
        //deliveryTime: <CenterAlignedCell value={order?.deliveredAt ? new Date(order?.deliveredAt).toLocaleString() : ''}></CenterAlignedCell>,

      });
    });

    return orders;
  };

  // ----------------------------- Set up Purchases -----------------------------
  const setPurchases = () => {
    const purchases = {
      columns: [
        {
          label: '',
          field: "id",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Purchase #"}> </CenterAlignedCell>,
          field: "purchaseNumber",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Vendor"}> </CenterAlignedCell>,
          field: "vendor",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Total"}> </CenterAlignedCell>,
          field: "total",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Invoice #"}> </CenterAlignedCell>,
          field: "invoiceNumber",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Purchase Date"}> </CenterAlignedCell>,
          field: "purchaseTime",
          sort: "asc",
        },
        {
          label: <CenterAlignedCell value={"Status"}> </CenterAlignedCell>,
          field: "status",
        }
      ],
      rows: [],
    };

    let index = 1;
    purchaseData?.purchases?.forEach((purchase) => {
      const purchaseDate = purchase.purchaseDate ? formatInTimeZone(convertToPST(new Date(purchase.purchaseDate)), 'America/Los_Angeles', 'MM/dd/yyyy') : '';
      purchases.rows.push({
        clickEvent: () => handlePurchaseRowClick(purchase._id, purchase.isHistory),
        id: <CenterAlignedCell value={index++}></CenterAlignedCell>,
        purchaseNumber: <CenterAlignedCell value={purchase.purchaseNumber}></CenterAlignedCell>,
        vendor: purchase.vendorName,
        total: <RightAlignedCell value={`$${purchase.totalAmount}` }></RightAlignedCell>,
        invoiceNumber: <CenterAlignedCell value={purchase.paymentInfo?.invoice}></CenterAlignedCell>,
        purchaseTime: <CenterAlignedCell value={purchaseDate}></CenterAlignedCell>,
        status: <span>{ PURCHASE_STATUS_ICON[purchase.status] && (<i className={PURCHASE_STATUS_ICON[purchase.status].icon}  style={{ color: PURCHASE_STATUS_ICON[purchase.status].color }} /> )} {String(purchase?.status).toUpperCase()}</span>,
      });
    });

    return purchases;
  };
  //------------------------------------------------------------------------------------

  return (
    <>
      <MetaData title={"History"} />
      <HeaderAdmin title={"History"} />
      <AdminLayout menuItem={ADMIN_MENU_ITEMS.HISTORY.name}>
        <div style={{width: '100%',  padding: '0 20px' }}>
          {/* Row 1 */}
          <div className="row d-flex justify-content-start align-items-center">
            <div className="col-2 py-3">
              <label className="form-label d-block">Inbound or Outbound</label>
              <select value={type} className="form-select" onChange={handleDataTypeChange} style={{width: '90%'}}>
                <option value="Sales">Sales</option>
                <option value="Purchases">Purchases</option>
              </select>
            </div>
            <div className="col-2 offset-6 justify-content-center">
              <label className="form-label d-block">Start Date</label>
              <DatePicker
                disabled={searchCriteria === 'searchByID' || searchCriteria === 'searchByInvoice'}
                style={{ width: "100%" }}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                className="form-control"
              />
            </div>
            <div className="col-2 justify-content-center">
              <label className="form-label d-block">End Date</label>
              <DatePicker
                disabled={searchCriteria === 'searchByID' || searchCriteria === 'searchByInvoice'}
                style={{ width: "100%" }}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                className="form-control"
              />
            </div>
          </div>

          {/* Row 2 */}
          <div className="row d-flex justify-content-start align-items-center">
            <div className="col-2 py-3">
              <label className="form-label d-block">Criteria</label>
              <select value={searchCriteria} className="form-select" onChange={(e) => { setSearchCriteria(e.target.value) }} style={{width: '90%'}}>
              {SEARCH_CRITERIA.map((criteria) => (
                (!(criteria.value === 'searchByVendor' && type === 'Sales') && !(criteria.value === 'searchByCustomer' && type === 'Purchases')  && !(criteria.value === 'searchByInvoice' && type === 'Sales')) && (
                  <option key={criteria.value} value={criteria.value}>
                    {criteria.label}
                  </option>
                )
                ))}
              </select>
            </div>
            <div className="col-3">
              <label className="form-label d-block">Search Value</label>
              <input
                type="text"
                value={searchValue}
                className="form-control"
                onKeyDown={handleKeyDown}
                onChange={(e) => setSearchValue(e.target.value) }
              />
            </div>
            <div className="col-3 offset-3 justify-content-center">
              <button
                className="btn fetch-btn ms-4 mt-3 px-5"
                onClick={onSubmit}
              >
                Fetch
              </button>
            </div>
          </div>

          {/* Loader */}
          {(isLoading || isLoadingSales || isLoadingPurchases ) && (
            <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
                <Loader />
            </div>
          )}
          { displayedData && (
            <h3>
              {dataType === 'Sales' ? `${displayedData.totalResultCount??0} Order(s)` : `${displayedData.totalResultCount??0} Purchase(s)`}
            </h3>
          )}
          <MDBDataTable
            data={dataType === 'Sales' ? setOrders() : setPurchases()}
            className={dataType === 'Sales' ? "px-10 myMDBDataListHistoryOrderTableRow" : "px-10 myMDBDataListHistoryPurchaseTableRow" }
            bordered
            striped
            hover
            noBottomColumns
            displayEntries={false}
            searching={false}
            paging={false} 
          />
          { displayedData && displayedData.totalResultCount > 0 && (

            <div className="d-flex justify-content-center my-5">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={Number(displayedData.resPerPage)}
                totalItemsCount={Number(displayedData.totalResultCount)}
                onChange={setCurrentPageNo}
                nextPageText={"Next"}
                prevPageText={"Prev"}
                firstPageText={"First"}
                lastPageText={"Last"}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          )}
        </div>
      </AdminLayout>
    </>
  );
};

export default History;
